import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import domtoimage from "dom-to-image";
// import { toPng } from "html-to-image";
import parse from "html-react-parser";

import { useAppStore } from "../../../../store";
import { useShallow } from "zustand/shallow";

import { categorizeFields } from "../targeting/categorizeFields";
import { SavePDF } from "../../../campaigns/campaignDetails/pdfDocument";
import AddressMap from "../../../map";

import ContentContainer from "../../../../components/contentContainer";
import RoundedButton from "../../../../components/roundedButton";
import PieChart from "../../../../components/pieChart";
import ActivitySpinnerNoBg from "../../../../components/activitySpinner/withNoBgEffect";
import ActivityIndicator from "../../../../components/activitySpinner";
import Tooltip from "../../../../components/tooltip";
import EstimationTable from "../../../../components/estimationTable";

import { useGeocoding } from "../../../../hooks/useGeoCoding";

import { ReactComponent as PdfIcon } from "../../../../assets/icons/pdf-icon-mui.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon-mui.svg";
// import { ReactComponent as ShareIcon } from "../../../../assets/icons/share-icon-mui.svg";

import {
  ICampaignStrategyResponse,
  useCampaignStrategyInfo,
  useSingleCampaignDetails,
} from "../../../../services/campaigns";
import { useAdvertiser } from "../../../../services/advertiser";
import { useKeywords } from "../../../../services/wizard/campaign";
import { useBudgetOrganizationChannels } from "../../../../services/advertiser/organization-budget-channel";
import {
  IBannerBearAdObject,
  IBannerBearImagesResponse,
} from "../../../../services/wizard/bannerbear";
import { useDomainStyle } from "../../../../services/multitenacy-config";

import { parseDefaultVal } from "../../../../utils/parseDefaultValues";
import { formatDate } from "../../../../utils/formatDate";

import { BUDGET_CHANNEL_MAPPING_READ_MODE } from "../../../../constants/wizard/campaign";
import CampaignConfirmationModal from "../../campaignConfirmationModal";

const Confirm = ({
  creatives,
}: {
  creatives?: IBannerBearImagesResponse | undefined;
}) => {
  const navigate = useNavigate();

  const {
    domainName,
    selectedAdvertiser,
    campaignId,
    step,
    setStep,
    creativesForPDFArr,
    setCreativesForPDFArr,
    resetCreativesForPDFArr,
    isConfirmationModalShown,
    setIsConfirmationModalShown,
  } = useAppStore(
    useShallow((state) => ({
      domainName: state.domainName,
      selectedAdvertiser: state.selectedAdvertiser,
      campaignId: state.campaignId,
      step: state.step,
      setStep: state.setStep,
      creativesForPDFArr: state.creativesForPDFArr,
      setCreativesForPDFArr: state.setCreativesForPDFArr,
      resetCreativesForPDFArr: state.resetCreativesForPDFArr,
      isConfirmationModalShown: state.isConfirmationModalShown,
      setIsConfirmationModalShown: state.setIsConfirmationModalShown,
    }))
  );

  const {
    isError: isCampaignDataFailed,
    isPending: isCampaignDataLoading,
    data: campaignData,
  } = useSingleCampaignDetails(campaignId, selectedAdvertiser);

  const { data: strategyInfo } = useCampaignStrategyInfo(
    campaignId,
    selectedAdvertiser
  );
  const totalBudget = strategyInfo?.campaignBudget.budget;

  const { data: campaignOwnerData } = useAdvertiser(selectedAdvertiser);

  const { data: domainStyleData } = useDomainStyle(domainName);
  const { data: organizationBudgetChannels } = useBudgetOrganizationChannels(
    domainStyleData?.id ?? null,
    domainName
  );

  const categorizedFields = categorizeFields(
    campaignData?.campaignFieldValues,
    true
  );
  const generalCampaignInfoArr = categorizedFields["GENERAL"];
  const ADVNACED_TARGETING_DATA =
    categorizedFields && categorizedFields["ADVANCE_TARGETING"];
  const GEO_TARGETING_DATA =
    categorizedFields && categorizedFields["GEO_TARGETING"];

  const addressField = GEO_TARGETING_DATA?.find(
    (field) => field.campaignFieldName.toLowerCase() === "address"
  );

  const radiusField = GEO_TARGETING_DATA?.find(
    (field) => field.campaignFieldName.toLowerCase() === "radius"
  );

  const { geoCodingResult } = useGeocoding({ address: addressField?.value });

  let campaignWebsite = campaignData?.websiteUrl;

  const { data: keywordsData } = useKeywords(campaignId);
  let formattedKeywords: string | undefined = undefined;

  if (keywordsData && keywordsData[0]?.keywords) {
    formattedKeywords = keywordsData[0].keywords
      .map((keyword) => `• ${keyword} `)
      .join(" ");

    if (formattedKeywords.length > 500) {
      formattedKeywords = formattedKeywords.slice(0, 500 - 3).trim() + "...";
    }
  }

  const isSearchChannelGreaterThanZero =
    (strategyInfo?.campaignBudget?.searchAllocation ?? 0) > 0;
  const campaignVisitEstimation = strategyInfo?.campaignVisitEstimation;
  const campaignViewEstimation = strategyInfo?.campaignImpressionEstimation;

  const chartRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<HTMLDivElement | null>(null);

  const [pieChartImageSrc, setPieChartImageSrc] = useState("");
  const legalText = domainStyleData?.termsAndConditions;
  const description = domainStyleData?.description;

  const [isPDFPending, setIsPDFPending] = useState<undefined | boolean>(
    undefined
  );

  const pieChartData = organizationBudgetChannels
    ?.filter(
      (channel) =>
        BUDGET_CHANNEL_MAPPING_READ_MODE[channel.budgetChannel]?.key !==
        undefined
    )
    .map((channel) => {
      const channelKey = BUDGET_CHANNEL_MAPPING_READ_MODE[channel.budgetChannel]
        ?.key as keyof ICampaignStrategyResponse["campaignBudget"];
      const channelColor =
        BUDGET_CHANNEL_MAPPING_READ_MODE[channel.budgetChannel]?.color;
      const value = strategyInfo?.campaignBudget[channelKey];

      return {
        key: `campaign-strategy-pie-${channelKey}`,
        value: value,
        color: channelColor,
        title: channel.channelName,
      };
    });

  async function handleCampaignConfirmationModal(
    campaignStatus: string | undefined
  ) {
    if (campaignStatus === "active") {
      navigate("/campaigns");
      return;
    }

    if (campaignStatus === "draft") {
      setIsConfirmationModalShown(true);
    }
  }

  async function setPieChartPdfImage() {
    if (!chartRef.current) return;
    if (pieChartImageSrc) return;

    try {
      const dataUrl = await domtoimage.toPng(chartRef.current);
      setPieChartImageSrc(dataUrl);
    } catch (error) {}
  }

  async function handleCreativeImagesForPDF() {
    if (!creatives) return;

    if (creativesForPDFArr.length !== 0) resetCreativesForPDFArr();

    const displayBudgetValue =
      strategyInfo?.campaignBudget?.displayAllocation ?? 0;
    const socialBudgetValue =
      strategyInfo?.campaignBudget?.socialAllocation ?? 0;
    const searchBudgetValue =
      strategyInfo?.campaignBudget?.searchAllocation ?? 0;
    const printBudgetValue = strategyInfo?.campaignBudget?.printAllocation ?? 0;

    const totalNumberOfInPDFCreatives = 4;
    const numberOfAvailableChannels =
      ((displayBudgetValue ?? 0) > 0 ? 1 : 0) +
      ((socialBudgetValue ?? 0) > 0 ? 1 : 0) +
      ((searchBudgetValue ?? 0) > 0 ? 1 : 0) +
      ((printBudgetValue ?? 0) > 0 ? 1 : 0);
    const numberOfCreativesFromPerChannel = Math.round(
      totalNumberOfInPDFCreatives / numberOfAvailableChannels
    );

    iterateCreativesInChannels(
      displayBudgetValue,
      creatives.displayAds,
      numberOfCreativesFromPerChannel
    );
    iterateCreativesInChannels(
      socialBudgetValue,
      creatives.socialAds,
      numberOfCreativesFromPerChannel
    );
    iterateCreativesInChannels(
      searchBudgetValue,
      creatives.searchAds,
      numberOfCreativesFromPerChannel
    );
    iterateCreativesInChannels(
      printBudgetValue,
      creatives.printAds,
      numberOfCreativesFromPerChannel
    );
  }

  function iterateCreativesInChannels(
    channelValue: number,
    channel: IBannerBearAdObject[],
    numberOfCreativesFromPerChannel: number
  ) {
    if (channelValue !== 0) {
      const newArr = channel
        .filter((creative) => creative.templateType !== "ANIMATED_GIF")
        .slice(0, numberOfCreativesFromPerChannel);
      setCreativesForPDFArr(newArr);
    }
  }

  function handleSavePDF() {
    setIsPDFPending(true);
    handleCreativeImagesForPDF();
    setTimeout(() => {
      const campaignProps = {
        description,
        legalText,
        setIsPDFPending,
        pieChartImageSrc,
        generalCampaignInfoArr,
        advanceTargetingCampaignInfoArr: ADVNACED_TARGETING_DATA,
        geoTargetingCampaignInfoArr: GEO_TARGETING_DATA,
        campaignName: strategyInfo?.campaignName,
        campaignOwnerData,
        totalBudget,
        campaignData,
        campaignWebsite,
        campaignKeywordsFormatted: formattedKeywords,
        pieChartData,
        campaignVisitEstimation,
        campaignViewEstimation,
        isSearchChannelGreaterThanZero,
      };
      SavePDF({ ...campaignProps });
    }, 1500);
  }

  useEffect(() => {
    if (!pieChartImageSrc) {
      const interval = setInterval(() => {
        if (chartRef.current) {
          setPieChartPdfImage();
          clearInterval(interval);
        }
      }, 100);
      return () => clearInterval(interval);
    }
    //eslint-disable-next-line
  }, [pieChartImageSrc]);

  return isCampaignDataLoading ? (
    <ActivitySpinnerNoBg />
  ) : isCampaignDataFailed ? (
    <div>
      <p className="text-colorDelete text-sm font-interSemiBold">
        Something went wrong when trying to load campaign data
      </p>
    </div>
  ) : (
    campaignData && (
      <ContentContainer className="px-7 py-5">
        {isConfirmationModalShown && <CampaignConfirmationModal />}
        {isPDFPending && <ActivityIndicator />}
        <div className="flex flex-col space-y-4">
          <section id="generalCampaignInfo">
            <div className="flex items-center">
              {strategyInfo?.campaignStatus === "active" ? (
                <p className="text-colorGreen font-interSemiBold text-lg">
                  Status: Active
                </p>
              ) : (
                strategyInfo?.campaignStatus === "draft" && (
                  <p className="text-colorOrange font-interSemiBold text-lg">
                    Status: Draft
                  </p>
                )
              )}
              <div className="flex ml-auto items-center">
                {strategyInfo?.campaignStatus !== "active" && step === 1 && (
                  <div
                    onClick={() => {
                      setStep(2);
                    }}
                  >
                    <Tooltip
                      Icon={EditIcon}
                      bodyText="Edit"
                      className="w-9 h-8  text-gray-500 cursor-pointer  fill-current"
                    />
                  </div>
                )}
                {/* <div className="ml-6" onClick={() => {}}>
                  <Tooltip
                    Icon={ShareIcon}
                    bodyText="Share"
                    className="w-9 h-8  text-gray-500 cursor-pointer  fill-current"
                  />
                </div> */}
                <div
                  className="mx-6"
                  onClick={() => {
                    if (pieChartImageSrc) {
                      handleSavePDF();
                    }
                  }}
                >
                  <Tooltip
                    Icon={PdfIcon}
                    bodyText="Download PDF"
                    className="w-9 h-8  text-gray-500 cursor-pointer  fill-current"
                  />
                </div>
                {step === 1 && (
                  <div>
                    <RoundedButton
                      onClick={async () =>
                        handleCampaignConfirmationModal(
                          strategyInfo?.campaignStatus
                        )
                      }
                      type="button"
                      isEnabled={true}
                      className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto "
                      borderRadius="rounded-md"
                      bgColor={`${
                        strategyInfo?.campaignStatus !== "active"
                          ? "bg-[#49AC1B]"
                          : "bg-[#3077aa]"
                      }`}
                      text={
                        <p className=" text-sm text-white inline-flex items-center font-interRegular">
                          {strategyInfo?.campaignStatus === "active"
                            ? "Go Back to Campaigns"
                            : "Continue"}
                        </p>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            <hr className=" mt-1.5 mb-3" />
            {strategyInfo?.campaignInstructions && (
              <div className="flex-col w-full mt-6">
                <div className="font-interRegular mt-2 text-sm leading-6 text-primary ">
                  {parse(strategyInfo.campaignInstructions)}
                </div>
              </div>
            )}
            <div className="flex w-full mt-6">
              <div className="flex w-[55%] flex-col">
                <h3 className="text-sm w-full mb-2.5 font-interSemiBold text-primary">
                  Campaign Info
                </h3>

                <div>
                  <p className="font-interRegular text-sm text-primary">
                    <span className="mr-1 text-sm font-interSemiBold text-primary">
                      • Name:
                    </span>
                    {strategyInfo?.campaignName}
                  </p>

                  {generalCampaignInfoArr?.map((campaignFieldsObj) => {
                    const fieldTitle = campaignFieldsObj.campaignFieldName;
                    const fieldVal = campaignFieldsObj.campaignFieldName
                      .toLocaleLowerCase()
                      .includes("date")
                      ? formatDate(campaignFieldsObj.value)
                      : campaignFieldsObj.value;

                    return (
                      <p
                        key={campaignFieldsObj.campaignFieldId}
                        className="font-interRegular my-2 text-primary text-sm"
                      >
                        <span className="mr-1 text-sm font-interSemiBold text-primary">
                          • {fieldTitle}:
                        </span>
                        {fieldVal}
                      </p>
                    );
                  })}

                  <p className="font-interRegular my-2 text-primary text-sm">
                    <span className="mr-1 text-sm font-interSemiBold text-primary">
                      • Website:
                    </span>
                    {campaignWebsite}
                  </p>

                  <p className="font-interRegular my-2 text-primary text-sm">
                    <span className="mr-1 text-sm font-interSemiBold text-primary">
                      • Total Budget:
                    </span>
                    ${totalBudget}
                  </p>
                </div>
              </div>
              <div className="flex w-[21rem] ml-auto">
                <EstimationTable
                  className="w-full"
                  campaignStrategyData={strategyInfo}
                />
              </div>
            </div>
          </section>

          <section id="allocation">
            <h3 className="text-sm text-primary mb-3 font-interSemiBold mt-6">
              Budget Allocation
            </h3>
            <div className="flex flex-col ">
              <div className="flex">
                <div className="w-[55%] ">
                  <p className="text-primary font-interRegular leading-6 text-sm w-full">
                    To get the most out of your ad budget, we’ll allocate it
                    across the best channels to ensure maximum impact and ROI.
                  </p>
                </div>

                <div className="ml-auto -mt-10">
                  <div ref={chartRef} className="w-[60%]">
                    {strategyInfo &&
                      organizationBudgetChannels &&
                      pieChartData && (
                        <PieChart
                          topContainer="flex flex-col space-y-4  order-2"
                          indicatorContainer="flex items-center w-28 ml-6"
                          circleContainer="relative w-[14.5rem]"
                          data={pieChartData}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="targeting">
            <div className="flex">
              <div className="flex flex-col w-[55%]">
                <h3 className="text-sm text-primary mb-3 font-interSemiBold ">
                  Reaching Your Customers
                </h3>
                <p className="text-sm text-primary mb-2 font-interRegular">
                  We make sure your ads reach the people most likely to be
                  interested in your business. Using smart data and insights, we
                  connect you with the right customers so every ad dollar
                  counts.
                </p>

                <p className="text-sm text-primary mt-1.5 font-interRegular">
                  Your
                  <span className="font-interSemiBold mx-1">digital</span>
                  ads will be shown to people within
                  <span className="font-interSemiBold mx-1.5">
                    {radiusField?.value}
                  </span>
                  miles of
                  <span className="font-interSemiBold mx-1.5">
                    {addressField?.value}
                  </span>
                  who match the right customer traits.
                </p>

                <div className="flex flex-col mt-2">
                  {ADVNACED_TARGETING_DATA?.map((field) => {
                    return (
                      <div key={field.campaignFieldId} className="flex ">
                        <p className="text-sm text-primary font-interRegular">
                          •
                          <span className="mx-1.5 text-sm text-primary font-interSemiBold">
                            {field.campaignFieldName}:
                          </span>
                          {parseDefaultVal(field.value)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                ref={mapRef}
                className="flex ml-auto flex-grow-0 h-[20.625rem] w-[20.625rem] mr-4"
              >
                <AddressMap
                  geoCodingResult={geoCodingResult}
                  radius={radiusField?.value}
                />
              </div>
            </div>
          </section>

          {isSearchChannelGreaterThanZero && formattedKeywords && (
            <section id="search-keywords">
              <div>
                <h3 className="text-sm text-primary  font-interSemiBold mt-6">
                  Search keywords
                </h3>
                <p className="font-interRegular text-sm text-primary mt-1.5">
                  Your ads will appear for users who are actively searching for
                  these topics on search engines.
                </p>
                <p className=" font-interSemiBold text-sm text-primary">
                  {formattedKeywords}
                </p>
              </div>
            </section>
          )}
        </div>
        {step === 5 && (
          <div className="ml-auto mt-10 flex">
            <RoundedButton
              onClick={() => setStep(4)}
              type="button"
              className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto mr-5"
              borderRadius="rounded-md"
              bgColor="#FFFFFF"
              borderColor="#C1C9D2"
              text={
                <p className=" text-sm text-primary inline-flex items-center font-interRegular">
                  Previous
                </p>
              }
            />
            <RoundedButton
              onClick={async () =>
                handleCampaignConfirmationModal(strategyInfo?.campaignStatus)
              }
              type="button"
              isEnabled={true}
              className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto "
              borderRadius="rounded-md"
              bgColor={`${
                strategyInfo?.campaignStatus !== "active"
                  ? "bg-[#49AC1B]"
                  : "bg-[#3077aa]"
              }`}
              text={
                <p className=" text-sm text-white inline-flex items-center font-interRegular">
                  {strategyInfo?.campaignStatus === "active"
                    ? "Go Back to Campaigns"
                    : "Continue"}
                </p>
              }
            />
          </div>
        )}
      </ContentContainer>
    )
  );
};

export default Confirm;
