import { StateCreator } from "zustand";

export interface ISidebarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
}

export const createSidebarSlice: StateCreator<
  ISidebarProps,
  [],
  [],
  ISidebarProps
> = (set, get) => ({
  isSidebarOpen: true,
  setIsSidebarOpen: (value) => {
    set({ isSidebarOpen: value });
  },
});
