import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAppStore } from "../../store";

import ContentContainer from "../../components/contentContainer";
import Input from "../../components/input/inputWithToggle";
import RoundedButton from "../../components/roundedButton";
import TopContent from "../../components/topContent";

import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon-mui.svg";

import {
  useOpenAIResponse,
  useUpdateWebsiteFields,
} from "../../services/web-details/openaiResponse";

export interface IOpenAIFormData {
  id: string;
  value: string;
  websiteFieldName: string;
  websiteFieldValue: string;
}

type RequestData = {
  [key: string]: {
    id: number;
    value: string;
    websiteFieldName: string;
    websiteFieldValue: string;
  };
};

const EditWebsite = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const selectedAdvertiser = useAppStore((state) => state.selectedAdvertiser);

  const { control, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      requestData: {} as RequestData,
    },
  });

  const formData = watch();

  const { data: openAIResponseData } = useOpenAIResponse(id);

  const {
    mutateAsync: updateWebsiteFields,
    isError: isUpdateFieldsError,
    isPending: isUpdateFieldsPending,
    isSuccess: isUpdateFieldsSuccess,
  } = useUpdateWebsiteFields(id, selectedAdvertiser);

  const onSubmit = async () => {
    const reqData = Object.entries(formData.requestData).map(([id, data]) => ({
      id: data.id,
      value: data.value,
    }));

    await updateWebsiteFields(reqData);
  };

  useEffect(() => {
    const newData = openAIResponseData?.reduce((acc, data) => {
      acc[data.websiteFieldName] = {
        id: data.id,
        websiteFieldName: data.websiteFieldName,
        value: data.value,
        websiteFieldValue: data.websiteFieldValue,
      };
      return acc;
    }, {} as RequestData);

    if (newData) {
      setValue("requestData", newData);
    }
  }, [openAIResponseData, setValue]);

  useEffect(() => {
    if (isUpdateFieldsSuccess) {
      toast.success("Website fields saved successfully!");
      setTimeout(() => {
        navigate("/websites");
      }, 800);
    } else if (isUpdateFieldsError) {
      toast.error("Something went wrong!");
    }
    // eslint-disable-next-line
  }, [isUpdateFieldsSuccess, isUpdateFieldsError]);

  return (
    <div className="flex flex-col">
      <TopContent
        title="Website Summary"
        subTitle="This is a summary of your website. Feel free to edit and tailor the information here. Use this space to highlight your key messages, creative concepts, and the unique selling points that will drive your campaign's success"
      />
      <ToastContainer
        position="top-right"
        autoClose={4000}
        newestOnTop={true}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      <ContentContainer>
        <div className=" flex flex-1 flex-col p-12 pb-0">
          <div className=" flex flex-1 flex-col space-y-5 justify-between mb-12 items-center sm:items-stretch">
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-1 flex-col w-[600px] space-y-6">
                {openAIResponseData?.map((data) => {
                  if (data.websiteFieldName === "Overview") {
                    return (
                      <Controller
                        key={data.websiteFieldName}
                        name={
                          `requestData.${data.websiteFieldName}.value` as any
                        }
                        rules={{
                          required: `${
                            formData?.requestData[data.websiteFieldName]
                              ?.websiteFieldName
                          } is required`,
                        }}
                        control={control}
                        defaultValue={data.value}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <div className="flex order-1">
                            <div
                              key={data.websiteFieldName}
                              className="flex flex-col"
                            >
                              <label
                                key={data.websiteFieldName}
                                className="font-interRegular text-sm text-secondary mb-1"
                              >
                                {
                                  formData?.requestData[data.websiteFieldName]
                                    ?.websiteFieldName
                                }
                              </label>
                              <textarea
                                name={data?.websiteFieldName}
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                                className={`px-3 ${
                                  error?.message
                                    ? "border-[#D34638]"
                                    : "border-[#40444f]"
                                }  border-[1px] rounded-[0.625rem] focus:outline-none py-3 font-interRegular text-sm text-primary xs:w-[18.75rem] sm:w-[27.5rem]    h-[12rem] hide-scrollbar resize-none `}
                              />
                            </div>
                            {error?.message && (
                              <div className="ml-3 self-center">
                                <p className="font-interSemiBold text-colorDelete text-sm ">
                                  {error?.message}
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      />
                    );
                  }
                  return (
                    <Controller
                      key={data.websiteFieldName}
                      name={`requestData.${data.websiteFieldName}.value` as any}
                      rules={{
                        required: `${
                          formData?.requestData[data.websiteFieldName]
                            ?.websiteFieldName
                        } is required`,
                      }}
                      control={control}
                      defaultValue={data.value}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <div className="flex ">
                          <div className="flex flex-col">
                            <label
                              key={data.websiteFieldName}
                              className="font-interRegular text-sm text-secondary mb-1"
                            >
                              {
                                formData?.requestData[data.websiteFieldName]
                                  ?.websiteFieldName
                              }
                            </label>

                            <div className=" ">
                              <Input
                                name={data?.websiteFieldName}
                                value={value}
                                type="text"
                                validationClassName="ml-2"
                                validation={error?.message}
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  );
                })}
              </div>
              <div className="flex pt-10 items-center">
                <RoundedButton
                  type="submit"
                  className="py-2.5 px-4"
                  borderRadius="rounded-md"
                  text={
                    <p className=" text-sm text-white inline-flex items-center font-interRegular">
                      <EditIcon className="text-white fill-current mr-2 text-2xl " />
                      {isUpdateFieldsPending ? "Saving..." : "SAVE"}
                    </p>
                  }
                />
                <p
                  onClick={() => navigate(-1)}
                  className="text-sm cursor-pointer font-interRegular ml-7 text-colorBlue"
                >
                  Cancel
                </p>
              </div>
            </form>
            {isUpdateFieldsError && (
              <p className="mt-5 pl-2 font-ManropeRegular text-base text-red-500">
                Failed to update fields.Please try again!
              </p>
            )}
          </div>
        </div>
      </ContentContainer>
    </div>
  );
};

export default EditWebsite;
