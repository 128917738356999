import { StateCreator } from "zustand";

export interface IAddWebsiteProps {
  isWebsiteError: Error | undefined;
  isWebsitePending: boolean | undefined;
  isWebsiteSuccess: boolean | undefined;
  setAddWebsiteStatusContext: (state: Partial<IAddWebsiteProps>) => void;
}

export const createAddWebsiteSlice: StateCreator<
  IAddWebsiteProps,
  [],
  [],
  IAddWebsiteProps
> = (set, get) => ({
  isWebsiteError: undefined,
  isWebsitePending: undefined,
  isWebsiteSuccess: undefined,
  setAddWebsiteStatusContext: (state) => set((prev) => ({ ...prev, ...state })),
});
