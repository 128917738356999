import { queryClient } from "../../App";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../axios";
import { ICampaignFieldsResponse } from "../industry-organization-campaign-fields";
import { ICreateBudgetResponse } from "../wizard/budget";
import { IBannerBearImagesResponse } from "../wizard/bannerbear";
import { TPaginatedDataResponse } from "../../interfaces";

export interface ICreateCampaignResponse {
  websiteId: string;
  name: string;
  status: "active" | "complete" | "draft" | "cancelled";
  id: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IAllCampaignsDataResponse {
  pagination: {
    totalRecords: number;
    currentPage: number;
    totalPages: number;
    nextPage: number | null;
    prevPage: number | null;
  };
  id: number;
  name: string;
  websiteId: number;
  status: "all" | "active" | "complete" | "cancelled";
  createdAt: Date;
  updatedAt: Date;
  goal: string;
  budget: number;
  image: string;
}

type TCampaignKeywords = {
  campaignId: number;
  keywords: string[];
  createdAt: Date;
  updatedAt: Date;
};

export interface ISingleCampaignDetailsResponse {
  id: number;
  name: string;
  websiteId: number;
  status: "all" | "active" | "draft" | "complete" | "cancelled";
  createdAt: Date;
  updatedAt: Date;
  campaignFieldValues: ICampaignFieldsResponse[];
  campaignKeywords: TCampaignKeywords[];
  campaignBudget: ICreateBudgetResponse;
  campaignImages: IBannerBearImagesResponse;
  websiteUrl: string;
}

export type TUpdateCampaignFields = Partial<ISingleCampaignDetailsResponse>;

export interface ICampaignStrategyInfoRequest {
  campaignId: number;
  campaignName: string | undefined;
  campaignBudget: {
    id: number;
    budget: number | undefined;
    printAllocation?: number | undefined;
    searchAllocation?: number | undefined;
    socialAllocation?: number | undefined;
    displayAllocation?: number | undefined;
    paidEmailAllocation?: number | undefined;
  };
  campaignGoal: {
    id: number;
    value: string;
  };
  campaignStartDate: {
    id: number;
    value: string;
  };
  campaignEndDate: {
    id: number;
    value: string;
  };
  campaignTheme: {
    id: number;
    value: string;
  };
  campaignVisitEstimation: {
    label: string;
    high: number;
    low: number;
  };
  campaignImpressionEstimation: {
    label: string;
    high: number;
    low: number;
  };
}

export type TCampaignField = {
  id: number;
  campaignId: number;
  campaignFieldId: number;
  value: any;
  isRequired: boolean;
  createdAt: Date;
  updatedAt: Date;
  campaignFieldName: string;
  categoryType: string;
  type: "STRING" | "DATE" | "DROPDOWN" | "MULTISELECT";
  options: any[];
  isEditible: boolean;
  isVisible: boolean;
  defaultValue: any;
  defaultValueType: any;
};
export interface ICampaignStrategyResponse {
  campaignId: number;
  campaignName: string;
  campaignStatus: string;
  campaignBudget: {
    id: number;
    websiteId: number;
    campaignId: number;
    budget: number;
    budgetRuleId: number;
    goal: string;
    searchAllocation: number;
    socialAllocation: number;
    printAllocation: number;
    displayAllocation: number;
    paidEmailAllocation: number;
    createdAt: Date;
    updatedAt: Date;
  };
  campaignTheme: TCampaignField;
  campaignGoal: TCampaignField;
  campaignStartDate: TCampaignField;
  campaignEndDate: TCampaignField;
  campaignVisitEstimation: {
    label: "Estimated Clicks";
    high: number;
    low: number;
  };
  campaignImpressionEstimation: {
    label: "Estimated Impressions";
    high: number;
    low: number;
  };
  campaignInstructions: null | string;
}

/* GET single campaign*/
async function getCampaignById(
  campaignId: number | undefined
): Promise<ISingleCampaignDetailsResponse> {
  return request({
    url: `campaigns/campaign/${campaignId}`,
    method: "get",
  });
}

export function useSingleCampaignDetails(
  campaignId: number | undefined,
  advertiserId: number | null
) {
  return useQuery({
    queryKey: ["campaign", advertiserId, campaignId],
    queryFn: () => getCampaignById(campaignId),
    enabled: !!campaignId && !!advertiserId,
    refetchOnWindowFocus: false,
  });
}
/* ADD a new campaign */
const createCampaign = (websiteId: number) => {
  return request({
    url: `campaigns/create/${websiteId}`,
    method: "post",
  });
};
export const useCreateCampaign = (advertiserId: number | null) =>
  useMutation<ICreateCampaignResponse, Error, any>({
    mutationFn: createCampaign,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["campaigns", advertiserId, 0],
      });
    },
  });

/* UPDATE campaign field values */
function updateCampaignFieldsValues({
  data,
}: {
  data: {
    id: number;
    campaignFieldId: number;
    value: any;
  }[];
}) {
  return request({
    method: "patch",
    url: "campaign-field-values",
    data,
  });
}

export function useUpdateCampaignFieldsValues({
  advertiserId,
  campaignId,
}: {
  advertiserId: number | null;
  campaignId: number | undefined;
}) {
  return useMutation<
    any,
    Error,
    {
      data: {
        id: number;
        campaignFieldId: number;
        value: any;
      }[];
    }
  >({
    mutationFn: updateCampaignFieldsValues,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["campaignFields", advertiserId, campaignId],
      });
    },
  });
}

/* GET all campaigns linked to an advertiser */
function getAllCampaigns(
  advertiserId: number | null,
  { page = 0, limit = 10 }
): Promise<TPaginatedDataResponse<IAllCampaignsDataResponse>> {
  return request({
    method: "get",
    url: `campaigns/${advertiserId}?page=${page}&limit=${limit}`,
  });
}

export function useAllCampaigns(
  advertiserId: number | null,
  { page, limit }: { page: number; limit: number }
) {
  return useQuery({
    queryKey: ["campaigns", advertiserId, page],
    queryFn: () =>
      getAllCampaigns(advertiserId, {
        page,
        limit,
      }),
    enabled: !!advertiserId,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });
}

/* Getting all campaigns associated with a website */
function getCampaignsByWebsiteId(
  websiteId: number | undefined
): Promise<IAllCampaignsDataResponse[]> {
  return request({
    method: "get",
    url: `campaigns/website/${websiteId}`,
  });
}

export function useCampaignsLinkedToWebsite(websiteId: number | undefined) {
  return useQuery({
    queryKey: ["campaigns", "website", websiteId],
    queryFn: () => getCampaignsByWebsiteId(websiteId),
    enabled: !!websiteId,
  });
}

/* Delete a campaign*/

function removeCampaign(campaignId: number | undefined) {
  return request({
    method: "delete",
    url: `campaigns/${campaignId}`,
  });
}

export function useRemoveCampaign(advertiserId: number | null) {
  return useMutation({
    mutationFn: removeCampaign,
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: ["campaigns", advertiserId],
      });
      queryClient.invalidateQueries({
        queryKey: ["campaigns", "website", advertiserId],
      });
    },
  });
}

/* CAMPAIGN Strategy Info  */
function getCampaignStrategyInfo(
  campaignId: number | undefined
): Promise<ICampaignStrategyResponse> {
  return request({
    method: "get",
    url: `campaigns/info/${campaignId}`,
  });
}

export function useCampaignStrategyInfo(
  campaignId: number | undefined,
  advertiserId: number | null
) {
  return useQuery({
    queryKey: ["campaignStrategyInfo", advertiserId, campaignId],
    queryFn: () => getCampaignStrategyInfo(campaignId),
    enabled: !!campaignId && !!advertiserId,
  });
}

/* UPDATE Campaign*/
function updateCampaign({
  campaignId,
  data,
}: {
  campaignId: number | undefined;
  data: TUpdateCampaignFields;
}) {
  return request({
    method: "patch",
    url: `campaigns/${campaignId}`,
    data,
  });
}

export function useUpdateCampaign(
  advertiserId: number | null,
  campaignId: number | undefined
) {
  return useMutation({
    mutationFn: updateCampaign,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["campaigns", advertiserId],
      });
      queryClient.invalidateQueries({
        queryKey: ["campaignStrategyInfo", advertiserId, campaignId],
      });
    },
  });
}

/* UPDATE campaign strategy info */
function updateCampaignStrategy({
  data,
}: {
  data: ICampaignStrategyInfoRequest;
}) {
  return request({
    method: "post",
    url: "/campaigns/update-info",
    data,
  });
}

export function useUpdateCampaignStrategy(
  advertiserId: number | null,
  campaignId: number | undefined
) {
  return useMutation({
    mutationFn: updateCampaignStrategy,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["campaignStrategyInfo", advertiserId, campaignId],
      });
      queryClient.invalidateQueries({
        queryKey: ["campaignStrategyInfo", advertiserId, campaignId],
      });
      queryClient.invalidateQueries({
        queryKey: ["bannerbearImages", campaignId],
      });
      queryClient.invalidateQueries({
        queryKey: ["campaigns", advertiserId, 0],
      });
    },
  });
}
