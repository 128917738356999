import {
  Controller,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";

import { setHours, setMilliseconds, setMinutes, setSeconds } from "date-fns";

import { Selection } from "../../../../components/select";
import Input from "../../../../components/input";

import { ICampaignFieldsResponse } from "../../../../services/industry-organization-campaign-fields";

import { CampaignFieldData } from "../../../../interfaces";

export const renderCampaignFieldsComponents = ({
  isEditEnabled,
  field,
  campaignFieldVals,
  control,
  errors,
  campaignFields,
  multiSelectFieldIds,
  setCampaignFieldVals,
  setValue,
  trigger,
  getValues,
  advertisersIndustry,
}: {
  isEditEnabled: boolean;
  field: ICampaignFieldsResponse;
  campaignFieldVals: CampaignFieldData;
  control: any;
  errors: Record<string, any>;
  campaignFields: ICampaignFieldsResponse[] | undefined;
  multiSelectFieldIds: {
    id: number;
    campaignFieldName: string;
    campaignFieldId: number;
  }[];
  setCampaignFieldVals: React.Dispatch<React.SetStateAction<CampaignFieldData>>;
  setValue: UseFormSetValue<FieldValues>;
  trigger: UseFormTrigger<CampaignFieldData>;
  getValues: UseFormGetValues<FieldValues>;
  advertisersIndustry: any;
}) => {
  function handleFieldChange(name: string, value: any) {
    let formattedValue = value;

    const isComponentDateType =
      campaignFields?.find((d) => d.campaignFieldName === name)?.type ===
      "DATE";

    if (isComponentDateType) {
      if (value && !isNaN(Date.parse(value))) {
        const localDate = setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(value), 0), 0), 0),
          0
        );
        formattedValue = localDate.toISOString();
      } else {
        formattedValue = "";
      }
    }

    if (Array.isArray(value)) {
      const item = multiSelectFieldIds.find(
        (item) => item.campaignFieldName === name
      );

      const id = item?.id;
      const campaignFieldId = item?.campaignFieldId;

      if (value.length === 0) {
        formattedValue = [];

        setCampaignFieldVals((prevValues) => ({
          ...prevValues,
          [name]: [
            {
              id,
              campaignFieldId,
              value: [],
              label: "",
            },
          ],
        }));

        setValue(name, [
          {
            id,
            campaignFieldId,
            value: [],
            label: "",
          },
        ]);
        return;
      }

      formattedValue = value.map((item) => ({
        ...item,
        id,
        campaignFieldId,
        label: item.label,
        value: item.value,
      }));
      setCampaignFieldVals((prevValues) => ({
        ...prevValues,
        [name]: [...formattedValue],
      }));
      setValue(name, [...formattedValue]);
      return;
    }

    if (!formattedValue.value && !isComponentDateType) {
      setCampaignFieldVals((prevValues) => ({
        ...prevValues,
        [name]: {
          ...prevValues[name],
          value: "",
          label: "",
        },
      }));
      setValue(name, {
        ...getValues(name),
        value: "",
        label: "",
      });
    } else if (formattedValue?.value && !isComponentDateType) {
      setCampaignFieldVals((prevValues) => ({
        ...prevValues,
        [name]: {
          ...prevValues[name],
          value: formattedValue.value,
          label: formattedValue.label,
        },
      }));
      setValue(name, {
        ...getValues(name),
        value: formattedValue.value,
        label: formattedValue.label,
      });
    } else {
      setCampaignFieldVals((prevValues) => ({
        ...prevValues,
        [name]: {
          ...prevValues[name],
          label: formattedValue,
          value: formattedValue,
        },
      }));
      setValue(name, {
        ...getValues(name),
        value: formattedValue,
        label: formattedValue,
      });
    }
  }

  return (() => {
    switch (field.type) {
      case "STRING":
        return (
          <>
            <Controller
              name={field.campaignFieldName}
              control={control}
              defaultValue={campaignFieldVals[field.campaignFieldName] || ""}
              rules={{
                validate: {
                  required: (f) => {
                    if (
                      field.campaignFieldName
                        .toLocaleLowerCase()
                        .includes("radius") &&
                      advertisersIndustry === "Real Estate" &&
                      f.value < 15
                    ) {
                      return `${"The radius must be at least 15 miles"}`;
                    }
                    if (field.isRequired && !f.value) {
                      return `${
                        field.campaignFieldName || "This field"
                      } is required`;
                    }
                    return true;
                  },
                },
              }}
              render={({ field: cfield }) => (
                <div className="flex">
                  <div className=" xs:w-[18.75rem] sm:w-[27.5rem] ">
                    <Input
                      {...cfield}
                      value={cfield.value?.value || ""}
                      label={""}
                      disabled={!field.isEditible}
                      className={`
                          w-full border-[1px] px-3 focus:outline-none py-3 font-interRegular text-sm text-primary rounded-[0.625rem]
                          ${
                            errors[cfield.name]?.message
                              ? "border-[#D34638]"
                              : "border-[#40444f]"
                          }`}
                      type="text"
                      onChange={(e) => {
                        const updatedValue = {
                          ...cfield.value,
                          label: e.target.value,
                          value: e.target.value,
                        };
                        handleFieldChange(cfield.name, updatedValue);
                        trigger(field.campaignFieldName);
                      }}
                    />
                  </div>
                  {errors[cfield.name]?.message && (
                    <p className="text-colorDelete pl-3 pt-1 mt-2 font-interSemiBold text-sm">
                      {errors[cfield.name]?.message}
                    </p>
                  )}
                </div>
              )}
            />
          </>
        );
      case "DROPDOWN":
        const dropdownData =
          field.options?.map((option) => ({
            value: option,
            label: option,
          })) || [];
        return (
          <Controller
            name={field.campaignFieldName}
            control={control}
            defaultValue={campaignFieldVals[field.campaignFieldName]}
            disabled={!field.isEditible}
            rules={{
              validate: {
                required: (f) => {
                  if (field.isRequired && !f.value) {
                    return `${
                      field.campaignFieldName || "This field"
                    } is required`;
                  }
                  return true;
                },
              },
            }}
            render={({ field }) => (
              <div className="flex">
                <div className="xs:w-[18.75rem] sm:w-[27.5rem]">
                  <Selection
                    isMulti={undefined}
                    data={dropdownData}
                    value={campaignFieldVals[field.name]}
                    onChange={(selectedOption: any) => {
                      if (field.disabled) return;
                      handleFieldChange(field.name, selectedOption);
                    }}
                    customStyle={{
                      selectContainer: {
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: `${
                          errors[field.name]?.message ? "#D34638" : "#40444f"
                        }`,
                        borderRadius: "0.625rem",
                        width: "100%",
                        marginTop: "0.25rem",
                        backgroundColor: `${
                          field.disabled ? "#f3f4f6" : "white"
                        }`,
                      },
                      color: "#d3d3d3",
                      valueContainer: {
                        paddingInline: "0.75rem",
                        paddingBlock: "0.43rem",
                      },
                      optionisSelectedBgColor: "transparent",
                      optionisFocusedBgColor: "#d7edff",
                      optionisFocusedTextColor: "#0e73f6",
                      singleValStyles: {
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                </div>
                {errors[field.name]?.message && (
                  <p className="text-colorDelete mt-2 pl-3 pt-1 font-interSemiBold text-sm">
                    {errors[field.name]?.message}
                  </p>
                )}
              </div>
            )}
          />
        );
      case "MULTISELECT":
        const multiDropdownData =
          field.options?.map((option) => ({
            value: option,
            label: option,
          })) || [];

        const multiVals = campaignFieldVals[field.campaignFieldName];
        return (
          <Controller
            name={field.campaignFieldName}
            control={control}
            defaultValue={campaignFieldVals[field.campaignFieldName]}
            disabled={!field.isEditible}
            rules={{
              validate: {
                required: (f) => {
                  if (
                    (field.isRequired && !f.value) ||
                    (field.isRequired && f.value.length === 0) ||
                    (field.isRequired && f.value.length === undefined)
                  ) {
                    return `${
                      field.campaignFieldName || "This field"
                    } is required`;
                  }
                  return true;
                },
              },
            }}
            render={({ field }) => (
              <div className="flex">
                <div className="xs:w-[18.75rem] sm:w-[27.5rem]">
                  <Selection
                    key={field.name}
                    isMulti={true}
                    isClearableEnabled={false}
                    data={multiDropdownData}
                    value={multiVals[0]?.value?.length > 0 ? multiVals : null}
                    onChange={(selectedOption: any) => {
                      if (field.disabled) return;
                      handleFieldChange(field.name, selectedOption);
                    }}
                    customStyle={{
                      selectContainer: {
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: `${
                          errors[field.name]?.message ? "#D34638" : "#40444f"
                        }`,
                        borderRadius: "0.625rem",
                        width: "100%",
                        fontSize: 14,
                        marginTop: "0.25px",
                        backgroundColor: `${
                          field.disabled ? "#f3f4f6" : "white"
                        }`,
                      },
                      color: "#d3d3d3",
                      valueContainer: {
                        paddingInline: "0.75rem",
                        paddingBlock: "0.43rem",
                      },
                      optionisSelectedBgColor: "transparent",
                      optionisFocusedBgColor: "#d7edff",
                      optionisFocusedTextColor: "#0e73f6",
                      singleValStyles: {
                        backgroundColor: "transparent",
                      },
                      multiValStyles: {
                        backgroundColor: "#d7edff",
                        color: "#0e73f6",
                      },
                      multiValLabelStyles: {
                        color: "#0e73f6",
                      },
                    }}
                  />
                </div>
                {errors[field.name]?.message && (
                  <p className="text-colorDelete mt-2 pl-3 pt-1 font-interSemiBold text-sm">
                    {errors[field.name]?.message}
                  </p>
                )}
              </div>
            )}
          />
        );
      default:
        return (
          <div>
            <p>{field.defaultValue}</p>
          </div>
        );
    }
  })();
};
