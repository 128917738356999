import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../axios";
import { queryClient } from "../../App";

import { userQueryKeys } from "./enums";
import { IUserData } from "../../interfaces";
import { IUsersData } from "../../interfaces/user/index";

/** CREATE USER */
const createSelf = (data: IUserData) => {
  return request({
    url: "user",
    method: "post",
    data,
  });
};
export const useCreateSelf = () =>
  useMutation({
    mutationFn: createSelf,
    onSuccess: invalidateSelf,
  });

/** GET USER */
const getSelf = () => {
  return request({
    url: `user/get-self`,
    method: "get",
  });
};
export const useGetSelf = (token?: string) => {
  return useQuery({
    queryKey: userQueryKeys.self,
    queryFn: getSelf,
    enabled: !!token,
    retry: 1,
  });
};

/** UPDATE USER */
const updateSelf = (data: IUserData) => {
  return request({
    url: "user",
    method: "patch",
    data,
  });
};

export const useUpdateSelf = () =>
  useMutation({
    mutationFn: updateSelf,
    onSuccess: invalidateSelf,
  });

/** UPLOAD PHOTO */
type TUploadPhotoResult = { url: string };
const uploadPhoto = (photoData: FormData): Promise<TUploadPhotoResult> =>
  request({
    url: "file/upload",
    method: "post",
    data: photoData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const useUploadPhoto = () =>
  useMutation<TUploadPhotoResult, Error, FormData>({
    mutationFn: uploadPhoto,
    onSuccess: invalidateSelf,
  });

/**
 * INVALIDATIONS
 */
export const invalidateSelf = async () => {
  await queryClient.invalidateQueries({ queryKey: userQueryKeys.self });
};

/** ALL USERS */

function getAllUsers(): Promise<IUsersData[]> {
  return request({
    url: "user",
    method: "GET",
  });
}

export const useAllUsers = () => {
  return useQuery({
    queryKey: ["allUsers"],
    queryFn: getAllUsers,
  });
};
