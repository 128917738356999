import { useQuery } from "@tanstack/react-query";
import { request } from "../axios";

export interface IDomainData {
  id: number;
  name: string;
  logoUrl: string;
  themeColor: string;
  createdAt: Date;
  updatedAt: Date;
  subDomain: string;
  description: null | string;
  termsAndConditions: null | string;
  fee: number | null;
  largeLogoUrl: string;
}

function getDomainsStyle(domainName: string | null): Promise<IDomainData> {
  return request({
    url: `organization/subdomain/${domainName}`,
  });
}

export const useDomainStyle = (domainName: string | null) => {
  return useQuery({
    queryKey: ["domainStyle"],
    queryFn: () => getDomainsStyle(domainName),
    retry: 1,
    enabled: !!domainName,
    refetchOnWindowFocus: false,
  });
};
