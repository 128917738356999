import { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

import { useAppStore } from "../../store";
import { useShallow } from "zustand/shallow";

import SidebarLogo from "../../components/sideBarLogo";

import ToggleIcon from "../../assets/icons/toggle-icon.svg";

import { ISidebarLink, sidebarLinks } from "../../constants/sidebarData";

const Sidebar = ({
  showSidebarLogo,
  showSideBar,
  showWizard,
}: {
  showSidebarLogo?: boolean;
  showSideBar?: boolean;
  showWizard?: boolean;
}) => {
  const { isSidebarOpen, setIsSidebarOpen } = useAppStore(
    useShallow((state) => ({
      isSidebarOpen: state.isSidebarOpen,
      setIsSidebarOpen: state.setIsSidebarOpen,
    }))
  );

  const sideNavRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 850) {
        setIsSidebarOpen(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [setIsSidebarOpen]);

  return (
    <div ref={sideNavRef}>
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        aria-controls="default-sidebar"
        type="button"
        className={`fixed -top-1 z-[9999]  -left-3  p-2 mt-0 ms-3 text-sm text-gray-500 rounded-lg   focus:outline-none  `}
      >
        <img src={ToggleIcon} className="w-12 h-12" alt="toggle-icon" />
      </button>

      <aside
        id="default-sidebar"
        className={`fixed xs:top-0 md:top-14 border-r-2 border-solid border-[#E5E9EB] left-0 z-40 h-screen transition-all transform ${
          isSidebarOpen
            ? "translate-x-0 w-[15rem]"
            : "md:w-14 md:block  xs:hidden"
        } `}
        aria-label="Sidebar"
      >
        <div className="relative h-full flex flex-col bg-white px-3 py-4">
          {showSidebarLogo && <SidebarLogo />}
          <ul className="space-y-2 font-medium mt-4 flex-grow overflow-y-auto">
            {showSideBar &&
              sidebarLinks.map((link: ISidebarLink) => (
                <li key={link.id}>
                  <NavLink
                    to={link.link}
                    target={link.link.includes("http") ? "_blank" : ""}
                    className={({ isActive }) =>
                      `flex items-center p-2 my-3  rounded-lg hover:bg-[#D7EDFF] group 
                ${isActive ? " bg-[#D7EDFF] " : ""}`
                    }
                  >
                    {({ isActive }) => (
                      <div className="flex items-center space-x-2">
                        <span className="w-5 h-5">{link.icon(isActive)}</span>
                        <span
                          className={`ms-3 text-sm ${
                            isSidebarOpen ? "inline-block" : "hidden"
                          } ${
                            isActive
                              ? "font-InterSemiBold text-[#0E73F6]"
                              : "font-interRegular text-primary"
                          }`}
                        >
                          {link.text}
                        </span>
                      </div>
                    )}
                  </NavLink>
                </li>
              ))}
          </ul>
          {/* Logout div starts */}
          {/* <div
            onClick={signOut}
            className="flex-shrink-0 p-2 mb-14 flex items-center rounded-lg hover:bg-[#D7EDFF] cursor-pointer"
          >
            <div className="w-full flex">
              <svg
                className="flex-shrink-0 fill-current w-5 h-5 text-gray-500 transition duration-75"
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
              >
                <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z" />
              </svg>
              <p
                className={`ms-2 text-sm text-primary font-interRegular ${
                  isSidebarOpen ? "inline-block" : "hidden"
                }`}
              >
                Sign Out
              </p>
            </div>
          </div> */}
          {/* Logout div ends */}
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
