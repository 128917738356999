import Select, { StylesConfig, components } from "react-select";
import { CustomStyleProps, ILabel } from "../../interfaces";
import { forwardRef } from "react";

interface SelectionProps {
  data: any;
  isMulti?: true;
  placeholder?: string;
  customStyle: CustomStyleProps;
  enableDefaultValue?: boolean;
  defaultSelectedValues?: any[];
  onChange?: (selectedOption: any, actionMeta: any) => void;
  closeMenuOnSelect?: boolean;
  isClearableEnabled?: boolean;
  value?: any;
  isPending?: boolean;
  defaultSingleValue?: any;
  handleLoadMore?: () => void;
  hasMoreData?: boolean;
}

const customStyles = (
  customStyle: CustomStyleProps
): StylesConfig<any, true> => ({
  input: (base) => ({
    ...base,
    // color: "#40444f",
    color: "#154ce3",
  }),
  container: (base) => ({
    ...base,
    ...customStyle.container,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    ...customStyle.selectContainer,
    outline: "none",
    boxShadow: "none",
    fontFamily: "InterRegular",
  }),
  valueContainer: (base) => ({
    ...base,
    ...customStyle.valueContainer,
  }),
  option: (styles, { data, isSelected, isFocused, isDisabled }) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    // fontFamily: "ManropeRegular",
    fontFamily: "InterRegular",
    padding: 10,
    backgroundColor: isSelected
      ? customStyle.optionisSelectedBgColor
      : isFocused
      ? customStyle.optionisFocusedBgColor
      : undefined,
    color: isSelected
      ? customStyle.color
      : isFocused
      ? customStyle.optionisFocusedTextColor
      : "black",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    ...customStyle.multiValLabelStyles,
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    ...customStyle.multiValStyles,
  }),
  singleValue: (styles) => ({
    display: "flex",
    alignItems: "center",
    ...styles,
    ...customStyle.singleValStyles,
  }),
});

const formatOptionLabel = (data: ILabel) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    {data.flag && (
      <img
        src={data.flag}
        alt={data.label}
        style={{ marginRight: 10, width: 20, height: 20 }}
      />
    )}

    {data.val ?? data.label}
  </div>
);

const LoadMoreOption = (props: any) => {
  if (props.data.isLoadMore) {
    return (
      <div
        style={{
          padding: "10px",
          textAlign: "center",
          background: "transparent",
          cursor: "pointer",
        }}
        onClick={() => {
          props.selectOption(props.data); // Trigger load more
        }}
      >
        <p
          style={{
            fontSize: "14px",
          }}
        >
          {props.data.label}
        </p>
      </div>
    );
  }
  return <components.Option {...props} />;
};

export const Selection = forwardRef<HTMLDivElement, SelectionProps>(
  (
    {
      data,
      isMulti,
      placeholder,
      customStyle,
      enableDefaultValue,
      defaultSelectedValues,
      onChange,
      closeMenuOnSelect,
      isClearableEnabled,
      value,
      isPending,
      defaultSingleValue,
      handleLoadMore,
      hasMoreData,
    },
    ref
  ) => {
    return (
      <Select
        closeMenuOnSelect={closeMenuOnSelect}
        // options={
        //   isPending
        //     ? [{ value: "loading", label: "Loading...", isDisabled: true }]
        //     : data
        // }
        options={
          isPending
            ? [{ value: "loading", label: "Loading...", isDisabled: true }]
            : hasMoreData
            ? [
                ...data,
                {
                  value: "load-more",
                  label: "load more",
                  isLoadMore: true,
                },
              ]
            : data
        }
        // onChange={onChange}
        onChange={(selectedOption: any, actionMeta: any) => {
          if (selectedOption.value === "load-more") {
            handleLoadMore && handleLoadMore();
          } else {
            onChange && onChange(selectedOption, actionMeta);
          }
        }}
        components={{
          Option: LoadMoreOption,
        }}
        isMulti={isMulti}
        isClearable={isClearableEnabled}
        placeholder={placeholder ?? ""}
        value={value}
        defaultValue={
          isMulti && enableDefaultValue
            ? defaultSelectedValues
            : enableDefaultValue && defaultSingleValue
        }
        styles={customStyles({ ...customStyle })}
        formatOptionLabel={formatOptionLabel}
      />
    );
  }
);
