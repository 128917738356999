import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useShallow } from "zustand/shallow";
import { useAppStore } from "../../../store";

import Modal from "../../../components/modal";
import RoundedButton from "../../../components/roundedButton";

import { useDomainStyle } from "../../../services/multitenacy-config";
import {
  useCampaignStrategyInfo,
  useUpdateCampaign,
} from "../../../services/campaigns";

function CampaignConfirmationModal() {
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const {
    selectedAdvertiser,
    campaignId,
    setIsConfirmationModalShown,
    setIsCampaignActiveInfoModalShown,
    domainName,
  } = useAppStore(
    useShallow((state) => ({
      selectedAdvertiser: state.selectedAdvertiser,
      campaignId: state.campaignId,
      setIsConfirmationModalShown: state.setIsConfirmationModalShown,
      setIsCampaignActiveInfoModalShown:
        state.setIsCampaignActiveInfoModalShown,
      domainName: state.domainName,
    }))
  );

  const {
    mutateAsync: updateCampaign,
    isPending: isCampaignUpdatePending,
    isError: isCampaignUpdateFailed,
    isSuccess: isCampaignUpdated,
  } = useUpdateCampaign(selectedAdvertiser, campaignId);

  const { data: strategyInfo } = useCampaignStrategyInfo(
    campaignId,
    selectedAdvertiser
  );

  const { data: domainStyleData } = useDomainStyle(domainName);
  const legalText = domainStyleData?.termsAndConditions;

  async function handleStartCampaign(campaignStatus: string | undefined) {
    setIsConfirmationModalShown(true);

    if (isCampaignUpdatePending) return;
    if (campaignStatus === "active") return;
    if (!isChecked) return;

    if (campaignStatus !== "active") {
      await updateCampaign({
        campaignId,
        data: { status: "active" },
      });
    }
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    setIsChecked(event.target.checked);
  }

  useEffect(() => {
    if (isCampaignUpdated) {
      navigate("/campaigns");
      setIsCampaignActiveInfoModalShown(true);
      setIsConfirmationModalShown(false);
    }
    //eslint-disable-next-line
  }, [isCampaignUpdated]);

  return (
    <div>
      <Modal
        setIsModalOpen={setIsConfirmationModalShown}
        isModalCloseBtnDisabled={isCampaignUpdatePending}
        modalBodyContent={
          <div className="flex flex-col px-1 max-w-2xl">
            <h3 className="text-base font-interSemiBold text-primary -mt-4 mb-2">
              Confirmation
            </h3>
            <p className="font-interRegular text-sm text-primary leading-6">
              We’re excited that you're eager to move forward. Our team will be
              in touch with you shortly to kick off your campaign and tailor
              everything to your needs. Get ready to take your business to the
              next level!
            </p>
            {legalText && (
              <div className="mt-8">
                <h3 className="text-base font-interSemiBold text-primary  mb-2">
                  Legal
                </h3>
                <p className="font-interRegular text-sm text-primary leading-6">
                  {legalText}
                </p>
              </div>
            )}
            <div className="flex flex-col  mt-10">
              <label className="flex items-center ">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className="form-checkbox h-5 w-5 cursor-pointer flex-shrink-0 text-[#d7edff]"
                />
                <span className="text-primary text-sm ml-3  max-w-[90%]  font-interRegular">
                  I confirm that I have read and agree to the terms and
                  conditions
                </span>
              </label>
            </div>
            <div className="ml-auto mt-10 flex">
              {isCampaignUpdateFailed && (
                <div className="self-center mr-6">
                  <p className="text-sm font-interSemiBold text-colorDelete">
                    Something went wrong with starting your campaign !
                  </p>
                </div>
              )}

              <RoundedButton
                onClick={() => setIsConfirmationModalShown(false)}
                type="button"
                className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto mr-5"
                borderRadius="rounded-md"
                bgColor="#FFFFFF"
                borderColor="#C1C9D2"
                text={
                  <p className=" text-sm text-primary inline-flex items-center font-interRegular">
                    Cancel
                  </p>
                }
              />
              <RoundedButton
                onClick={async () =>
                  handleStartCampaign(strategyInfo?.campaignStatus)
                }
                type="button"
                isEnabled={isChecked}
                className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto "
                borderRadius="rounded-md"
                bgColor="bg-[#49AC1B]"
                text={
                  <div className=" inline-flex items-center ">
                    {isCampaignUpdatePending ? (
                      <div className="w-12 h-5 pt-0.5">
                        <div className="circle-spinner-white  w-4 h-4 mx-auto"></div>
                      </div>
                    ) : (
                      <p className="text-sm text-white font-interRegular">
                        Confirm
                      </p>
                    )}
                  </div>
                }
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default CampaignConfirmationModal;
