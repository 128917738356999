import "./App.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { APIProvider } from "@vis.gl/react-google-maps";

import Chatbot from "./components/chatbot";

import useInitializeDomain from "./hooks/useInitializeDomain";
import useAuthSubscriber from "./hooks/useAuthSubscriber";

import { RootNavigator } from "./navigation";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
  },
});

function App() {
  useInitializeDomain();
  useAuthSubscriber();

  return (
    <QueryClientProvider client={queryClient}>
      <Chatbot />
      <APIProvider apiKey={"AIzaSyDKVLTjkjPNb1la_wtdbnlBc-yFhRI6BLk"}>
        <RootNavigator />
      </APIProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
