import { create } from "zustand";
import { persist } from "zustand/middleware";

import { createStepSlice, StepSlice } from "./stepSlice";
import { AdvertiserSlice, createAdvertiserSlice } from "./advertiserSlice";
import { createCampaignSlice, ICampaign } from "./campaignSlice";
import {
  createCampaignModalSlice,
  ICampaignInfoModal,
} from "./campaignSlice/campaignModal";
import { createSidebarSlice, ISidebarProps } from "./sidebarSlice";
import { createAddWebsiteSlice, IAddWebsiteProps } from "./addWebsiteSlice";
import { createChatBotSlice, IChatBotProps } from "./chatbotSlice";
import { createNotifierSlice, INotifierProps } from "./notifierSlice";
import { createDomainSlice, IDomainSliceProps } from "./domainSlice";
import { createAuthSlice, IAuthSliceProps } from "./authSlice";

export type StoreState = AdvertiserSlice &
  StepSlice &
  ICampaign &
  ICampaignInfoModal &
  ISidebarProps &
  IAddWebsiteProps &
  IChatBotProps &
  INotifierProps &
  IDomainSliceProps &
  IAuthSliceProps;

export const useAppStore = create<StoreState>()(
  persist<StoreState>(
    (...a) => ({
      ...createAdvertiserSlice(...a),
      ...createStepSlice(...a),
      ...createCampaignSlice(...a),
      ...createCampaignModalSlice(...a),
      ...createSidebarSlice(...a),
      ...createAddWebsiteSlice(...a),
      ...createChatBotSlice(...a),
      ...createNotifierSlice(...a),
      ...createDomainSlice(...a),
      ...createAuthSlice(...a),
    }),
    {
      name: "app-storage",
      partialize: (state) =>
        ({
          user: state.user,
          selectedAdvertiser: state.selectedAdvertiser ?? null,
          domainName: state.domainName,
        } as any),
      onRehydrateStorage: () => (state, error) => {
        if (error) {
          console.error("Error rehydrating store:", error);
        } else {
          if (
            state?.selectedAdvertiser !== null &&
            state?.selectedAdvertiser !== undefined
          ) {
            state.setStep(0);
          }
        }
      },
    }
  )
);
