import { useNavigate } from "react-router-dom";

import { useAppStore } from "../../store";
import { useShallow } from "zustand/shallow";

import { useGetSelf } from "../../services/user/index";

function UnauthorizedScreen() {
  const navigate = useNavigate();

  const { sessionToken, signOut } = useAppStore(
    useShallow((state) => ({
      sessionToken: state.sessionToken,
      signOut: state.signOut,
    }))
  );

  const { data: usersData } = useGetSelf(sessionToken);

  async function goBack() {
    if (!usersData) {
      await signOut();
    }
    navigate(-1);
  }

  return (
    <div className="flex flex-col items-center text-center my-2">
      <div>
        <p className="font-interBold text-4xl ">401</p>
        <p className="font-interBold text-4xl ">Unauthorized</p>
      </div>
      <p
        onClick={goBack}
        className="text-sm cursor-pointer font-interRegular my-5 text-colorBlue"
      >
        Go back
      </p>
    </div>
  );
}

export default UnauthorizedScreen;
