import { useNavigate } from "react-router-dom";

function NotFoundScreen() {
  const navigate = useNavigate();

  async function goBack() {
    navigate(-1);
  }

  return (
    <div className="flex flex-col items-center text-center my-2">
      <div>
        <p className="font-interBold text-4xl ">404</p>
        <p className="font-interBold text-4xl ">Not Found</p>
      </div>
      <p
        onClick={goBack}
        className="text-sm cursor-pointer font-interRegular my-5 text-colorBlue"
      >
        Go back
      </p>
    </div>
  );
}

export default NotFoundScreen;
