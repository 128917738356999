export const randomColor = () => {
  const colors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#EF4444",
    "#A633FF",
    "#3B82F6",
    "#10B981",
    "#F59E0B",
    "#6366F1",
    "#8B5CF6",
    "#EC4899",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};
