import { useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { useAppStore } from "../../store";

import Tooltip from "../../components/tooltip";

import SparkLogo from "../../assets/icons/spark-logo-white-text.png";
import { ReactComponent as SignoutIcon } from "../../assets/icons/sign-out.svg";
import { ReactComponent as UserProfileIcon } from "../../assets/icons/user-profile-mui.svg";
import { ReactComponent as SupportIcon } from "../../assets/icons/contact-support-mui.svg";

import { useDomainStyle } from "../../services/multitenacy-config";

import { isDarkColor } from "../../utils/isDarkColor";
import { useShallow } from "zustand/shallow";

interface INavBarProps {
  customNavLinkContainer?: React.ReactNode;
  isDefaultNavLinksEnabled?: boolean;
  isProfileNavEnabled?: boolean;
}

function Navbar({ customNavLinkContainer }: INavBarProps) {
  const navigate = useNavigate();

  const {
    user,
    signMeOut,
    domainName,
    setSelectedAdvertiser,
    handleToggle,
    setIsToggled,
    setMessages,
  } = useAppStore(
    useShallow((state) => ({
      user: state.user,
      signMeOut: state.signOut,
      domainName: state.domainName,
      setSelectedAdvertiser: state.setSelectedAdvertiser,
      handleToggle: state.handleToggle,
      setIsToggled: state.setIsToggled,
      setMessages: state.setMessages,
    }))
  );

  const isEmailVerified = useMemo(() => user?.emailVerified, [user]);

  const { data: domainStyleData } = useDomainStyle(domainName);

  const bgColor = domainStyleData?.themeColor ?? "#0e086a";
  const navLinkColor = isDarkColor(bgColor) ? "#ffffff" : "#40444f";

  function AppNameLogo() {
    const onClickLogo = () => navigate("/");
    return (
      <div
        className=" mr-auto  md:translate-x-0 xs:translate-x-0  flex items-center cursor-pointer"
        onClick={onClickLogo}
      >
        <img
          src={domainStyleData?.logoUrl ?? SparkLogo}
          alt="veloxi-ai-logo.png"
          // className="w-30 h-12 object-contain"
          className="w-full h-full object-contain"
        />
      </div>
    );
  }

  function navigateToUserProfile() {
    navigate("/profile");
  }

  async function signOut() {
    setSelectedAdvertiser(null);
    signMeOut();
    setMessages([]);
    setIsToggled(true);
  }

  const NavRightSide = () => (
    <div className="flex ">
      {customNavLinkContainer ? (
        customNavLinkContainer
      ) : (
        <ul className="flex ml-auto mr-2 items-center">
          <li className="mr-6">
            <div
              style={{
                color: `${navLinkColor}`,
              }}
              onClick={handleToggle}
            >
              <Tooltip
                Icon={SupportIcon}
                bodyText="Support"
                className={"w-6 h-6  cursor-pointer fill-current"}
              />
            </div>
          </li>
          <li className="relative mr-6">
            {!isEmailVerified && (
              <div className="absolute right-0 -top-3">
                <span className="text-xl font-interBold text-colorDelete">
                  !
                </span>
              </div>
            )}
            <div
              style={{
                color: `${navLinkColor}`,
              }}
              onClick={navigateToUserProfile}
            >
              <Tooltip
                Icon={UserProfileIcon}
                bodyText="User profile"
                className={"w-6 h-6  cursor-pointer fill-current"}
              />
            </div>
          </li>
          <li>
            <div
              style={{
                color: `${navLinkColor}`,
              }}
              onClick={signOut}
            >
              <Tooltip
                Icon={SignoutIcon}
                bodyText="Log Out"
                className={"w-6 h-6  cursor-pointer fill-current"}
              />
            </div>
          </li>
        </ul>
      )}
    </div>
  );

  return (
    <div
      style={{
        backgroundColor: `${domainStyleData?.themeColor ?? "#0e086a"}`,
      }}
      className={`flex items-center justify-center w-full h-14 xs:pl-14  pr-8 fixed z-50`}
    >
      <div className="flex w-full">
        <AppNameLogo />
        <NavRightSide />
      </div>
    </div>
  );
}

export default Navbar;
