import PieChart from "../../../../components/pieChart";
import EstimationTable from "../../../../components/estimationTable";

import { IBudgetOrganizationChannelsResponse } from "../../../../services/advertiser/organization-budget-channel";
import { ICampaignStrategyResponse } from "../../../../services/campaigns";

import { formatDate } from "../../../../utils/formatDate";

import { BUDGET_CHANNEL_MAPPING_READ_MODE } from "../../../../constants/wizard/campaign";

const CampaignStrategyReadMode = ({
  campaignStrategyData,
  organizationBudgetChannels,
}: {
  campaignStrategyData: ICampaignStrategyResponse | undefined;
  organizationBudgetChannels:
    | []
    | IBudgetOrganizationChannelsResponse[]
    | undefined;
}) => {
  const pieChartData = organizationBudgetChannels
    ?.filter(
      (channel) =>
        BUDGET_CHANNEL_MAPPING_READ_MODE[channel.budgetChannel]?.key !==
        undefined
    )
    .map((channel) => {
      const channelKey = BUDGET_CHANNEL_MAPPING_READ_MODE[channel.budgetChannel]
        .key as keyof ICampaignStrategyResponse["campaignBudget"];

      const channelColor =
        BUDGET_CHANNEL_MAPPING_READ_MODE[channel.budgetChannel].color;
      const value = campaignStrategyData?.campaignBudget[channelKey];

      return {
        key: `campaign-strategy-pie-${channelKey}`,
        value: value,
        color: channelColor,
        title: channel.channelName,
      };
    });

  return (
    <div className=" flex mt-7">
      <div className="flex flex-col w-[65%]">
        <section id="strategy-summary">
          <h3 className="text-primary font-interSemiBold text-sm">Summary</h3>
          <p className="text-sm text-primary mt-1.5 font-interRegular">
            Your campaign
            <span className="font-interSemiBold mx-1.5">
              {campaignStrategyData?.campaignName}
            </span>
            is designed to achieve a total budget of
            <span className="font-interSemiBold mx-1.5">
              ${campaignStrategyData?.campaignBudget.budget}
            </span>
            to elevate your brand. Your budget was determined based on your
            location and industry, assuming an annual revenue of $100,000.
          </p>
          <p className="text-sm text-primary mt-6 font-interRegular">
            The Campaign will run from
            <span className="font-interSemiBold mx-1.5">
              {campaignStrategyData?.campaignStartDate?.value
                ? formatDate(campaignStrategyData?.campaignStartDate?.value)
                : "null date"}
            </span>
            to
            <span className="font-interSemiBold mx-1.5">
              {campaignStrategyData?.campaignEndDate?.value
                ? formatDate(campaignStrategyData?.campaignEndDate?.value)
                : "null date"}
            </span>
            with a central theme of
            <span className="font-interSemiBold mx-1.5">
              {campaignStrategyData?.campaignTheme?.value ?? null}
            </span>
            , which will shape the messaging and outreach effort to boost
            engagement and impact.
          </p>
          <p className="text-sm text-primary mt-6 font-interRegular">
            Feel free to adjust these settings anytime by clicking on “Edit”.
          </p>
        </section>
        <section id="strategy-allocation" className="mt-10">
          <h3 className="text-primary font-interSemiBold text-sm mb-1.5">
            Budget Allocation
          </h3>
          <p className="text-sm text-primary font-interRegular">
            To get the most out of your ad budget, we’ll allocate it across the
            best channels to ensure maximum impact and ROI.
          </p>
          <div className="flex mt-6">
            {campaignStrategyData &&
              organizationBudgetChannels &&
              pieChartData && (
                <PieChart
                  topContainer="flex flex-col space-y-5 w-[3rem] order-2"
                  indicatorContainer="flex items-center w-36"
                  circleContainer="relative w-[25rem]"
                  data={pieChartData}
                />
              )}
          </div>
        </section>
      </div>
      <EstimationTable
        className="w-[30%] ml-auto"
        campaignStrategyData={campaignStrategyData}
      />
    </div>
  );
};

export default CampaignStrategyReadMode;
