import React, { useEffect, useMemo } from "react";

import { useAppStore } from "../../../store";
import { useShallow } from "zustand/shallow";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useForm } from "react-hook-form";

import PasswordStrengthItem from "./passwordStrengthItem";

import ContentContainer from "../../../components/contentContainer";
import Input from "../../../components/input/inputWithToggle";
import RoundedButton from "../../../components/roundedButton";
import TopContent from "../../../components/topContent";

import AlertIcon from "../../../assets/icons/icon_alert-warning-yellow.png";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-icon-mui.svg";

import { ProfileContent } from "../../../interfaces";

import { evaluatePasswordStrength } from "../../../utils/passwordStrength";

interface ChangePasswordProps {
  data: {
    displayName: string;
    email: string;
    phoneNumber: string;
  };
  onClickGoBack: (content: ProfileContent) => void;
  updateUserPassword: (
    email: string,
    currentPassword: string,
    newPassword: string
  ) => Promise<void>;
  isUpdateUserPasswordLoading: boolean;
  isUpdateUserPasswordError: boolean;
  isUpdateUserPasswordSuccess: boolean;
  updateUserPasswordErrorMessage: string | null;
}

type TUserState = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const ChangePassword: React.FC<ChangePasswordProps> = ({
  data,
  onClickGoBack,
  updateUserPassword,
  isUpdateUserPasswordLoading,
  isUpdateUserPasswordError,
  isUpdateUserPasswordSuccess,
  updateUserPasswordErrorMessage,
}) => {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<TUserState>({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  const userData = watch();

  const { showNotifier, hideNotifier, clearAuthStates } = useAppStore(
    useShallow((state) => ({
      showNotifier: state.showNotifier,
      hideNotifier: state.hideNotifier,
      clearAuthStates: state.clearAuthStates,
    }))
  );

  const passwordMatchMsg = useMemo(
    // calculates if newPassword and confirmNewPassword are matching, if not, it returns a message
    // if confirmNewPassword is empty, it returns undefined so that the validation message is not displayed
    () =>
      !userData.confirmNewPassword
        ? undefined
        : userData.newPassword === userData.confirmNewPassword
        ? undefined
        : "Passwords are not matching",
    [userData.newPassword, userData.confirmNewPassword]
  );

  async function handleUpdateUserPassword() {
    if (passwordMatchMsg) {
      showNotifier({
        message: "Passwords do not match!",
        icon: AlertIcon,
      });
    } else if (Object.values(passwordStrength).includes(false)) {
      showNotifier({
        message: "New Password does not meet the requirements",
        icon: AlertIcon,
      });
    } else {
      await updateUserPassword(
        data.email,
        userData.currentPassword,
        userData.newPassword
      );
    }
  }

  function handleClickGoBack() {
    onClickGoBack("displayProfile");
    // we need to clearAuthStates manually here as there is no route change happening between displayProfile and editProfile interfaces
    hideNotifier();
    clearAuthStates();
  }

  function onSubmit() {
    handleUpdateUserPassword();
  }

  const passwordStrength = useMemo(
    () => evaluatePasswordStrength(userData.newPassword),
    [userData.newPassword]
  );

  function onUpdatePasswordResponse() {
    if (isUpdateUserPasswordSuccess) {
      onClickGoBack("displayProfile");
      clearAuthStates();
    } else if (isUpdateUserPasswordError) {
      const errorMessage =
        updateUserPasswordErrorMessage?.split(": ")[1].split("(")[0] ??
        "An error occurred while updating password.";
      toast.error(errorMessage);
      clearAuthStates();
    }
  }

  useEffect(() => {
    if (isUpdateUserPasswordSuccess || isUpdateUserPasswordError) {
      onUpdatePasswordResponse();
    }
    //eslint-disable-next-line
  }, [isUpdateUserPasswordSuccess, isUpdateUserPasswordError]);

  return (
    <div className="flex flex-col">
      <TopContent
        title="User Profile"
        subTitle="Manage your personal information"
      />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      <ContentContainer>
        <div className=" flex flex-1 flex-col p-12 pb-0  ">
          <div className=" flex flex-1 flex-col space-y-5 justify-between mb-12 items-center sm:items-stretch">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-1 flex-col w-[600px] space-y-6">
                <Input
                  name="currentPassword"
                  type="password"
                  label="Current Password"
                  register={register}
                  requiredMessage="Current Password is required"
                  validation={errors.currentPassword?.message}
                  reactHooksFormEnabled={true}
                  validationClassName="text-colorDelete text-sm  ml-2"
                  labelClassName="font-ManropeSemiBold text-base text-[#434343]"
                  className={`
                  ${errors.currentPassword?.message && "border-[#D34638]"}
                  
                  `}
                />
                <Input
                  type="password"
                  name="newPassword"
                  label="New Password"
                  register={register}
                  requiredMessage="New Password is required"
                  validation={errors.newPassword?.message}
                  reactHooksFormEnabled={true}
                  validationClassName="text-colorDelete text-sm  ml-2"
                  labelClassName="font-ManropeSemiBold text-base text-[#434343]"
                  className={`${
                    errors.newPassword?.message && "border-[#D34638]"
                  }`}
                />

                <div className="flex flex-row space-x-4 mb-6">
                  <div className="flex flex-col">
                    <PasswordStrengthItem
                      isChecked={passwordStrength.lowercase}
                      message="One lowercase character"
                    />
                    <PasswordStrengthItem
                      isChecked={passwordStrength.uppercase}
                      message="One uppercase character"
                    />
                    <PasswordStrengthItem
                      isChecked={passwordStrength.number}
                      message="One number"
                    />
                  </div>

                  <div className="flex flex-col space-y-2">
                    <PasswordStrengthItem
                      isChecked={passwordStrength.specialChar}
                      message="One special character"
                    />
                    <PasswordStrengthItem
                      isChecked={passwordStrength.length}
                      message="Minimum 8 characters"
                    />
                  </div>
                </div>

                <Input
                  type="password"
                  name="confirmNewPassword"
                  label="Confirm New Password"
                  register={register}
                  requiredMessage="Confirm New Password is required"
                  validation={
                    errors.confirmNewPassword?.message || passwordMatchMsg
                  }
                  reactHooksFormEnabled={true}
                  validationClassName="text-colorDelete text-sm  ml-2"
                  labelClassName="font-ManropeSemiBold text-base text-[#434343]"
                  className={`
                  ${
                    (errors.confirmNewPassword?.message || passwordMatchMsg) &&
                    "border-[#D34638]"
                  }
                  
                  `}
                />
              </div>

              <div className=" flex pt-10 items-center">
                <RoundedButton
                  className="py-2.5 px-4"
                  type="submit"
                  borderRadius="rounded-md"
                  text={
                    <p className=" text-sm text-white inline-flex items-center font-interRegular">
                      <EditIcon className="text-white fill-current mr-2 text-2xl " />
                      {isUpdateUserPasswordLoading ? "Saving..." : "SAVE"}
                    </p>
                  }
                />
                <p
                  onClick={handleClickGoBack}
                  className="text-sm cursor-pointer font-interRegular ml-7 text-colorBlue"
                >
                  Cancel
                </p>
                {/* <RoundedButton
                  text="BACK"
                  className=" w-32 h-10 bg-[#9BA3B0]"
                  onClick={handleClickGoBack}
                />
                <RoundedButton
                  className=" w-32 h-10"
                  type="submit"
                  text={isUpdateUserPasswordLoading ? "Loading..." : "SAVE"}
                /> */}
              </div>
            </form>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
};

export default ChangePassword;
