import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { ColDef } from "ag-grid-community";

import { toast, ToastContainer, Bounce } from "react-toastify";

import { useAppStore } from "../../../store";
import { useShallow } from "zustand/shallow";

import ContentContainer from "../../../components/contentContainer";
import RoundedButton from "../../../components/roundedButton";
import Table from "../../../components/table";
import { Selection } from "../../../components/select";
import TopContent from "../../../components/topContent";

import { ReactComponent as EditSvg } from "../../../assets/icons/edit-icon-mui.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete-icon-mui.svg";

import {
  IUnAssignAdvertiserReq,
  useAdvertisersUsers,
  useUnAssignRole,
  useUsersRoleForAdvertiser,
} from "../../../services/user-organization-advertiser-role";
import { useDomainStyle } from "../../../services/multitenacy-config";

import { formatDate } from "../../../utils/formatDate";

function Users() {
  const navigation = useNavigate();

  const { selectedAdvertiser, domainName } = useAppStore(
    useShallow((state) => ({
      selectedAdvertiser: state.selectedAdvertiser,
      domainName: state.domainName,
    }))
  );

  const { data: domainStyleData } = useDomainStyle(domainName);
  const organizationId = domainStyleData?.id ?? null;

  const [tableTotalPages, setTableTotalPages] = useState<number>(0);
  const [, setDataPageNumber] = useState<number>(0);

  const { data: usersRoleForAdvertiserData } = useUsersRoleForAdvertiser(
    selectedAdvertiser,
    organizationId
  );

  const { data: advertisersUsers } = useAdvertisersUsers(
    selectedAdvertiser,
    organizationId
  );

  const {
    mutateAsync: unAssignRole,
    isSuccess: isUnassignmentDone,
    isError: isUnassignmentFailed,
    isPending: isUnassignmentProcessing,
  } = useUnAssignRole(selectedAdvertiser);

  const numberOfItemsInEachPage = 10;

  const [gridApi, setGridApi] = useState<any>(null);
  const [filterStatus, setFilterStatus] = useState<string | undefined>(
    undefined
  );

  const columnData: ColDef[] = [
    {
      headerName: "EMAIL",
      field: "email",
      flex: 3,
      headerClass: "ag-header-border",
      sortable: false,
      filter: true,
      resizable: false,
    },
    {
      headerName: "ACCESS LEVEL",
      field: "role",
      sortable: false,
      flex: 1.5,
      headerClass: "ag-header-border",
      filter: true,
      resizable: false,
    },
    {
      headerName: "INVITED BY",
      field: "invitedBy",
      sortable: false,
      flex: 1.5,
      headerClass: "ag-header-border",
      filter: true,
      resizable: false,
    },
    {
      headerName: "ADDED ON",
      sortable: false,
      flex: 1.5,
      headerClass: "ag-header-border",
      filter: true,
      resizable: false,
      cellRenderer: (params: any) => {
        const { addedOn } = params.data;
        const formattedDate = formatDate(addedOn);
        return <p>{formattedDate}</p>;
      },
    },
    {
      headerName: "ACTION",
      sortable: false,
      width: 120,
      resizable: false,
      cellRenderer: (params: any) => {
        const { id, userId, role, isAssignable } = params.data;
        return (
          <div className="flex">
            <div
              onClick={() => {
                if (!isAssignable) return;
                navigation(`/profile/users/editRole/${id}`);
              }}
            >
              {
                <EditSvg
                  className={`w-7 h-6 ${
                    isAssignable
                      ? "text-gray-500 cursor-pointer"
                      : "text-gray-200"
                  } fill-current`}
                />
              }
            </div>
            <div
              onClick={() => {
                if (!isAssignable) return;

                unAssignUser({ id, userId, role, organizationId });
              }}
              className="ml-1.5"
            >
              {
                <DeleteIcon
                  className={`w-7 h-6
                ${
                  isAssignable
                    ? "text-gray-500 cursor-pointer"
                    : "text-gray-200"
                }
                fill-current`}
                />
              }
            </div>
          </div>
        );
      },
    },
  ];

  function onDropdownChange(value: string) {
    if (gridApi) {
      if (value === "BASIC" || value === "ADVERTISER_ADMIN") {
        gridApi.setFilterModel({
          role: {
            filterType: "text",
            type: "equals",
            filter: value,
          },
        });
      } else {
        gridApi.setFilterModel(null);
      }
    }
  }

  async function unAssignUser({
    id,
    userId,
    role,
    organizationId,
  }: {
    id: number;
    userId: string | null;
    role: "ADVERTISER_ADMIN" | "BASIC";
    organizationId: number | null;
  }) {
    if (isUnassignmentProcessing) return;

    const reqData: IUnAssignAdvertiserReq = {
      id,
      userId,
      role,
      advertiserId: selectedAdvertiser,
      organizationId: organizationId,
    };
    await unAssignRole(reqData);
  }

  useEffect(() => {
    if (gridApi && filterStatus === undefined) {
      // Clear the filter when no filter is selected or reset is triggered
      gridApi.setFilterModel(null);
    }
    //eslint-disable-next-line
  }, [filterStatus]);

  useEffect(() => {
    if (gridApi && advertisersUsers) {
      const totalPages = advertisersUsers.length / numberOfItemsInEachPage;
      setTableTotalPages(totalPages);
    }
    // eslint-disable-next-line
  }, [gridApi, advertisersUsers?.length]);

  useEffect(() => {
    if (isUnassignmentDone) {
      toast.success("Successfully unassigned the user!");
    } else if (isUnassignmentFailed) {
      toast.error("Failed to unassign the user!");
    }
  }, [isUnassignmentDone, isUnassignmentFailed]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        newestOnTop={true}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      <div className="flex flex-col">
        <TopContent
          title="User Authorization"
          subTitle="Manage and control access and administrative rights to the account"
        />
        <ContentContainer>
          <div>
            <div className="flex  my-[1px] items-center">
              <div className="py-2 flex items-center ">
                <p className="mx-5 font-interSemiBold text-sm text-primary">
                  Filter by:{" "}
                </p>
                <Selection
                  data={[
                    {
                      label: "all",
                      value: null,
                    },
                    {
                      label: "basic",
                      value: "BASIC",
                    },
                    {
                      label: "admin",
                      value: "ADVERTISER_ADMIN",
                    },
                  ]}
                  isMulti={undefined}
                  closeMenuOnSelect={true}
                  placeholder={"Access Level"}
                  customStyle={{
                    selectContainer: {
                      marginBlock: "-2px",
                      paddingInline: 7,
                      fontSize: 14,
                      fontFamily: "InterRegular",
                      paddingBlock: 0,
                      borderRadius: "7px",
                      width: "15rem",
                      marginRight: ".7rem",
                    },
                    color: "#d3d3d3",
                    optionisSelectedBgColor: "transparent",
                    optionisFocusedBgColor: "#335498",
                    optionisFocusedTextColor: "white",
                    singleValStyles: {
                      backgroundColor: "transparent",
                    },
                    valueContainer: {
                      fontFamily: "InterRegular",
                    },
                  }}
                  onChange={(selectedOption: any, actionMeta: any) => {
                    onDropdownChange(
                      selectedOption ? selectedOption.value : ""
                    );
                    setFilterStatus(
                      selectedOption ? selectedOption.value : undefined
                    );
                  }}
                />
              </div>

              <div className="max-w-fit flex items-center  ml-auto">
                <RoundedButton
                  text={
                    <p
                      onClick={() => {
                        if (usersRoleForAdvertiserData?.role === "BASIC")
                          return;
                        navigation("/profile/users/invite");
                      }}
                      className=" text-sm text-white inline-flex items-center font-interRegular"
                    >
                      Invite User
                    </p>
                  }
                  bgColor={` ${
                    usersRoleForAdvertiserData?.role === "BASIC"
                      ? "bg-gray-200"
                      : "bg-[#3077aa]"
                  } `}
                  borderRadius="rounded-lg"
                  textColor="text-secondary"
                  className=" ml-auto px-8 py-2.5 text-sm font-interSemiBold mr-1"
                />
              </div>
            </div>
            <Table
              gridApi={gridApi}
              tableId="usersGrid"
              tableTotalPages={tableTotalPages}
              // tableTotalPages={1}
              setDataPageNumber={setDataPageNumber}
              tableStyle={{
                minHeight: "200px",
                maxHeight: "1149px",
              }}
              rowHeight={50}
              paginationPageSize={numberOfItemsInEachPage}
              onGridReady={(params) => setGridApi(params.api)}
              columnData={columnData}
              assetRowData={advertisersUsers || []}
            />
          </div>
        </ContentContainer>
      </div>
    </>
  );
}

export default Users;
