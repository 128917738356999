import { useNavigate } from "react-router-dom";

import { useAppStore } from "../../../../../store";
import { useShallow } from "zustand/shallow";

import CampaignConfirmationModal from "../../../campaignConfirmationModal";

import RoundedButton from "../../../../../components/roundedButton";
import { Selection } from "../../../../../components/select";
import Tooltip from "../../../../../components/tooltip";

import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit-icon-mui.svg";

import { IBannerBearImagesResponse } from "../../../../../services/wizard/bannerbear";
import { useCampaignStrategyInfo } from "../../../../../services/campaigns";

import { ICreativeDisplayModeProps } from "../../../../../interfaces";

const CreativeDisplayMode = ({
  formattedSelectionData,
  isBudgetChannelPending,
  adCategoryselection,
  setAdCategorySelection,
  adsFromEach,
  previewedImage,
  setPreviewedImage,
  setModalImage,
  isEditEnabled,
  setIsEditEnabled,
}: ICreativeDisplayModeProps) => {
  const navigate = useNavigate();

  const {
    step,
    setStep,
    campaignId,
    selectedAdvertiser,
    setIsConfirmationModalShown,
    isConfirmationModalShown,
  } = useAppStore(
    useShallow((state) => ({
      step: state.step,
      setStep: state.setStep,
      campaignId: state.campaignId,
      selectedAdvertiser: state.selectedAdvertiser,
      setIsConfirmationModalShown: state.setIsConfirmationModalShown,
      isConfirmationModalShown: state.isConfirmationModalShown,
    }))
  );

  const { data: campaignStrategyData } = useCampaignStrategyInfo(
    campaignId,
    selectedAdvertiser
  );

  async function handleCampaignConfirmationModal(
    campaignStatus: string | undefined
  ) {
    if (campaignStatus === "active") {
      navigate("/campaigns");
      return;
    }

    if (campaignStatus === "draft") {
      setIsConfirmationModalShown(true);
    }
  }

  function handlePreviewedImage(
    id: number,
    imageUrl: string | null,
    templateName: string | null
  ) {
    setPreviewedImage({
      id,
      imageUrl,
      templateName,
    });
  }

  const adCategorySelectionMapping =
    adCategoryselection?.value === "display"
      ? "displayAds"
      : adCategoryselection?.value === "social"
      ? "socialAds"
      : adCategoryselection?.value === "search"
      ? "searchAds"
      : adCategoryselection?.value === "print" && "printAds";

  const allocationBudgetMapping =
    adCategorySelectionMapping === "displayAds"
      ? "displayAllocation"
      : adCategorySelectionMapping === "socialAds"
      ? "socialAllocation"
      : adCategorySelectionMapping === "searchAds"
      ? "searchAllocation"
      : adCategorySelectionMapping === "printAds" && "printAllocation";

  const selectedAllocationValue =
    allocationBudgetMapping &&
    campaignStrategyData?.campaignBudget[allocationBudgetMapping];

  return (
    <>
      {isConfirmationModalShown && <CampaignConfirmationModal />}

      <div>
        {step === 1 && (
          <>
            <div className="flex items-center -mt-2 justify-end">
              {campaignStrategyData?.campaignStatus !== "active" && (
                <div
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  <Tooltip
                    Icon={EditIcon}
                    bodyText="Edit"
                    className="w-9 h-8  text-gray-500 cursor-pointer  fill-current"
                  />
                </div>
              )}
              {}
              <RoundedButton
                onClick={async () =>
                  handleCampaignConfirmationModal(
                    campaignStrategyData?.campaignStatus
                  )
                }
                type="button"
                isEnabled={true}
                className="py-3 px-9 max-w-fit max-h-fit ml-5"
                borderRadius="rounded-md"
                bgColor={`${
                  campaignStrategyData?.campaignStatus !== "active"
                    ? "bg-[#49AC1B]"
                    : "bg-[#3077aa]"
                }`}
                text={
                  <p className=" text-sm text-white inline-flex items-center font-interRegular">
                    {step === 1 &&
                    campaignStrategyData?.campaignStatus !== "active"
                      ? "Continue"
                      : step === 1 &&
                        campaignStrategyData?.campaignStatus === "active" &&
                        "Go Back to Campaigns"}
                  </p>
                }
              />
            </div>
            <hr className=" mt-1.5 mb-3 " />
          </>
        )}

        <p className=" text-primary font-interRegular text-sm leading-6">
          The following creative concepts are designed to showcase some samples
          of your Ads. These are tailored to suit your unique brand identity and
          objectives and are optimized based on our industry experience to
          ensure it resonates with audiences.
        </p>
        <div className="mb-6"></div>
      </div>
      {step !== 1 && <hr className=" mt-1.5 mb-3 " />}

      <div className="flex">
        <div className="w-[200px] flex-col h-[566px] overscroll-y-auto hide-scrollbar  px-2 py-2 flex border border-solid border-[#E5E9EB] rounded-md">
          <div className="w-full mb-2">
            {formattedSelectionData && (
              <Selection
                data={formattedSelectionData ?? []}
                isPending={isBudgetChannelPending}
                value={adCategoryselection}
                closeMenuOnSelect={true}
                isMulti={undefined}
                onChange={(selectedOption: any, actionMeta: any) => {
                  setAdCategorySelection(selectedOption);
                }}
                customStyle={{
                  selectContainer: {
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#40444f",
                    borderRadius: "0.625rem",
                    width: "100%",
                    fontSize: 14,
                    marginTop: "1px",
                  },
                  color: "#d3d3d3",
                  valueContainer: {
                    paddingInline: "0.45rem",
                    paddingBlock: "0.1rem",
                  },
                  optionisSelectedBgColor: "transparent",
                  optionisFocusedBgColor: "#d7edff",
                  optionisFocusedTextColor: "#0e73f6",
                  singleValStyles: {
                    fontSize: 14,
                    backgroundColor: "transparent",
                  },
                  multiValStyles: {
                    backgroundColor: "#d7edff",
                    color: "#0e73f6",
                  },
                  multiValLabelStyles: {
                    color: "#0e73f6",
                  },
                }}
              />
            )}
          </div>

          {adsFromEach &&
            adsFromEach[
              adCategoryselection?.value as keyof IBannerBearImagesResponse
            ]?.length !== 0 && (
              <div className="flex flex-col  h-full overflow-y-auto hide-scrollbar    gap-y-4">
                {adsFromEach[
                  adCategorySelectionMapping as keyof IBannerBearImagesResponse
                ]?.map((ad) => {
                  return (
                    <div
                      onClick={() =>
                        handlePreviewedImage(
                          ad.id,
                          ad.imageUrl,
                          ad.templateName
                        )
                      }
                      className={`${
                        previewedImage?.imageUrl &&
                        previewedImage?.id === ad.id &&
                        "bg-[#D7EDFF]"
                      } cursor-pointer w-full  -mb-4 last-of-type:mb-0 p-2`}
                      key={ad.id}
                    >
                      {ad.imageUrl && (
                        <img
                          className="object-contain mx-auto max-h-[300px]"
                          src={ad.imageUrl}
                          alt={ad.templateType}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
        </div>

        {previewedImage && previewedImage?.id && previewedImage.imageUrl && (
          <div className="flex items-center justify-center mx-auto">
            <div className="">
              {previewedImage.templateName && (
                <h3 className="text-sm text-center mb-3 font-interSemiBold text-primary">
                  {previewedImage.templateName}
                </h3>
              )}
              <img
                onClick={() => setModalImage(previewedImage.imageUrl)}
                className="object-contain cursor-pointer max-h-[25rem] min-w-[350px]"
                src={previewedImage.imageUrl}
                alt="previewed-item"
              />
              <p className="mt-3 text-primary text-xs font-interRegular text-center">
                Click to zoom
              </p>
            </div>
          </div>
        )}

        {selectedAllocationValue === 0 && (
          <div className="max-w-[65%] px-6 mt-2">
            <p className="text-primary  text-sm  font-interSemiBold">
              No creative is needed for this channel since no budget was
              allocated. Adjust your budget if you'd like to see some sample
              creatives for this channel
            </p>
          </div>
        )}

        {!isEditEnabled &&
          step === 4 &&
          campaignStrategyData?.campaignStatus &&
          campaignStrategyData?.campaignStatus !== "active" && (
            <RoundedButton
              type="button"
              onClick={() => {
                if (
                  campaignStrategyData?.campaignStatus &&
                  campaignStrategyData?.campaignStatus !== "active"
                ) {
                  setIsEditEnabled(!isEditEnabled);
                }
              }}
              className="py-2.5 px-4 max-w-fit ml-auto max-h-[2.9rem]"
              borderRadius="rounded-md"
              text={
                <p className=" text-sm text-white inline-flex items-center font-interRegular">
                  <EditIcon className="text-white fill-current mr-2 text-2xl " />
                  EDIT TEXT
                </p>
              }
            />
          )}
      </div>

      {step === 4 && (
        <div className="ml-auto flex ">
          <RoundedButton
            onClick={() => {
              setStep(3);
            }}
            type="button"
            className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto mr-5"
            borderRadius="rounded-md"
            bgColor="#FFFFFF"
            borderColor="#C1C9D2"
            text={
              <p className=" text-sm text-primary inline-flex items-center font-interRegular">
                Previous
              </p>
            }
          />
          <RoundedButton
            onClick={() => {
              setStep(5);
            }}
            type="button"
            isEnabled={true}
            className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto "
            borderRadius="rounded-md"
            text={
              <p className=" text-sm text-white inline-flex items-center font-interRegular">
                Next
              </p>
            }
          />
        </div>
      )}
    </>
  );
};

export default CreativeDisplayMode;
