import MainLayout from "../../layouts/main";

import { useAppStore } from "../../store";

import ContentContainer from "../../components/contentContainer";

const Index = () => {
  const isSidebarOpen = useAppStore((store) => store.isSidebarOpen);

  return (
    <MainLayout showSidebarLogo showSidebar>
      <div className="bg-white px-8 h-[6.375rem]  w-full flex ">
        <div className="w-full flex  max-w-[80rem]  ">
          <div
            className={`
            flex flex-col mt-2  max-w-fit ${!isSidebarOpen && "ml-[4rem]"}
            `}
          >
            <h3 className="text-[2.5rem] font-interBold text-primary">
              Insights
            </h3>
            <h5 className="text-sm text-secondary font-interSemiBold -mt-1.5 text max-w-[680px] truncate overflow-hidden whitespace-nowrap">
              Feature coming soon
            </h5>
          </div>
        </div>
      </div>

      <ContentContainer>
        <div className=" flex flex-1 flex-col p-12">
          {/* <p className="text-sm font-interRegular text-primary line-clamp-3">
            Your advertising insights will appear here! We're working behind the
            scenes to bring you powerful data to give you visibility to
            advertising investment. Stay tuned!
          </p> */}
          <iframe
            src="https://app.thisissqueeze.com/analytics/dashboard/440/shared/61c063b3-92ed2578" // Replace this with the URL you want to embed
            title="Analytics"
            style={{ width: "100%", height: "1400px", border: "none" }}
          />
        </div>
      </ContentContainer>
    </MainLayout>
  );
};

export default Index;
