import MainLayout from "../../layouts/main";

import { useAppStore } from "../../store";

import ContentContainer from "../../components/contentContainer";

const Index = () => {
  const isSidebarOpen = useAppStore((store) => store.isSidebarOpen);

  return (
    <MainLayout showSidebarLogo showSidebar>
      <div className="bg-white px-8 h-[6.375rem]  w-full flex ">
        <div className="w-full flex  max-w-[80rem]  ">
          <div
            className={`
            flex flex-col mt-2  max-w-fit ${!isSidebarOpen && "ml-[4rem]"}
            `}
          >
            <h3 className="text-[2.5rem] font-interBold text-primary">
              Notifications
            </h3>
            <h5 className="text-sm text-secondary font-interSemiBold -mt-1.5 text max-w-[680px] truncate overflow-hidden whitespace-nowrap">
              Feature coming soon
            </h5>
          </div>
        </div>
      </div>

      <ContentContainer>
        <div className=" flex flex-1 flex-col p-12  ">
          <p className="text-sm font-interRegular text-primary line-clamp-3">
            Notifications about your advertising campaigns will appear here!
            Everything you need to know to stay on top of your advertising
            dollars.
          </p>
        </div>
      </ContentContainer>
    </MainLayout>
  );
};

export default Index;
