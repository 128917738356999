import { useCallback, useEffect, useState } from "react";

import { ColDef } from "ag-grid-community";

import Table from "../../../../../components/table";
import Tooltip from "../../../../../components/tooltip";

import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit-icon-mui.svg";

import { ICreativeImageCopyResponse } from "../../../../../services/image-copy";
import { Selection } from "../../../../../components/select";

const CopyList = ({
  creativeCopyData,
  setRequestedCopyData,
  setCreativeEditMode,
}: {
  creativeCopyData: ICreativeImageCopyResponse[];
  setRequestedCopyData: React.Dispatch<
    React.SetStateAction<{
      id: number;
      copy: string;
    } | null>
  >;
  setCreativeEditMode: React.Dispatch<
    React.SetStateAction<"copyList" | "copyEdit">
  >;
}) => {
  const [gridApi, setGridApi] = useState<any>(null);
  const [tableTotalPages, setTableTotalPages] = useState<number>(0);
  const numberOfItemsInEachPage = 20;

  const [filterStatus, setFilterStatus] = useState<string | null>(null);

  const columnData: ColDef[] = [
    {
      headerName: "CHANNEL",
      field: "channel",
      cellClass: "align-start",
      headerClass: "custom-table-header-padding-copy-table",
      sortable: false,
      flex: 1,
      filter: true,
      resizable: false,
    },
    {
      headerName: "TYPE",
      field: "type",
      filter: true,
      headerClass: "ag-header-border",
      sortable: false,
      flex: 1,
      resizable: false,
    },
    {
      headerName: "COPY",
      field: "copy",
      filter: true,
      headerClass: "ag-header-border",
      sortable: false,
      flex: 3,
      resizable: false,
    },
    {
      headerName: "ACTION",
      sortable: false,
      width: 90,
      cellRenderer: (params: any) => {
        return (
          <div className="flex">
            <div
              className="cursor-pointer"
              onClick={() => {
                const { id, copy } = params.data;
                setRequestedCopyData({
                  id,
                  copy,
                });
                setCreativeEditMode("copyEdit");
              }}
            >
              {
                <Tooltip
                  Icon={EditIcon}
                  bodyText="Edit"
                  className="w-6 h-6 text-gray-500 fill-current"
                />
              }
            </div>
          </div>
        );
      },
      resizable: false,
    },
  ];

  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
  }, []);

  function onDropdownChange(value: string | null) {
    if (gridApi) {
      if (value) {
        gridApi.setFilterModel({
          channel: {
            filterType: "text",
            type: "equals",
            filter: value,
          },
        });
      } else {
        gridApi.setFilterModel(null);
      }
    }
  }

  useEffect(() => {
    if (gridApi && creativeCopyData && creativeCopyData?.length > 0) {
      const totalRows = gridApi.paginationGetRowCount();
      const totalPages = Math.ceil(totalRows / numberOfItemsInEachPage);
      setTableTotalPages(totalPages);
    }
    // eslint-disable-next-line
  }, [gridApi, creativeCopyData?.length, filterStatus]);

  return (
    <div className="-mx-7 -mt-7">
      <div className="flex flex-col   ">
        <div className="border-[#e5e9ee] border-b pl-9 py-[0.44rem]">
          <p className="font-interRegular text-sm text-primary">
            The text shown here will be submitted as part of your campaign and
            used across different creative. Not all text will appear in every
            sample ad, as platforms may adjust how content is displayed based on
            format, space, and targeting. If you want to make changes, you can
            adjust each text by clicking on the edit icon.
          </p>
        </div>
        <div className="flex pl-9 items-center py-[0.44rem]">
          <p className=" font-interSemiBold text-sm text-primary mr-2">
            Filter by:{" "}
          </p>
          <Selection
            data={[
              {
                label: "all",
                value: null,
              },
              {
                label: "social",
                value: "social",
              },
              {
                label: "search",
                value: "search",
              },
              {
                label: "display",
                value: "display",
              },
              {
                label: "email",
                value: "paidEmail",
              },
            ]}
            isMulti={undefined}
            closeMenuOnSelect={true}
            placeholder={"Channel"}
            customStyle={{
              selectContainer: {
                marginTop: "-2px",
                paddingInline: 7,
                fontSize: 14,
                fontFamily: "InterRegular",
                paddingBlock: 0,
                borderRadius: "7px",
                width: "15rem",
                marginRight: ".7rem",
              },
              color: "#d3d3d3",
              optionisSelectedBgColor: "transparent",
              optionisFocusedBgColor: "#d7edff",
              optionisFocusedTextColor: "#0e73f6",
              singleValStyles: {
                backgroundColor: "transparent",
              },
              valueContainer: {
                fontFamily: "InterRegular",
              },
            }}
            onChange={(selectedOption: any, actionMeta: any) => {
              const value = selectedOption ? selectedOption.value : null;
              setFilterStatus(value);
              onDropdownChange(value);
            }}
          />
        </div>
      </div>
      <Table
        gridApi={gridApi}
        tableTotalPages={tableTotalPages}
        tableId="creativeCopyGrid"
        tableStyle={{
          minHeight: "200px",
          maxHeight: "1149px",
        }}
        rowHeight={50}
        paginationPageSize={numberOfItemsInEachPage}
        onGridReady={onGridReady}
        columnData={columnData}
        assetRowData={creativeCopyData ?? []}
      />
    </div>
  );
};

export default CopyList;
