import { StateCreator } from "zustand";
import { StoreState } from "..";
import { IBannerBearAdObject } from "../../services/wizard/bannerbear";

export interface ICampaign {
  campaignId: number | undefined;
  setCampaignId: (campaignId: number | undefined) => void;
  campaignTableFilterStatus: string | null;
  setCampaignTableFilterStatus: (val: string | null) => void;
  campaignDeletionModal: boolean;
  setCampaignDeletionModal: (val: boolean) => void;
  campaignIdToDelete: number | undefined;
  setCampaignIdToDelete: (val: number | undefined) => void;
  creativesForPDFArr: IBannerBearAdObject[];
  setCreativesForPDFArr: (val: IBannerBearAdObject[]) => void;
  resetCreativesForPDFArr: () => void;
  isConfirmationModalShown: boolean;
  setIsConfirmationModalShown: (value: boolean) => void;
}

export const createCampaignSlice: StateCreator<
  StoreState,
  [],
  [],
  ICampaign
> = (set, get) => ({
  campaignId: undefined,
  setCampaignId: (campaignId) => {
    set({ campaignId });
  },
  campaignTableFilterStatus: null,
  setCampaignTableFilterStatus: (state) =>
    set({ campaignTableFilterStatus: state }),
  campaignDeletionModal: false,
  setCampaignDeletionModal: (state) => set({ campaignDeletionModal: state }),
  campaignIdToDelete: undefined,
  setCampaignIdToDelete: (state) => set({ campaignIdToDelete: state }),
  creativesForPDFArr: [],
  setCreativesForPDFArr: (arr) => {
    set((state) => ({
      creativesForPDFArr: [...state.creativesForPDFArr, ...arr], // Appending, not overwriting
    }));
  },
  resetCreativesForPDFArr: () => set({ creativesForPDFArr: [] }),
  isConfirmationModalShown: false,
  setIsConfirmationModalShown: (state) =>
    set({ isConfirmationModalShown: state }),
});
