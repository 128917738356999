import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { useForm, SubmitHandler } from "react-hook-form";

import { useAppStore } from "../../store";
import { useShallow } from "zustand/shallow";

import PasswordStrengthItem from "../../containers/profile/changePassword/passwordStrengthItem";

import RoundedButton from "../../components/roundedButton";
import Input from "../../components/input/floatinLabelInput";
import ActivityIndicator from "../../components/activitySpinner";

import SparkLogo from "../../assets/icons/spark-logo-black-text.png";
import WarningIcon from "../../assets/icons/icon_alert-warning.png";

import { SignUpFormData } from "../../interfaces";

import { evaluatePasswordStrength } from "../../utils/passwordStrength";

import { useDomainStyle } from "../../services/multitenacy-config";
import { useCreateSelf } from "../../services/user";

export const SignUpPage: React.FC = () => {
  const { mutate: createNewUser, isSuccess: isUserCreateSuccess } =
    useCreateSelf();

  const {
    signUp,
    isSignUpError,
    signUperrorMessage,
    isSignUpProcessing,
    setFirebaseTokenAsAxiosAuthToken,
    setTokenRefreshed,
    domainName,
  } = useAppStore(
    useShallow((state) => ({
      signUp: state.signUp,
      isSignUpError: state.isErrorAuth,
      signUperrorMessage: state.errorAuthMessage,
      isSignUpProcessing: state.isLoadingAuth,
      setFirebaseTokenAsAxiosAuthToken: state.setFirebaseTokenAsAxiosAuthToken,
      setTokenRefreshed: state.setTokenRefreshed,
      domainName: state.domainName,
    }))
  );

  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [phoneNumberValidMessage, setPhoneNumberValidMessage] = useState<
    string | undefined
  >();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<SignUpFormData>({
    defaultValues: {
      displayName: "",
      password: "",
      phoneNumber: "xxxx",
      email: "",
    },
  });

  const formData: SignUpFormData = watch();

  const isSubmitDisabled =
    !formData.displayName || !formData.password || !formData.email;

  const { data: domainStyleData, isPending } = useDomainStyle(domainName);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const organizationId = domainStyleData?.id ?? null;

  const onSubmit: SubmitHandler<SignUpFormData> = async (data) => {
    if (isSubmitDisabled) {
      return;
    }

    if (Object.values(passwordStrength).includes(false)) {
      setError("password", { message: "Password does not meet requirements!" });
      return;
    }

    let reqData = { ...data, organizationId };

    await signUp(reqData, createNewUser);
  };

  const passwordStrength = useMemo(
    () => evaluatePasswordStrength(formData.password),
    [formData.password]
  );

  useEffect(() => {
    if (phoneNumberValidMessage) {
      setTimeout(() => {
        setPhoneNumberValidMessage(undefined);
      }, 3000);
    }
    //eslint-disable-next-line
  }, [signUperrorMessage, phoneNumberValidMessage]);

  useEffect(() => {
    if (isUserCreateSuccess) {
      setFirebaseTokenAsAxiosAuthToken(true);
      setTokenRefreshed(true);
    } else {
      setTokenRefreshed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserCreateSuccess]);

  if (isPending && !domainStyleData && isImageLoading)
    return <ActivityIndicator />;

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex  mt-0 mb-2 justify-center">
        <div className="border relative border-solid border-gray-200 rounded-lg  pb-4 mt-10  w-[30.5rem]">
          <div className="px-5 pt-3">
            <div className="w-full h-[4.5rem] mb-6">
              <img
                src={domainStyleData?.largeLogoUrl ?? SparkLogo}
                onLoad={() => setIsImageLoading(false)}
                className="w-full h-full object-contain "
                alt="logo.png"
              />
            </div>

            <h3 className=" mt-2 mb-6 font-interSemiBold text-3xl text-[#0e086a] text-center  ">
              Sign Up
            </h3>

            {isSignUpError && (
              <div className="flex bg-[#fef7f7] border border-red-600 px-3 py-4 rounded-md">
                <div className="w-5 flex-shrink-0 h-5 mr-5">
                  <img
                    className="w-full h-full"
                    src={WarningIcon}
                    alt="warning-text"
                  />
                </div>

                <p className="font-ManropeSemiBold text-sm text-black">
                  {signUperrorMessage}
                </p>
              </div>
            )}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-x-3 gap-y-2"
            >
              <Input
                id="displayName"
                name="displayName"
                label="Display Name"
                reactHooksFormEnabled={true}
                register={register}
                requiredMessage="Display Name is required"
                errorMessage={errors?.displayName?.message}
                value={formData["displayName"]}
                focusedField={focusedField}
                setFocusedField={setFocusedField}
              />

              <Input
                id="email"
                name="email"
                label="Email"
                reactHooksFormEnabled={true}
                register={register}
                requiredMessage="Email is required"
                errorMessage={errors?.email?.message}
                value={formData["email"]}
                focusedField={focusedField}
                setFocusedField={setFocusedField}
              />
              <Input
                id="password"
                name="password"
                type="password"
                label="Password"
                reactHooksFormEnabled={true}
                requiredMessage="Password is required"
                register={register}
                value={formData["password"]}
                errorMessage={errors?.password?.message}
                focusedField={focusedField}
                setFocusedField={setFocusedField}
              />

              <div className="flex flex-row space-x-4 mb-6">
                <div className="flex flex-col">
                  <PasswordStrengthItem
                    fontSize="11px"
                    isChecked={passwordStrength.lowercase}
                    message="One lowercase character"
                  />
                  <PasswordStrengthItem
                    isChecked={passwordStrength.uppercase}
                    message="One uppercase character"
                  />
                  <PasswordStrengthItem
                    isChecked={passwordStrength.number}
                    message="One number"
                  />
                </div>

                <div className="flex flex-col space-y-2">
                  <PasswordStrengthItem
                    isChecked={passwordStrength.specialChar}
                    message="One special character"
                  />
                  <PasswordStrengthItem
                    isChecked={passwordStrength.length}
                    message="Minimum 8 characters"
                  />
                </div>
              </div>

              {/* <div className="flex flex-col">
                <div className=" flex space-x-1">
                  <div className="flex pt-5  flex-[0.25]">
                    <CountryCodePicker
                      onChange={handleCountryCodeChange}
                      value={selectedCountry}
                      customSingleValueStyles={{ height: "48px" }}
                      customDropdownMenuStyles={{ width: "250%" }}
                    />
                  </div>
                  <div className="flex flex-[0.75] ">
                    <Input
                      containerDivClassName="w-full"
                      id="phoneNumber"
                      name="phoneNumber"
                      type="tel"
                      label="Phone Number"
                      reactHooksFormEnabled={true}
                      register={register}
                      errorMessage={errors?.email?.message}
                      requiredMessage="Phone Number is required"
                      value={formData["phoneNumber"]}
                      focusedField={focusedField}
                      setFocusedField={setFocusedField}
                    />
                  </div>
                </div>
                {phoneNumberValidMessage && (
                  <div className="mt-2 -mb-2">
                    <p className="text-red-400 text-sm">
                      {phoneNumberValidMessage}
                    </p>
                  </div>
                )}
              </div> */}
              <RoundedButton
                type="submit"
                text={(isSignUpProcessing && "Processing...") || "Next"}
                isEnabled={!isSubmitDisabled}
                className="py-3 px-4 mt-5"
              />

              {/* {isSignUpError && (
              <div className="my-2">
                <p className="text-red-400 text-sm">
                  {signUperrorMessage ?? "An error occurred while signing up"}
                </p>
              </div>
            )} */}
              <p className="text-[#5e88b6] font-interRegular text-sm mt-4">
                By signing up, you agree to our{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                  href="https://docs.google.com/document/d/1dl3TCCCBRDBdq-TBQcIU6Ez-EYK85ChObPCszIq1rHY/edit"
                >
                  Terms of Service and Privacy Policy
                </a>
              </p>
            </form>
          </div>

          <div className="absolute -right-24 top-0">
            <Link
              to="/"
              className="text-[#45678b] cursor-pointer font-ManropeBold text-right"
            >
              Log In
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="flex mt-auto bg-gray-50 w-full items-center justify-center py-3.5  ">
        <p className=" text-center text-sm text-primary underline">
          <a
            rel="noreferrer"
            className="cursor-pointer"
            target="_blank"
            href="https://docs.google.com/document/d/1dl3TCCCBRDBdq-TBQcIU6Ez-EYK85ChObPCszIq1rHY/edit"
          >
            Legal
          </a>
        </p>
      </div> */}
    </div>
  );
};

export default SignUpPage;
