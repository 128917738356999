import { View, Text, StyleSheet } from "@react-pdf/renderer";

export default function EstimationPDFTable({
  campaignViewEstimation,
  campaignVisitEstimation,
}: {
  campaignViewEstimation: any;
  campaignVisitEstimation: any;
}) {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.headerText}>
          Estimated Potential Customers (
          <Text style={styles.headerLow}>Low</Text>
          <Text style={styles.separator}> - </Text>
          <Text style={styles.headerHigh}>High</Text>)
        </Text>
      </View>

      <View style={styles.row}>
        <View style={styles.cellLeft}>
          <Text style={styles.cellText}>Visiting your website</Text>
        </View>
        <View style={styles.cellRight}>
          <Text>
            <Text style={styles.lowText}>{campaignVisitEstimation.low}</Text>
            <Text style={styles.separator}> - </Text>
            <Text style={styles.highText}>{campaignVisitEstimation.high}</Text>
          </Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.cellLeft}>
          <Text style={styles.cellText}>Viewing your Ads</Text>
        </View>
        <View style={styles.cellRight}>
          <Text>
            <Text style={styles.lowText}>{campaignViewEstimation.low}</Text>
            <Text style={styles.separator}> - </Text>
            <Text style={styles.highText}>{campaignViewEstimation.high}</Text>
          </Text>
        </View>
      </View>

      <View style={styles.footer}>
        <Text style={styles.footerText}>
          These estimates are based on industry averages and may vary due to
          factors like seasonality, competition, and location.
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: "#E5E9EB",
    borderRadius: 4,
    width: "100%",
  },
  header: {
    borderBottomWidth: 1,
    borderBottomColor: "#E5E9EB",
    padding: 6,
    paddingRight: 1,
  },
  headerText: {
    fontSize: 7,
    color: "#40444f",
  },
  headerLow: {
    fontSize: 7,
    color: "#d34638",
  },
  headerHigh: {
    fontSize: 7,
    color: "#60aa1b",
  },
  lowText: {
    fontSize: 8,
    color: "#d34638",
  },
  highText: {
    fontSize: 8,
    color: "#60aa1b",
  },
  separator: {
    fontSize: 8,
    marginHorizontal: 4,
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#E5E9EB",
  },
  cellLeft: {
    width: "50%",
    padding: 6,
    borderRightWidth: 1,
    borderRightColor: "#E5E9EB",
  },
  cellRight: {
    width: "50%",
    padding: 3,
    alignItems: "center",
    textAlign: "center",
  },
  cellText: {
    fontSize: 7,
  },
  footer: {
    padding: 6,
  },
  footerText: {
    fontSize: 7,
    color: "#40444f",
  },
});
