import {
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";

// import { useSelectedAdvertiser } from "../../../../contexts/selectedAdvertiser";
import { useAppStore } from "../../../../store";

import { renderCampaignFieldsComponents } from "./renderCampaignFieldsComponents";
import { categorizeFields } from "./categorizeFields";

import Tooltip from "../../../../components/tooltip";

import { ReactComponent as TooltipIcon } from "../../../../assets/icons/tooltip-icon-mui.svg";

import { ICampaignFieldsResponse } from "../../../../services/industry-organization-campaign-fields";
import { useUserIndustry } from "../../../../services/industry";
import { useAdvertiser } from "../../../../services/advertiser";

import { CampaignFieldData } from "../../../../interfaces";

import {
  categoryOrder,
  categoryTitle,
  categoryTooltip,
  // categoryTooltipTexts,
} from "../../../../constants/wizard/campaign";

const CampaignFieldsRenderer = ({
  multiSelectFieldIds,
  isEditEnabled,
  campaignFields,
  campaignFieldVals,
  setCampaignFieldVals,
  control,
  setValue,
  errors,
  trigger,
  getValues,
}: {
  multiSelectFieldIds: {
    id: number;
    campaignFieldName: string;
    campaignFieldId: number;
  }[];
  isEditEnabled: boolean;
  campaignFieldVals: CampaignFieldData;
  setCampaignFieldVals: React.Dispatch<React.SetStateAction<CampaignFieldData>>;
  campaignFields: ICampaignFieldsResponse[] | undefined;
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  errors: Record<string, any>;
  trigger: UseFormTrigger<CampaignFieldData>;
  getValues: UseFormGetValues<FieldValues>;
}) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const categorizedFields =
    campaignFields && categorizeFields([...campaignFields]);

  const selectedAdvertiser = useAppStore((store) => store.selectedAdvertiser);

  const { data: advertiserData } = useAdvertiser(selectedAdvertiser);
  const { data: advertiserIndustryData } = useUserIndustry(
    advertiserData?.industryId,
    advertiserData?.id
  );

  const advertisersIndustry = advertiserIndustryData?.name;

  return (
    <>
      {categorizedFields &&
        Object.keys(categorizedFields).map((category) => (
          <div
            key={categoryOrder[category]}
            className=""
            style={{ order: categoryOrder[category] }}
          >
            <div className="flex items-center w-full  mb-1.5 ">
              <h2 className="text-sm text-primary uppercase font-interSemiBold mr-1.5 ">
                {categoryTitle[category]}
              </h2>
              <Tooltip
                Icon={TooltipIcon}
                bodyText={categoryTooltip[category]}
                className="cursor-pointer w-4 h-4 text-gray-500 fill-current"
              />
            </div>
            <div
              key={category}
              className="mb-8  last-of-type:mb-0  flex flex-col"
            >
              {categorizedFields[category]?.map((field) => (
                <div
                  key={field.id}
                  className={`flex ${
                    field.campaignFieldName === "Address" && "-order-1"
                  }  flex-col  items-start mb-6`}
                >
                  <div>
                    <p className="font-interRegular text-sm text-secondary mb-0 w-[170px]">
                      {field.campaignFieldName}
                    </p>
                  </div>
                  {renderCampaignFieldsComponents({
                    isEditEnabled,
                    field,
                    campaignFieldVals,
                    control,
                    errors,
                    campaignFields,
                    multiSelectFieldIds,
                    setCampaignFieldVals,
                    setValue,
                    trigger,
                    getValues,
                    advertisersIndustry,
                  })}
                </div>
              ))}
            </div>
          </div>
        ))}
    </>
  );
};

export default CampaignFieldsRenderer;
