import { useEffect } from "react";

import { useAppStore } from "../store";

function useInitializeDomain() {
  const initializeDomainName = useAppStore.getState().initializeDomainName;

  useEffect(() => {
    initializeDomainName();
    // eslint-disable-next-line
  }, []);

  return null;
}

export default useInitializeDomain;
