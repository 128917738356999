import React, { useEffect, useRef, useState } from "react";

import UserMessage from "./userMessage";
import BotMessage from "./botMessage";

import { useAppStore } from "../../store";
import { useShallow } from "zustand/shallow";

import {
  IChatBotAssistantRequestData,
  IChatMessage,
  useSendChatBotMessage,
  useSessionChatMessages,
} from "../../services/chatbot";

import { getCookie } from "../../utils/cookies";

function Chatbot() {
  const chatbotSessionId = getCookie("chatSessionId");
  const el = useRef<HTMLDivElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);

  const [text, setText] = useState("");

  const {
    messages,
    setMessages,
    isToggled,
    handleToggle,
    sessionToken,
    selectedAdvertiser,
  } = useAppStore(
    useShallow((state) => ({
      messages: state.messages,
      setMessages: state.setMessages,
      isToggled: state.isToggled,
      handleToggle: state.handleToggle,
      sessionToken: state.sessionToken,
      selectedAdvertiser: state.selectedAdvertiser,
    }))
  );

  const hasPendingMessage = messages?.find(
    (message) => message.message === "pending"
  );

  const {
    mutateAsync: sendMessage,
    isPending: isMessageSentPending,
    isError: isMessageSentFailed,
    isSuccess: isMessageSentSuccess,
  } = useSendChatBotMessage(chatbotSessionId);

  const {
    data: chatBotMessages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useSessionChatMessages({
    pageParam: 0,
    limit: 10,
    sessionId: chatbotSessionId,
    signInToken: sessionToken,
  });

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setText(e.target.value);
  }

  async function handleSend(e: React.FormEvent) {
    e.preventDefault();

    if (isMessageSentPending) return;

    const newUserMessage = {
      id: 6,
      chatId: 21212,
      isRead: true,
      sender: "user",
      updatedAt: "21:221:12",
      createdAt: "21:221:12",
      message: text,
      userId: "12",
    } as IChatMessage;

    const reqData: IChatBotAssistantRequestData = {
      advertiserId: selectedAdvertiser,
      message: text,
      sessionId: chatbotSessionId,
    };
    // setMessages((prev) => {
    //   return [...prev, newUserMessage];
    // });
    setMessages([...messages, newUserMessage]);
    setText("");
    await sendMessage(reqData);
  }

  useEffect(() => {
    if (el.current && !isToggled) {
      el.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, [
    isToggled,
    isMessageSentPending,
    isMessageSentSuccess,
    hasPendingMessage,
  ]);

  useEffect(() => {
    if (chatBotMessages?.pages) {
      const allMessages = chatBotMessages.pages
        .flatMap((page) => page.data)
        .reverse();

      setMessages(allMessages);
    }

    chatRef.current?.scrollTo({
      top: 70,
      behavior: "smooth",
    });
    //eslint-disable-next-line
  }, [chatBotMessages?.pages]);

  useEffect(() => {
    const chatContainer = chatRef.current;

    function handleScroll() {
      if (
        chatContainer?.scrollTop === 0 &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    }

    chatContainer?.addEventListener("scroll", handleScroll);
    return () => chatContainer?.removeEventListener("scroll", handleScroll);
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (isMessageSentPending) {
      const newAssitantMessage = {
        id: 10,
        chatId: 21212,
        isRead: true,
        sender: "assistant",
        updatedAt: "21:221:12",
        createdAt: "21:221:12",
        message: "pending",
        userId: "12",
      } as IChatMessage;
      // setMessages((prev) => {
      //   return [...prev, newAssitantMessage];
      // });
      setMessages([...messages, newAssitantMessage]);
    }
    //eslint-disable-next-line
  }, [isMessageSentPending]);

  useEffect(() => {
    if (isMessageSentFailed) {
      const assitantFailMessage = {
        id: 4,
        chatId: 21212,
        isRead: true,
        sender: "assistant",
        updatedAt: "21:221:12",
        createdAt: "21:221:12",
        message: "Something went wrong this time!",
        userId: "12",
      } as IChatMessage;
      // setMessages((prev) => {
      //   return [...prev, assitantFailMessage];
      // });
      setMessages([...messages, assitantFailMessage]);
    }
    //eslint-disable-next-line
  }, [isMessageSentFailed]);

  return (
    <>
      <div
        className={`fixed ${
          isToggled ? "h-0" : "h-[26.2rem]"
        } z-[100] right-0 bottom-0`}
      >
        <div className="flex flex-col overflow-hidden w-[25rem] text-sm font-interRegular rounded-[.625rem] shadow-md bg-[#f5f8fb] text-center">
          <div className="p-2.5 cursor-pointer relative text-center text-white text-base font-interBold bg-[#002f50]">
            <p>&nbsp;Your Marketing Assistant</p>
            <div
              onClick={handleToggle}
              className="absolute top-2 right-3 cursor-pointer text-xl rounded-full "
            >
              {!isToggled && "X"}
            </div>
          </div>
          <div className="flex flex-col overflow-hidden transition-all duration-300">
            <div
              ref={chatRef}
              className="h-[20rem] flex flex-col overflow-auto py-2.5 px-0 w-full"
            >
              {messages &&
                messages.map((message) => {
                  if (message.sender === "user") {
                    return (
                      <UserMessage key={message.id} text={message.message} />
                    );
                  }
                  return <BotMessage key={message.id} text={message.message} />;
                })}
              <div id={"el"} ref={el} />
            </div>
            <div className="chatbot-input">
              <form onSubmit={handleSend}>
                <input
                  type="text"
                  onChange={handleInputChange}
                  value={text}
                  placeholder="Enter your message here"
                />
                <button>
                  <svg
                    className={` fill-current ${
                      text === "" || isMessageSentPending
                        ? "opacity-10"
                        : "opacity-100"
                    }`}
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 500 500"
                  >
                    <g>
                      <g>
                        <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
                      </g>
                    </g>
                  </svg>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chatbot;

/*
  useEffect(() => {
    const chatContainer = chatRef.current;

    if (!chatContainer || !isFetchingNextPage) return;

    const newScrollHeight = chatContainer.scrollHeight;

    // Adjust scrollTop to preserve user's position
    if (previousScrollHeight) {
      chatContainer.scrollTop = newScrollHeight - previousScrollHeight;
    }
  }, [messages, isFetchingNextPage, previousScrollHeight]);
  */
