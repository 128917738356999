import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ColDef } from "ag-grid-community";

import { Bounce, ToastContainer, toast } from "react-toastify";

import { useAppStore } from "../../store";
import { useShallow } from "zustand/shallow";

import Modal from "../../components/modal";
import ActivitySpinnerNoBg from "../../components/activitySpinner/withNoBgEffect";
import TopContent from "../../components/topContent";
import ContentContainer from "../../components/contentContainer";
import RoundedButton from "../../components/roundedButton";
import Table from "../../components/table";
import TableImageModal from "../../components/modal/tableImageModal";
import Tooltip from "../../components/tooltip";

import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon-mui.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-icon-mui.svg";
// import { ReactComponent as GoogleBusinessIcon } from "../../assets/icons/google-business-mui.svg";

import {
  useAllAdvertisers,
  useRemoveAdvertiser,
} from "../../services/advertiser";
import { useAllCampaigns } from "../../services/campaigns";
import { useDomainStyle } from "../../services/multitenacy-config";

function Index() {
  const navigate = useNavigate();

  const { selectedAdvertiser, setSelectedAdvertiser, domainName } = useAppStore(
    useShallow((state) => ({
      selectedAdvertiser: state.selectedAdvertiser,
      setSelectedAdvertiser: state.setSelectedAdvertiser,
      domainName: state.domainName,
    }))
  );

  const { data: domainStyleData } = useDomainStyle(domainName);

  const [modalImage, setModalImage] = useState<string | null>(null);
  const [deletionModal, setDeletionModal] = useState<boolean>(false);
  const [itemIdToDelete, setItemIdToDelete] = useState<number | null>(null);

  const {
    data: advertiserCampaigns,
    isFetching: isAdvertiserCampaignsFetching,
  } = useAllCampaigns(itemIdToDelete, { limit: 1, page: 0 });

  const [gridApi, setGridApi] = useState<any>(null);
  const [tableTotalPages, setTableTotalPages] = useState<number>(0);
  const [, setDataPageNumber] = useState<number>(0);
  const numberOfItemsInEachPage = 10;

  const columnData: ColDef[] = [
    {
      headerName: "",
      field: "photoUrl",
      cellRenderer: (params: any) => (
        <img
          className="my-1 cursor-pointer h-[3rem] object-contain"
          src={params.value}
          alt="row.png"
          onClick={() => setModalImage(params.value)}
        />
      ),
      width: 110,
      sortable: false,
      suppressSpanHeaderHeight: true,
      resizable: false,
    },
    {
      headerName: "NAME",
      field: "displayName",
      headerClass: "ag-header-border",
      sortable: false,
      cellStyle: { textTransform: "inherit" },
      flex: 1,
      resizable: false,
    },
    {
      headerName: "EMAIL",
      field: "email",
      headerClass: "ag-header-border",
      sortable: false,
      flex: 1,
      resizable: false,
    },
    {
      headerName: "PHONE NUMBER",
      field: "phoneNumber",
      headerClass: "ag-header-border",
      sortable: false,
      flex: 1,
      resizable: false,
    },
    {
      headerName: "ACTION",
      sortable: false,
      width: 120,
      resizable: false,
      cellRenderer: (params: any) => {
        const { id } = params.data;
        return (
          <div className="flex">
            <div className="cursor-pointer" onClick={() => handleEdit(id)}>
              {
                <Tooltip
                  Icon={EditIcon}
                  bodyText="Edit"
                  className="w-7 h-6 text-gray-500 fill-current"
                />
              }
            </div>
            {/* <div
              className="cursor-pointer ml-1.5"
              onClick={() => handleBusinessProfile(id)}
            >
              {
                <Tooltip
                  Icon={GoogleBusinessIcon}
                  bodyText="Insights"
                  className="w-7 h-6 text-gray-500 fill-current"
                />
              }
            </div> */}
            <div
              onClick={() => handleDeletionModal(id)}
              className="ml-1.5 cursor-pointer"
            >
              {
                <Tooltip
                  Icon={DeleteIcon}
                  bodyText="Delete"
                  className="w-7 h-6 text-gray-500 fill-current"
                />
              }
            </div>
          </div>
        );
      },
    },
  ];

  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
  }, []);

  const {
    data: allAdvertisers,
    isPending: isAdvertisersPending,
    isFetching: isAdvertisersFetching,
  } = useAllAdvertisers(domainStyleData?.id ?? null);

  const {
    mutateAsync: deleteAdvertiser,
    isSuccess: isDeletedSuccess,
    isError: isDeletedError,
  } = useRemoveAdvertiser();

  async function handleEdit(id: number) {
    navigate(`/advertiser/${id}`);
  }

  // function handleBusinessProfile(id: number) {
  //   navigate(`/advertiser/businessProfile/${id}`);
  // }

  async function handleDelete(id: number) {
    await deleteAdvertiser(id);
    if (
      id === selectedAdvertiser &&
      allAdvertisers &&
      allAdvertisers.length > 1
    ) {
      const newAdvertiserData = allAdvertisers.filter(
        (advertiser) => advertiser.id !== id
      );
      setSelectedAdvertiser(newAdvertiserData[0].id);
    } else if (
      id === selectedAdvertiser &&
      allAdvertisers &&
      allAdvertisers.length === 1
    ) {
      localStorage.removeItem("advertiser");
      setSelectedAdvertiser(null);
    }
    setDeletionModal(false);
  }

  function handleDeletionModal(id: number) {
    setItemIdToDelete(id);
    setDeletionModal(true);
  }

  useEffect(() => {
    if (isDeletedError) {
      toast.error("Failed to delete the advertiser");
    } else if (isDeletedSuccess) {
      if (allAdvertisers?.length === 1) {
        setSelectedAdvertiser(null);
      }
      toast.success("The advertiser deleted successfully!");
    }
    // eslint-disable-next-line
  }, [isDeletedError, isDeletedSuccess]);

  useEffect(() => {
    if (gridApi && allAdvertisers && allAdvertisers?.length > 0) {
      // const totalPages = allAdvertisers.pagination.totalPages;
      const totalPages = Math.ceil(
        allAdvertisers?.length / numberOfItemsInEachPage
      );
      setTableTotalPages(totalPages);
    }
    // eslint-disable-next-line
  }, [gridApi, allAdvertisers?.length]);

  useEffect(() => {
    if (isAdvertisersFetching && gridApi) {
      gridApi.showLoadingOverlay();
    } else if (gridApi) {
      gridApi.hideOverlay();
    }
  }, [isAdvertisersFetching, gridApi]);

  return (
    <div className="flex flex-col">
      <ToastContainer
        position="top-right"
        autoClose={4000}
        newestOnTop={true}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      {deletionModal && (
        <Modal
          setIsModalOpen={setDeletionModal}
          modalBodyContent={
            isAdvertiserCampaignsFetching ? (
              <div className="py-14 px-56">
                <ActivitySpinnerNoBg />
              </div>
            ) : (
              <div className="flex flex-col p-1 max-w-xl">
                <p className="font-interRegular text-primary text-base">
                  {advertiserCampaigns && advertiserCampaigns.data?.length > 0
                    ? "You have existing campaigns. Are you sure you want to delete this advertiser ?"
                    : "Are you sure you want to delete this advertiser ?"}
                </p>
                <div className="flex mt-5 justify-center">
                  <RoundedButton
                    text="yes"
                    className="py-2 px-6 ManropeRegular mr-2"
                    onClick={() => {
                      if (itemIdToDelete !== null) {
                        handleDelete(itemIdToDelete);
                        setItemIdToDelete(null);
                      }
                    }}
                  />
                  <RoundedButton
                    text="no"
                    className="py-2 px-6 font-ManropeRegular bg-gray-300"
                    onClick={() => setDeletionModal(false)}
                  />
                </div>
              </div>
            )
          }
        />
      )}

      <TopContent
        title="Manage Advertisers"
        subTitle="Manage your advertisers"
      />

      {isAdvertisersPending ? (
        <ActivitySpinnerNoBg />
      ) : (
        <ContentContainer>
          {modalImage && (
            <TableImageModal
              isOpen={modalImage}
              src={modalImage}
              onClose={() => setModalImage(null)}
            />
          )}
          <div>
            <div className="flex  my-[1px] items-center ">
              <div className="max-w-fit flex items-center  ml-auto">
                <RoundedButton
                  onClick={() => navigate(`/advertiser/addAdvertiser`)}
                  // onClick={() => setIsModalOpen(true)}
                  text={
                    <p className=" text-sm text-white inline-flex items-center font-interRegular">
                      <span className="text-2xl   mr-2 text-white">+</span>
                      Add Advertiser
                    </p>
                  }
                  bgColor="bg-[#3077aa]"
                  borderRadius="rounded-lg"
                  textColor="text-secondary"
                  className=" ml-auto px-6 py-1 text-sm font-interSemiBold my-1 mr-1"
                />
              </div>
            </div>

            <Table
              gridApi={gridApi}
              tableTotalPages={tableTotalPages}
              setDataPageNumber={setDataPageNumber}
              tableId="assetsGrid"
              tableStyle={{
                minHeight: "200px",
                maxHeight: "1149px",
              }}
              rowHeight={70}
              paginationPageSize={numberOfItemsInEachPage}
              onGridReady={onGridReady}
              columnData={columnData}
              assetRowData={allAdvertisers ?? []}
            />
          </div>
        </ContentContainer>
      )}
    </div>
  );
}

export default Index;
