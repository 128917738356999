import { useMutation, useQuery } from "@tanstack/react-query";

import { request } from "../axios";
import { queryClient } from "../../App";

export type TCampaignChangeRequestData = {
  campaignId: number | undefined;
  changeRequest: string;
};

export type TUpdateCampaignNoteRequestDate = {
  changeRequest: string;
};

export interface ICampaignNoteResponse {
  id: number;
  campaignId: number;
  changeRequest: string;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
}

function requestCampaignChange(data: TCampaignChangeRequestData) {
  return request({
    method: "POST",
    url: "campaign-change-request",
    data,
  });
}

export const useRequestCampaignChange = (campaignId: number | undefined) => {
  return useMutation({
    mutationFn: requestCampaignChange,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["campaignNote", campaignId],
      });
    },
  });
};

function getCampaignNote(
  campaignId: number | undefined
): Promise<ICampaignNoteResponse> {
  return request({
    method: "GET",
    url: `campaign-change-request/${campaignId}`,
  });
}

export const useCampaignNote = (campaignId: number | undefined) => {
  return useQuery({
    queryKey: ["campaignNote", campaignId],
    queryFn: () => getCampaignNote(campaignId),
    enabled: !!campaignId,
  });
};

function updateCampaignChange({
  id,
  changeRequest,
}: {
  id: number;
  changeRequest: string;
}) {
  return request({
    method: "PATCH",
    url: `campaign-change-request/${id}`,
    data: {
      changeRequest,
    },
  });
}

export const useUpdateCampaignNote = (campaignId: number | undefined) => {
  return useMutation({
    mutationFn: updateCampaignChange,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["campaignNote", campaignId],
      });
    },
  });
};
