import { createRef, useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Bounce, toast, ToastContainer } from "react-toastify";

import { useAppStore } from "../../../../store";
import { useShallow } from "zustand/shallow";

import InputWithToggle from "../../../../components/input/inputWithToggle";
import RoundedButton from "../../../../components/roundedButton";
import ActivityIndicator from "../../../../components/activitySpinner";
import Tooltip from "../../../../components/tooltip";

import CalendarIcon from "../../../../assets/icons/calendar.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon-mui.svg";
import { ReactComponent as RefreshIcon } from "../../../../assets/icons/refresh-mui-icon.svg";

import {
  ICampaignStrategyInfoRequest,
  ICampaignStrategyResponse,
  useUpdateCampaignStrategy,
} from "../../../../services/campaigns";
import { useSuggestedAllocations } from "../../../../services/wizard/budget";
import { useAdvertiser } from "../../../../services/advertiser";
import { useUpdateThemeSuggestion } from "../../../../services/campaign-theme-suggestions";
import { IBudgetOrganizationChannelsResponse } from "../../../../services/advertiser/organization-budget-channel";

import { extractMonth } from "../../../../utils/extractMonth";

import { BUDGET_CHANNEL_MAPPING_EDIT_MODE } from "../../../../constants/wizard/campaign";

type AllocationKeys =
  | "paidEmailAllocation"
  | "printAllocation"
  | "searchAllocation"
  | "socialAllocation"
  | "displayAllocation";

type TSuggestedAllocationProps = {
  searchAllocation?: number;
  socialAllocation?: number;
  displayAllocation?: number;
  printAllocation?: number;
  paidEmailAllocation?: number;
};

const allocationMapping: Record<
  "paidEmail" | "print",
  keyof TSuggestedAllocationProps
> = {
  paidEmail: "paidEmailAllocation",
  print: "printAllocation",
};

const CampaignStrategyEditMode = ({
  campaignStrategyData,
  setIsEditEnabled,
  organizationBudgetChannels,
}: {
  campaignStrategyData: ICampaignStrategyResponse | undefined;
  setIsEditEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  organizationBudgetChannels:
    | []
    | IBudgetOrganizationChannelsResponse[]
    | undefined;
}) => {
  const { selectedAdvertiser, campaignId } = useAppStore(
    useShallow((state) => ({
      selectedAdvertiser: state.selectedAdvertiser,
      campaignId: state.campaignId,
    }))
  );

  const {
    isSuccess: isStrategyUpdateSuccess,
    isPending: isStrategyUpdatePending,
    isError: isStrategyUpdateFailed,
    mutateAsync: updateStrategyInfo,
  } = useUpdateCampaignStrategy(selectedAdvertiser, campaignId);

  const {
    mutateAsync: getSuggestedAllocations,
    isSuccess: isSuggestedAllocationsUpdated,
  } = useSuggestedAllocations();
  const { mutateAsync: getSuggestedTheme } = useUpdateThemeSuggestion();

  const { data: advertiserData } = useAdvertiser(selectedAdvertiser);

  const channels =
    campaignStrategyData &&
    organizationBudgetChannels?.map((channel) => {
      const channelKey =
        BUDGET_CHANNEL_MAPPING_EDIT_MODE[channel.budgetChannel].key;
      const value = campaignStrategyData.campaignBudget[channelKey] as number;
      const title = channel.channelName;

      return {
        value,
        title,
        channelKey,
      };
    });

  const defaultAllocationValues:
    | { [key in AllocationKeys]: number }
    | undefined = channels?.reduce((acc, channel) => {
    acc[channel.channelKey] = channel.value;
    return acc;
  }, {} as { [key in AllocationKeys]: number });

  const {
    register,
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm<{
    searchAllocation: number;
    displayAllocation: number;
    socialAllocation: number;
    paidEmailAllocation: number;
    printAllocation: number;
    campaignName: string;
    budget: number;
    goal: { value: any; label: any };
    startDate: string | null;
    endDate: string | null;
    theme: string;
  }>({
    defaultValues: {
      ...(defaultAllocationValues ?? {}),
      campaignName: campaignStrategyData?.campaignName,
      budget: campaignStrategyData?.campaignBudget?.budget,
      goal: {
        value: campaignStrategyData?.campaignGoal.value,
        label: campaignStrategyData?.campaignGoal.value,
      },
      startDate: campaignStrategyData?.campaignStartDate?.value,
      endDate: campaignStrategyData?.campaignEndDate?.value,
      theme: campaignStrategyData?.campaignTheme?.value,
    },
  });

  const [suggestedAllocations, setSuggestedAllocations] =
    useState<TSuggestedAllocationProps>(defaultAllocationValues || {});

  const formValues = watch();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [datePickerRefs, setDatePickerRefs] = useState<{
    [key: string]: React.RefObject<DatePicker>;
  }>({});

  let updatedSuggestedValsMessage = "Allocation suggestions updated";

  function getDatePickerRef(fieldName: string) {
    if (datePickerRefs && !datePickerRefs[fieldName]) {
      datePickerRefs[fieldName] = createRef<DatePicker>();
      setDatePickerRefs({ ...datePickerRefs });
    }
    return datePickerRefs[fieldName];
  }

  function handleIconClick(fieldName: string) {
    if (datePickerRefs[fieldName]?.current) {
      datePickerRefs[fieldName].current?.setOpen(true);
    }
  }

  async function onSubmit() {
    const totalAllocation =
      (formValues.searchAllocation ?? 0) +
      (formValues.displayAllocation ?? 0) +
      (formValues.printAllocation ?? 0) +
      (formValues.socialAllocation ?? 0) +
      (formValues.paidEmailAllocation ?? 0);

    let errorField:
      | "searchAllocation"
      | "displayAllocation"
      | "socialAllocation"
      | "printAllocation"
      | "paidEmailAllocation"
      | undefined = undefined;

    if (totalAllocation !== 100) {
      const fields = [
        "paidEmailAllocation",
        "displayAllocation",
        "printAllocation",
        "searchAllocation",
        "socialAllocation",
      ] as const;

      errorField = fields.find((field) => formValues[field] !== undefined);

      if (errorField) {
        setError(errorField, {
          type: "manual",
          message: "Allocation total percentages must equal 100",
        });
      }
      return;
    } else {
      clearErrors(errorField);
    }

    if (campaignStrategyData && formValues.startDate && formValues.endDate) {
      const reqData: ICampaignStrategyInfoRequest = {
        campaignId: campaignStrategyData?.campaignId,
        campaignName: formValues.campaignName,
        campaignBudget: {
          id: campaignStrategyData.campaignBudget.id,
          budget: formValues.budget,
          searchAllocation: formValues.searchAllocation ?? 0,
          printAllocation: formValues.printAllocation ?? 0,
          socialAllocation: formValues.socialAllocation ?? 0,
          displayAllocation: formValues.displayAllocation ?? 0,
          paidEmailAllocation: formValues.paidEmailAllocation ?? 0,
        },
        campaignGoal: {
          id: campaignStrategyData.campaignGoal.id,
          value: formValues.goal.value,
        },
        campaignStartDate: {
          id: campaignStrategyData.campaignStartDate.id,
          value: formValues.startDate,
        },
        campaignEndDate: {
          id: campaignStrategyData.campaignEndDate.id,
          value: formValues.endDate,
        },
        campaignTheme: {
          id: campaignStrategyData.campaignTheme.id,
          value: formValues.theme,
        },
        campaignVisitEstimation: {
          ...campaignStrategyData.campaignVisitEstimation,
        },
        campaignImpressionEstimation: {
          ...campaignStrategyData.campaignImpressionEstimation,
        },
      };
      await updateStrategyInfo({ data: reqData });
    }
  }

  useEffect(() => {
    if (isStrategyUpdateSuccess) {
      toast.success("Successfully updated fields!");
      setTimeout(() => {
        setIsEditEnabled(false);
      }, 1000);
    } else if (isStrategyUpdateFailed) {
      toast.error("Failed to update fields!");
    }
    // eslint-disable-next-line
  }, [isStrategyUpdateSuccess, isStrategyUpdateFailed]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        newestOnTop={true}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      {isStrategyUpdatePending && <ActivityIndicator />}
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex">
          <h2 className="text-sm text-primary uppercase font-interSemiBold mr-1.5 mb-1.5">
            campaign settings
          </h2>
        </div>
        <div className="flex flex-col space-y-4">
          <InputWithToggle
            type="text"
            name="campaignName"
            label="Campaign Name"
            register={register}
            reactHooksFormEnabled={true}
            requiredMessage="Campaign Name is required"
            validation={errors.campaignName?.message}
            validationClassName="text-colorDelete ml-3 text-sm"
            className={`${
              errors.campaignName?.message && "border-[#D34638] "
            }   `}
          />
          {/* <ControlledSelect
            data={formattedGoals}
            errorFieldMsg={errors.goal?.message}
            isMulti={false}
            extractValueOnly={false}
            labelName="Goal"
            register={register}
            setValue={setValue}
            registerFieldName="goal"
            value={formValues.goal}
            requiredMessage="Goal is required"
          /> */}
          <div className="flex w-[27.5rem]">
            <div className="flex flex-col xs:w-[8.75rem] sm:w-[11.5rem]">
              <label className="font-interRegular text-sm text-secondary mb-1">
                Start Date
              </label>
              <DatePicker
                id="startDate"
                ref={getDatePickerRef("startDate")}
                showIcon
                icon={
                  <img
                    className="absolute cursor-pointer right-1.5 top-2"
                    onClick={() => handleIconClick("startDate")}
                    src={CalendarIcon}
                    alt="calendar"
                  />
                }
                selected={
                  formValues.startDate ? new Date(formValues.startDate) : null
                }
                onChange={(date) => {
                  if (date) {
                    const isoDate = date.toISOString();
                    setValue("startDate", isoDate, { shouldValidate: true });
                  } else {
                    setValue("startDate", null, { shouldValidate: true });
                  }
                }}
                dateFormat="MM/dd/yyyy"
                className={`${
                  errors.startDate ? "border-[#D34638]" : "border-[#40444f]"
                } w-full border-[1px] border-solid rounded-[0.625rem] font-interRegular text-sm text-primary datepicker-wrapper`}
                placeholderText=""
                minDate={today}
                maxDate={
                  formValues.endDate ? new Date(formValues.endDate) : null
                }
              />
              <input
                type="hidden"
                {...register("startDate", {
                  validate: (value) =>
                    value ? true : "Start Date is required",
                })}
              />
              {errors.startDate && (
                <p className="text-colorDelete text-sm font-interSemiBold">
                  {errors.startDate.message as string}
                </p>
              )}
            </div>
            <div className="mx-auto self-center ">
              <p className="text-sm text-secondary mt-4  font-interRegular">
                TO
              </p>
            </div>
            <div className="flex flex-col xs:w-[8.75rem] sm:w-[11.5rem]">
              <label className="font-interRegular text-sm text-secondary mb-1">
                End Date
              </label>
              <DatePicker
                id="endDate"
                ref={getDatePickerRef("endDate")}
                showIcon
                icon={
                  <img
                    className="absolute cursor-pointer right-1.5 top-2"
                    onClick={() => handleIconClick("endDate")}
                    src={CalendarIcon}
                    alt="calendar"
                  />
                }
                selected={
                  formValues.endDate ? new Date(formValues.endDate) : null
                }
                onChange={(date) => {
                  if (date) {
                    const isoDate = date.toISOString();
                    setValue("endDate", isoDate);
                  } else {
                    setValue("endDate", null);
                  }
                }}
                dateFormat="MM/dd/yyyy"
                className={`${
                  errors.endDate ? "border-[#D34638]" : "border-[#40444f]"
                } w-full border-[1px] border-solid rounded-[0.625rem] font-interRegular text-sm text-primary datepicker-wrapper`}
                placeholderText=""
                minDate={
                  formValues.startDate
                    ? new Date(
                        new Date(formValues.startDate).setDate(
                          new Date(formValues.startDate).getDate() + 1
                        )
                      )
                    : today
                }
              />
              <input
                type="hidden"
                {...register("endDate", {
                  validate: (value) => (value ? true : "End Date is required"),
                })}
              />
              {errors.endDate && (
                <p className="text-colorDelete text-sm font-interSemiBold">
                  {errors.endDate.message as string}
                </p>
              )}
            </div>
          </div>
          <div className="flex">
            <InputWithToggle
              type="text"
              name="theme"
              label="Theme"
              register={register}
              toolTipEnabled={true}
              tooltipText={`
              An advertising theme is the central idea or message that runs throughout an advertising campaign. It ties together all the individual ads and marketing materials, ensuring consistency and cohesion. The theme often reflects the brand's identity, values, or a specific product feature and is designed to resonate with the target audience, making the campaign more memorable and effective. For example, a theme could be "Valentine day” for a restaurant  hoping to drive customers to their business for the day
              `}
              reactHooksFormEnabled={true}
              requiredMessage="Theme is required"
              validation={errors.theme?.message}
              validationClassName="text-colorDelete ml-3 text-sm"
              className={`${errors.theme?.message && "border-[#D34638] "}   `}
            />
            <div
              onClick={async () => {
                if (advertiserData?.industryId && formValues.startDate) {
                  const industryId = advertiserData.industryId;
                  const date = extractMonth(formValues.startDate);
                  const reqData = { industryId, month: date };
                  const res = await getSuggestedTheme({ data: reqData });
                  if (res) {
                    setValue("theme", res.suggestedTheme);
                  }
                }
              }}
              className="self-end mb-2.5 ml-3 cursor-pointer"
            >
              <Tooltip
                Icon={RefreshIcon}
                bodyText={"Suggest another theme"}
                className=" w-5 h- fill-current text-gray-500"
              />
            </div>
          </div>
          <InputWithToggle
            type="number"
            name="budget"
            // label="Monthly (30 day) Budget"
            label="Total Budget"
            register={register}
            reactHooksFormEnabled={true}
            requiredMessage="Budget is required"
            minValue={100}
            validation={errors.budget?.message}
            onBlurFunc={async () => {
              if (
                formValues.budget &&
                formValues.budget >= 100 &&
                advertiserData?.industryId
              ) {
                const reqData = {
                  budget: formValues.budget,
                  industryId: advertiserData?.industryId,
                  organizationId: advertiserData?.organizationId,
                };
                const response = await getSuggestedAllocations(reqData);

                setSuggestedAllocations((prevAllocations = {}) => {
                  const filteredResponse = Object.keys(response).reduce(
                    (acc, key) => {
                      const mappedKey = (allocationMapping[
                        key as keyof typeof allocationMapping
                      ] || key) as keyof TSuggestedAllocationProps;

                      if (mappedKey in prevAllocations) {
                        acc[mappedKey] = response[
                          key as keyof typeof response
                        ] as number;
                      }

                      return acc;
                    },
                    {} as TSuggestedAllocationProps
                  );

                  return filteredResponse;
                });
              }
            }}
            validationClassName="text-colorDelete ml-3 text-sm"
            className={`${errors.budget?.message && "border-[#D34638] "}   `}
          />
        </div>

        <div className="flex mt-6">
          <h2 className="text-sm text-primary uppercase font-interSemiBold mr-1.5 mb-1.5">
            budget allocation
          </h2>
          {isSuggestedAllocationsUpdated && (
            <p className="text-sm font-interRegular text-colorOrange ml-5">
              {updatedSuggestedValsMessage}
            </p>
          )}
        </div>
        <div className="grid grid-cols-[2fr_1fr_1.5fr] gap-y-3 space-x-12 w-[27rem]">
          <div className="flex flex-col space-y-6">
            <div className="h-5"></div>
            {channels?.map((channel) => (
              <p
                key={channel.title}
                className="text-sm text-primary font-interRegular py-1 pr-3 last-of-type:pt-3 last-of-type:pb-0 "
              >
                {channel.title}
              </p>
            ))}
          </div>

          <div className="flex flex-col space-y-[25px]">
            <h3 className="text-sm text-secondary font-interRegular">
              Suggested
            </h3>

            {Object.entries(suggestedAllocations || {})
              .filter(([key, value]) => value !== undefined || value !== null)
              .map(([channelKey, value]) => (
                <p
                  key={channelKey}
                  className={`text-sm ${
                    isSuggestedAllocationsUpdated
                      ? "text-colorOrange"
                      : "text-primary"
                  } py-1 last-of-type:pt-2 last-of-type:pb-0 px-3  font-interRegular`}
                >
                  {value}%
                </p>
              ))}
          </div>

          <div className="flex flex-col space-y-6">
            <h3 className="text-sm text-secondary  font-interRegular">
              Current
            </h3>
            {campaignStrategyData &&
              channels?.map((channel) => (
                <InputWithToggle
                  type="number"
                  key={channel.title}
                  name={channel.channelKey}
                  label=""
                  register={register}
                  reactHooksFormEnabled={true}
                  requiredMessage={`${channel.title} is required`}
                  validation={errors[channel.channelKey]?.message}
                  customWidth="w-full"
                  customInputLabelContainer="relative"
                  validationClassName="text-colorDelete ml-3 text-sm absolute left-full top-1 w-[20rem]"
                  customPadding="py-1 px-3"
                  className={`w-full ${
                    errors[channel.channelKey]?.message && "border-[#D34638] "
                  }`}
                />
              ))}
          </div>
        </div>
        <div className="flex items-center  mt-20">
          <RoundedButton
            type="submit"
            className="py-2.5 px-4"
            borderRadius="rounded-md"
            text={
              <p className=" text-sm text-white inline-flex items-center font-interRegular">
                <EditIcon className="text-white fill-current mr-2 text-2xl " />
                SAVE
              </p>
            }
          />
          <p
            onClick={() => {
              setIsEditEnabled(false);
            }}
            className="text-sm cursor-pointer font-interRegular ml-7 text-colorBlue"
          >
            Cancel
          </p>
        </div>
      </form>
    </div>
  );
};

export default CampaignStrategyEditMode;
