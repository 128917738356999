import { useInfiniteQuery, useMutation } from "@tanstack/react-query";

import { request } from "../axios";

import { queryClient } from "../../App";

export interface ISessionChatMessagesQueryData {
  pageParam: number;
  limit: number;
  sessionId: string | null;
  signInToken: string | undefined;
}

export interface IChatBotAssistantRequestData {
  sessionId: string | null;
  advertiserId: number | null;
  message: string;
}

export interface IChatBotSessionMessagesResponse {
  data: IChatMessage[];
  pagination: {
    totalRecords: number;
    currentPage: number;
    totalPages: number;
    nextPage: number;
    prevPage: number;
  };
}

export interface IChatMessage {
  id: number;
  chatId: number;
  userId: string;
  message: string;
  sender: "assistant" | "user";
  isRead: boolean;
  createdAt: string;
  updatedAt: string;
}

async function sendChatBotMessage(data: IChatBotAssistantRequestData) {
  return request({
    url: "/chat",
    method: "POST",
    data,
  });
}

export const useSendChatBotMessage = (sessionId: string | null) => {
  return useMutation({
    mutationFn: sendChatBotMessage,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["usersChatBotMessages", sessionId],
      });
    },
  });
};

async function getSessionChatMessages(
  query: ISessionChatMessagesQueryData
): Promise<IChatBotSessionMessagesResponse> {
  const { sessionId, pageParam, limit } = query;
  return request({
    url: `chat-message/session/${sessionId}?page=${pageParam}&limit=${limit}`,
  });
}

export const useSessionChatMessages = (
  queryData: ISessionChatMessagesQueryData
) => {
  return useInfiniteQuery({
    queryKey: ["usersChatBotMessages", queryData.sessionId],
    queryFn: ({ pageParam = 0 }) =>
      getSessionChatMessages({ ...queryData, pageParam }),
    enabled: !!queryData.signInToken && !!queryData.sessionId,
    retry: 1,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.nextPage;
    },
  });
};

// export const useSessionChatMessages = (
//   queryData: ISessionChatMessagesQueryData
// ) => {
//   return useQuery({
//     queryKey: ["usersChatBotMessages", queryData.sessionId],
//     queryFn: () => getSessionChatMessages(queryData),
//     enabled: !!queryData.signInToken,
//     retry: 1,
//   });
// };
