import { StateCreator } from "zustand";

type TNotifierOptions = {
  message: string;
  icon?: string;
};

export interface INotifierProps {
  isVisible: boolean;
  message: string;
  isLoading: boolean;
  icon: string | undefined;
  showNotifier: (options: TNotifierOptions) => void;
  showLoading: (isLoading: boolean) => void;
  hideNotifier: () => void;
}

export const createNotifierSlice: StateCreator<INotifierProps> = (
  set,
  get
) => ({
  isVisible: false,
  message: "",
  isLoading: false,
  icon: undefined,
  showNotifier: (state) => {
    set({ message: state.message, icon: state.icon, isVisible: true });
  },
  showLoading: (state) => {
    set({ isLoading: state });
  },
  hideNotifier: () => {
    set({
      isVisible: false,
      message: "",
      icon: undefined,
      isLoading: false,
    });
  },
});
