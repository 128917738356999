// import MarkdownViewer from "../../components/markdown-renderer";
import Navbar from "../../components/navBar";

const TermsAndServices = () => {
  return (
    <div className="flex flex-col">
      <Navbar isDefaultNavLinksEnabled={false} />
      <div className="flex flex-col mx-auto mt-14 px-20 py-10 markdown-content">
        {/* <MarkdownViewer
          filePath={"/markdowns/sparkbirdai-terms-and-services.md"}
        /> */}
      </div>
    </div>
  );
};

export default TermsAndServices;
