import React, { FC } from "react";

import { useAppStore } from "../../store";
import { useShallow } from "zustand/shallow";

import Navbar from "../../components/navBar";
import Sidebar from "../../components/fixedSideBar";
import { Notifier } from "../../components/notifier";

interface IMainLayout {
  children: React.ReactNode;
  showSidebarLogo?: boolean;
  showSidebar?: boolean;
  showWizard?: boolean;
  showCampaignImage?: boolean;
}

const MainLayout: FC<IMainLayout> = ({
  children,
  showSidebarLogo,
  showSidebar,
  showWizard,
}) => {
  const { isSidebarOpen, visible, message, icon, isLoading, hideNotifier } =
    useAppStore(
      useShallow((state) => ({
        isSidebarOpen: state.isSidebarOpen,
        visible: state.isVisible,
        message: state.message,
        icon: state.icon,
        isLoading: state.isLoading,
        hideNotifier: state.hideNotifier,
      }))
    );

  const notifierProps = {
    visible,
    message,
    icon,
    isLoading,
  };

  return (
    <div className="flex flex-col  min-h-screen bg-[#f6f6f6]">
      <Navbar />
      <div className="flex mt-14">
        <div className="flex  w-full sm:flex-row flex-col">
          <Sidebar
            showWizard={showWizard}
            showSideBar={showSidebar}
            showSidebarLogo={showSidebarLogo}
          />
          <div
            className={`
            w-full ${
              isSidebarOpen ? " md:ml-[15rem]" : "ml-0"
            }  xs:ml-0  bg-[#f6f6f6] xl:pr-0

          `}
          >
            <Notifier {...notifierProps} closeNotifier={hideNotifier} />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
