export function getInitials(displayName: string): string {
  const names = displayName.split(" ");
  let initials = "";

  for (let i = 0; i < 2; i++) {
    if (names[i]) {
      initials += names[i].charAt(0).toUpperCase();
    }
  }

  return initials;
}
