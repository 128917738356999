import { getInitials } from "./getInitials";
import { randomColor } from "./randomColors";

export const generateImageWithInitials = async (name: string) => {
  const canvas = document.createElement("canvas");
  canvas.width = 200;
  canvas.height = 200;
  const ctx = canvas.getContext("2d");
  if (!ctx) return null;

  const bgColor = randomColor();

  // Clear the canvas before drawing
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Clip the canvas into a circle
  ctx.beginPath();
  ctx.arc(
    canvas.width / 2,
    canvas.height / 2,
    canvas.width / 2,
    0,
    Math.PI * 2
  );
  ctx.closePath();
  ctx.clip(); // Apply the circular mask

  ctx.fillStyle = bgColor;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  const textColor =
    parseInt(bgColor.replace("#", ""), 16) > 0xffffff / 2 ? "#000" : "#fff";

  ctx.fillStyle = textColor;
  ctx.font = "bold 70px Arial";
  ctx.textAlign = "center";

  ctx.textBaseline = "middle";

  ctx.fillText(getInitials(name), canvas.width / 2, canvas.height / 2);

  return new Promise<File | null>((resolve) => {
    canvas.toBlob((blob) => {
      if (!blob) return resolve(null);
      resolve(new File([blob], `${name}-logo.jpg`, { type: "image/jpg" }));
    }, "image/jpg");
  });
};
