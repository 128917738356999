import { ICampaignStrategyResponse } from "../../services/campaigns";

export default function EstimationTable({
  campaignStrategyData,
  className,
}: {
  campaignStrategyData: ICampaignStrategyResponse | undefined;
  className: string;
}) {
  return (
    <div className={className}>
      <div className="w-full rounded-md border-solid border border-[#E5E9EB]">
        <div className="py-3 border-b border-solid border-[#E5E9EB] rounded-md ">
          <p className="text-primary text-sm font-interRegular pl-3">
            Estimated Potential Customers (
            <span className="text-colorDelete text-sm font-interRegular">
              Low
            </span>
            <span className="mx-1">-</span>
            <span className="text-colorGreen text-sm font-interRegular">
              High
            </span>
            )
          </p>
        </div>
        <div className="flex">
          <div className=" w-1/2 mr-[1px] border rounded-md py-3 border-solid border-[#E5E9EB]">
            <p className="text-sm pl-3 font-interRegular py-0.5">
              Visiting your website
            </p>
          </div>
          <div className="text-center w-1/2  border rounded-md py-3 border-solid border-[#E5E9EB]">
            <p>
              <span className="text-colorDelete">
                {campaignStrategyData?.campaignVisitEstimation.low}
              </span>
              <span className="mx-1">-</span>
              <span className="text-colorGreen">
                {campaignStrategyData?.campaignVisitEstimation.high}
              </span>
            </p>
          </div>
        </div>
        <div className="flex">
          <div className=" w-1/2 mr-[1px] border rounded-md py-3 border-solid border-[#E5E9EB]">
            <p className="text-sm pl-3 font-interRegular py-0.5">
              Viewing your Ads
            </p>
          </div>
          <div className="text-center w-1/2  border rounded-md py-3 border-solid border-[#E5E9EB]">
            <p>
              <span className="text-colorDelete">
                {campaignStrategyData?.campaignImpressionEstimation.low}
              </span>
              <span className="mx-1">-</span>
              <span className="text-colorGreen">
                {campaignStrategyData?.campaignImpressionEstimation.high}
              </span>
            </p>
          </div>
        </div>
        <div className=" border py-2 px-1 border-solid border-[#E5E9EB] rounded-md ">
          <p className="text-xs text-primary font-interRegular">
            These estimates are based on industry averages and may vary due to
            factors like seasonality, competition, and location.
          </p>
        </div>
      </div>
    </div>
  );
}
