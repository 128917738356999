import { StateCreator } from "zustand";

import { IChatMessage } from "../../services/chatbot";

import { getCookie, setCookie } from "../../utils/cookies";
import { generateRandomId } from "../../utils/generateUniqueId";

export interface IChatBotProps {
  messages: IChatMessage[];
  setMessages: (data: IChatMessage[]) => void;
  isToggled: boolean;
  setIsToggled: (value: boolean) => void;
  handleToggle(): void;
}

export const createChatBotSlice: StateCreator<IChatBotProps> = (set, get) => ({
  messages: [],
  setMessages: (messages) => set({ messages }),
  isToggled: true,
  setIsToggled: (state) => set({ isToggled: state }),
  handleToggle: () => {
    const sessionId = generateRandomId();
    const isChatSessionIdExisted = getCookie("chatSessionId");
    if (get().isToggled && !isChatSessionIdExisted) {
      setCookie("chatSessionId", sessionId);
    }
    set((state) => ({ isToggled: !state.isToggled }));
  },
});
