import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";

import { SubmitHandler, useForm } from "react-hook-form";

import { useAppStore } from "../../store";
import { useShallow } from "zustand/shallow";

import ControlledSelect from "../controlledSelect";

import FileUpload from "../../components/imageUpload";
import TopContent from "../../components/topContent";
import ContentContainer from "../../components/contentContainer";
import InputWithToggle from "../../components/input/inputWithToggle";
import RoundedButton from "../../components/roundedButton";
import SearchBar from "../../components/urlBar";
import ProgressTracking from "../../components/progressTracking";

import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon-mui.svg";

import {
  useAllAdvertisers,
  useCreateAdvertiser,
} from "../../services/advertiser";
import { useUploadPhoto } from "../../services/user";
import { useOrganizationIndustries } from "../../services/industry";
import { useAdvertiserTones } from "../../services/advertiser/tone";
import { useDomainStyle } from "../../services/multitenacy-config";

import { handleFileType } from "../../utils/handleFileType";
import { formatFileName } from "../../utils/formatUploadedFileName";
import { generateImageWithInitials } from "../../utils/generateImageWithInitials";

import { IAddAdvertiserData, IAddAdvertiserReqData } from "../../interfaces";

import { tooltipTexts } from "../../constants/tooltipTexts";

const steps = [
  "Reading your website",
  "Summarizing the information",
  "Collecting usable images",
  "Reviewing content",
];

function AddAdvertiser() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    // reset: resetForm,
    formState: { errors },
    watch,
  } = useForm<IAddAdvertiserData>();

  const registeredFormData: IAddAdvertiserData = watch();

  const isSubmitDisabled =
    !registeredFormData.displayName ||
    !registeredFormData.email ||
    !registeredFormData.phoneNumber ||
    !registeredFormData.industryId;

  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState<FormData | null>(null);
  const [, setSelectedFileName] = useState<string>("");

  const { setSelectedAdvertiser, domainName } = useAppStore(
    useShallow((state) => ({
      setSelectedAdvertiser: state.setSelectedAdvertiser,
      domainName: state.domainName,
    }))
  );

  const { data: domainStyleData } = useDomainStyle(domainName);

  const { data: allAdvertisers } = useAllAdvertisers(
    domainStyleData?.id ?? null
  );

  const {
    mutateAsync: createAdvertiser,
    isPending: isNewAdvertiserPending,
    isSuccess: isNewAdvertiserAdded,
    isError: isNewAdvertiserError,
    reset: resetCreateAdvertiserStates,
  } = useCreateAdvertiser();

  const {
    mutateAsync: uploadPhotoToGCP,
    isPending: isPhotoUploadPending,
    isSuccess: isPhotoUploadSuccess,
    data: photoData,
  } = useUploadPhoto();

  const { data: INDUSTRIES, isPending: isFetchingIndustries } =
    useOrganizationIndustries(domainStyleData?.id ?? null);
  const { data: TONES, isPending: isFetchingTones } = useAdvertiserTones();

  const formattedTones = TONES?.map((tone) => {
    return {
      value: tone.tone,
      label: tone.tone,
    };
  });

  async function processFile(file: File) {
    const newFile = formatFileName(file);
    const formData = new FormData();
    formData.append("file", newFile);
    const fileTypeIsValid = await handleFileType(formData, setFile, "file");
    if (fileTypeIsValid) {
      const photoUrl = await uploadPhotoToGCP(formData);
      return photoUrl.url;
    }
  }

  async function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      await processFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  }

  async function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFileName(e.target.files[0].name);
      await processFile(e.target.files[0]);
    }
  }

  const onSubmit: SubmitHandler<IAddAdvertiserData> = async (data) => {
    if (isSubmitDisabled) {
      return;
    }

    let { displayName, tone, photoUrl, ...rest } = data;
    const toneValue: string = registeredFormData.tone?.value;

    let photoValue = registeredFormData.photoUrl;

    if (!photoValue) {
      const photoWithInitials = await generateImageWithInitials(displayName);
      const savedPhoto =
        photoWithInitials && (await processFile(photoWithInitials));
      photoValue = savedPhoto ?? null;
    }

    const reqData: IAddAdvertiserReqData = {
      displayName,
      tone: toneValue,
      photoUrl: photoValue,
      organizationId: domainStyleData?.id ?? null,
      ...rest,
    };

    await createAdvertiser(reqData);
  };

  useEffect(() => {
    if (TONES && TONES.length !== 0) {
      setValue("tone", {
        value: "Neutral/Professional",
        label: "Neutral/Professional",
      });
    }
    // eslint-disable-next-line
  }, [TONES?.length]);

  useEffect(() => {
    if (isPhotoUploadSuccess) {
      setValue("photoUrl", photoData.url);
    }
    //eslint-disable-next-line
  }, [isPhotoUploadSuccess]);

  useEffect(() => {
    if (registeredFormData.industryId) {
      clearErrors("industryId");
    }
    if (registeredFormData.photoUrl) {
      clearErrors("photoUrl");
    }
  }, [registeredFormData.industryId, registeredFormData.photoUrl, clearErrors]);

  useEffect(() => {
    let advertisersRefetchTimer: ReturnType<typeof setTimeout>;

    if (isNewAdvertiserPending && allAdvertisers) {
      advertisersRefetchTimer = setTimeout(() => {
        queryClient
          .invalidateQueries({
            queryKey: ["advertisers"],
          })
          .then(() => setSelectedAdvertiser(allAdvertisers?.[0].id));
      }, 2000);
    }

    return () => {
      clearTimeout(advertisersRefetchTimer);
    };
    // eslint-disable-next-line
  }, [isNewAdvertiserPending, allAdvertisers?.length]);

  useEffect(() => {
    if (isNewAdvertiserError || isNewAdvertiserAdded) {
      navigate("/websites");
      resetCreateAdvertiserStates();
    }
    //eslint-disable-next-line
  }, [isNewAdvertiserError, isNewAdvertiserAdded]);

  return (
    <div className="flex  flex-col">
      {isNewAdvertiserPending && (
        <ProgressTracking
          steps={steps}
          isRequestStillProcessing={isNewAdvertiserPending}
        />
      )}
      <TopContent
        title="New Advertiser and Website"
        subTitle="Provide details below to add both the advertiser and associated business website"
      />
      <ContentContainer>
        <div className=" flex flex-1 flex-col p-12 pb-6 ">
          <div className=" flex flex-1 flex-col space-y-5 justify-between mb-12 items-center sm:items-stretch">
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-1 flex-col  space-y-6">
                <div className="flex mt-6">
                  <div className="flex flex-col xs:w-[18.75rem] sm:w-[27.5rem] ">
                    <label
                      className="font-interRegular text-sm text-secondary mb-1"
                      htmlFor="photoUrl"
                    >
                      Advertiser image/logo (optional)
                    </label>
                    <FileUpload
                      handleDrop={handleDrop}
                      {...register("photoUrl")}
                      defaultHeight="h-[10rem]"
                      mainText="Click to upload or drag and drop an image."
                      secondaryText="Recommended size is 320 x 320 pixels to ensure that your profile picture looks great in an Ad"
                      subText="SVG, PNG, JPG or GIF..."
                      file={file}
                      dragging={dragging}
                      setDragging={setDragging}
                      handleChange={handleFileChange}
                      renderedOnFileSelection={
                        <>
                          {isPhotoUploadPending && (
                            <div className="">Loading...</div>
                          )}
                          {isPhotoUploadSuccess && (
                            <div className="flex relative  w-full h-full my-2   items-center justify-center">
                              <img
                                src={photoData.url}
                                alt="Uploaded File"
                                className="w-full max-h-[90%] object-contain"
                              />{" "}
                              <button
                                onClick={() => {
                                  setFile(null);
                                  setValue("photoUrl", null);
                                }}
                                className="absolute top-0 right-0 px-2 py-1 bg-red-500 text-white rounded hover:bg-red-700 transition duration-150 ease-in-out"
                              >
                                X
                              </button>
                            </div>
                          )}
                        </>
                      }
                    />
                  </div>
                  {errors.photoUrl && (
                    <span
                      role="alert"
                      className="font-interSemiBold   mt-10 ml-2 text-colorDelete text-sm"
                    >
                      {errors.photoUrl.message}
                    </span>
                  )}
                </div>
                <InputWithToggle
                  type="text"
                  name="displayName"
                  label="Name (Appears on ad)"
                  register={register}
                  requiredMessage="Name is required"
                  validation={errors.displayName?.message}
                  reactHooksFormEnabled={true}
                  validationClassName="mt-0 text-sm ml-2"
                  className={`${
                    errors.displayName?.message && "border-[#D34638] "
                  }   `}
                />

                <div className="flex flex-col">
                  {/* <p className="font-interRegular text-sm text-secondary mb-1">
                    Phone Number
                  </p> */}
                  <div className="flex">
                    <div className="flex ">
                      <InputWithToggle
                        type="text"
                        name="phoneNumber"
                        label="Phone Number (Appears on ad)"
                        register={register}
                        requiredMessage="Phone number is required"
                        validation={errors.phoneNumber?.message}
                        reactHooksFormEnabled={true}
                        validationClassName="mt-0 text-sm ml-2"
                        className={`${
                          errors.phoneNumber?.message && "border-[#D34638] "
                        }   `}
                      />
                    </div>
                  </div>
                </div>
                <InputWithToggle
                  type="text"
                  name="email"
                  label="Email (Appears on ad)"
                  register={register}
                  pattern={{
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Entered value does not match email format",
                  }}
                  requiredMessage="Email is required"
                  validation={errors.email?.message}
                  reactHooksFormEnabled={true}
                  validationClassName="mt-0 text-sm ml-2"
                  className={`${errors.email?.message && "border-[#D34638] "}`}
                />

                <div className="flex items-center">
                  <ControlledSelect
                    labelName="Industry"
                    data={INDUSTRIES}
                    isPending={isFetchingIndustries}
                    register={register}
                    registerFieldName="industryId"
                    placeholder="Type to filter the list..."
                    requiredMessage="Industry is required"
                    isMulti={false}
                    extractValueOnly={true}
                    setValue={setValue}
                    errorFieldMsg={errors.industryId?.message}
                  />
                </div>

                <div className="flex items-center">
                  <ControlledSelect
                    labelName="Brand Voice"
                    tooltipBody={tooltipTexts.BRAND_VOICE}
                    tooltipClassName="cursor-pointer ml-1 h-4 w-4 mt-0.5"
                    data={formattedTones}
                    isPending={isFetchingTones}
                    register={register}
                    registerFieldName="tone"
                    requiredMessage="Tone is required"
                    value={registeredFormData.tone}
                    isMulti={false}
                    extractValueOnly={false}
                    setValue={setValue}
                    errorFieldMsg={errors.tone?.message}
                  />
                </div>

                <SearchBar
                  label="Website URL"
                  name="websiteUrl"
                  register={register}
                  pattern={{
                    message: "Make sure url is in correct format",
                    value: /^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/,
                  }}
                  requiredMessage="Website is required"
                  reactHooksFormEnabled={true}
                  urlErrMesg={errors.websiteUrl?.message}
                />
                <div className="max-w-[80%]">
                  <p className="text-primary  text-sm  font-interSemiBold">
                    When you click save, you'll be redirected to the page where
                    the website will be added and processed. Don't worry, your
                    advertiser details are saved, and you can return to manage
                    them later.
                  </p>
                </div>
              </div>

              {isNewAdvertiserError && (
                <p className="font-interSemiBold my-5 text-colorDelete text-sm">
                  Failed to add new advertiser!
                </p>
              )}
              <div className="flex  pt-10 items-center">
                <RoundedButton
                  className="py-2.5 px-4"
                  type="submit"
                  isEnabled={!isPhotoUploadPending}
                  borderRadius="rounded-md"
                  text={
                    <p className=" text-sm text-white inline-flex items-center font-interRegular">
                      <EditIcon className="text-white fill-current mr-2 text-2xl " />
                      {isNewAdvertiserPending ? "Saving.." : "SAVE"}
                    </p>
                  }
                />
                <p
                  onClick={() => navigate(-1)}
                  className="text-sm cursor-pointer font-interRegular ml-7 text-colorBlue"
                >
                  Cancel
                </p>
              </div>
            </form>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
}

export default AddAdvertiser;
