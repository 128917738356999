import { useEffect, useState } from "react";

import { queryClient } from "../../../App";

import { useAppStore } from "../../../store";
import { useShallow } from "zustand/shallow";

import { renderConfigFieldComponents } from "./renderConfigFieldComponents";

import Website from "../websiteSelection";
import TabView from "./tabView";
import Strategy from "./strategy";
import Targeting from "./targeting";
import Ads from "./creative";
import Confirm from "./confirm";

import TopContent from "../../../components/topContent";
import ProgressTracking from "../../../components/progressTracking";

import { useAdvertiserSites, useWebsite } from "../../../services/web-details";
import { useCampaignBudget } from "../../../services/wizard/budget";
import { useBannerBearImages } from "../../../services/wizard/bannerbear";
import { useCampaignFields } from "../../../services/industry-organization-campaign-fields";
import {
  useCampaignsLinkedToWebsite,
  useCreateCampaign,
} from "../../../services/campaigns";

import { CampaignFieldData } from "../../../interfaces";

const steps = [
  "Building your allocation plan",
  "Structuring your campaigns",
  "Writing your creative content",
  "Designing your creative",
];

const Stepper = () => {
  const { step, setStep, selectedAdvertiser, campaignId, setCampaignId } =
    useAppStore(
      useShallow((state) => ({
        step: state.step,
        setStep: state.setStep,
        selectedAdvertiser: state.selectedAdvertiser,
        campaignId: state.campaignId,
        setCampaignId: state.setCampaignId,
      }))
    );

  const [isCampaignDetailsPending, setIsCampaignDetailsPending] =
    useState(false);

  const { data: usersAllSites } = useAdvertiserSites(selectedAdvertiser);
  const selectionData = usersAllSites?.data.map((item: any) => ({
    id: item.id,
    value: item.shortName,
    label: item.shortName,
  }));

  const [selectedListing, setSelectedListing] = useState<any>([]);
  const [, setIsRefetchTriggered] = useState(false);
  const [refetchTrigger, setRefetchTrigger] = useState(0);

  const { data: selectedSiteData } = useWebsite(selectedListing?.id);

  const [textArea] = useState<string | null>("Ad");
  const [campaignFieldVals, setCampaignFieldVals] = useState<CampaignFieldData>(
    {}
  );
  const [initialCampaignFieldVals, setInitialCampaignFieldVals] =
    useState<CampaignFieldData>({});
  const [
    openNewCampaignVerificationModal,
    setOpenNewCampaignVerificationModal,
  ] = useState<boolean>(false);
  const [multiSelectFieldIds, setMultiSelectFieldIds] = useState<
    {
      id: number;
      campaignFieldName: string;
      campaignFieldId: number;
    }[]
  >([]);

  const { data: campaignBudgetData } = useCampaignBudget(campaignId);

  const { data: bannerbearImagesData } = useBannerBearImages(campaignId);

  const { data: campaignFields } = useCampaignFields(
    selectedAdvertiser,
    campaignId
  );

  const {
    mutateAsync: createCampaign,
    isPending: isCreatingCampaignPending,
    isError: isCreatingCampaignError,
  } = useCreateCampaign(selectedAdvertiser);

  const { data: campaignsByWebsiteIdList } = useCampaignsLinkedToWebsite(
    selectedSiteData?.id
  );

  const hasNullDisplayAdImages = bannerbearImagesData?.displayAds.some(
    (adsObj) => adsObj.imageUrl === null
  );
  const hasNullSocialAdImages = bannerbearImagesData?.socialAds.some(
    (adsObj) => adsObj.imageUrl === null
  );
  const hasNullSearchAdImages = bannerbearImagesData?.searchAds.some(
    (adsObj) => adsObj.imageUrl === null
  );

  const hasNullBannerBearImages =
    hasNullDisplayAdImages || hasNullSocialAdImages || hasNullSearchAdImages;

  const canMoveToWizardStep =
    selectedListing &&
    selectedSiteData?.isEditable &&
    selectedSiteData?.isFieldsFilled &&
    selectedSiteData.numberOfAssets > 0;

  function refetchIfHasNullImages() {
    if (hasNullBannerBearImages) {
      queryClient.invalidateQueries({
        queryKey: ["bannerbearImages", campaignId],
      });
    }
  }

  function isAllConfigFieldsFilled() {
    return Object.keys(campaignFieldVals).every(
      (field: string) => campaignFieldVals[field]
    );
  }

  async function handleWebsiteNext() {
    if (isCreatingCampaignPending) return;

    if (canMoveToWizardStep) {
      if (campaignsByWebsiteIdList?.length !== 0) {
        setOpenNewCampaignVerificationModal(true);
        return;
      }
      handleCreateNewCampaign();
    }
  }

  async function handleCreateNewCampaign() {
    if (
      selectedListing &&
      selectedSiteData?.isFieldsFilled &&
      selectedSiteData.numberOfAssets > 0
    ) {
      setIsCampaignDetailsPending(true);
      const campaignResponse = await createCampaign(selectedSiteData?.id);
      const { id } = campaignResponse;

      if (!id) {
        return;
      }

      setCampaignId(id);
      setIsCampaignDetailsPending(false);
      setStep(1);
    }
  }

  async function handlePlanNext() {
    refetchIfHasNullImages();

    if (selectedListing && campaignBudgetData) {
      setStep(3);
    }
  }

  async function handleCampaignNext() {
    refetchIfHasNullImages();

    if (isAllConfigFieldsFilled() === false || !textArea) {
      return;
    }

    if (bannerbearImagesData) {
      setStep(4);
    }
  }

  useEffect(() => {
    if (selectionData) {
      setSelectedListing({ ...selectionData[0] });
    }
    // eslint-disable-next-line
  }, [usersAllSites]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (campaignFields) {
      renderConfigFieldComponents({
        campaignFields,
        setCampaignFieldVals,
        setInitialCampaignFieldVals,
        setMultiSelectFieldIds,
      });
    }
  }, [campaignFields]);

  useEffect(() => {
    if (bannerbearImagesData) {
      if (
        // hasNullBannerBearImages ||
        // bannerbearImagesData.displayAds.length === 0
        hasNullBannerBearImages
      ) {
        setIsRefetchTriggered(true);
        setTimeout(() => {
          queryClient.refetchQueries({
            queryKey: ["bannerbearImages", campaignId],
          });
          setRefetchTrigger((prev) => prev + 1);
        }, 1500);
      } else {
        setIsRefetchTriggered(false);
      }
    }
    //eslint-disable-next-line
  }, [bannerbearImagesData, campaignId, refetchTrigger]);

  if (!selectedAdvertiser) {
    return (
      <div className="bg-white flex h-[9.2rem] items-start justify-start rounded-lg py-8 px-5 ">
        <div className="px-8 py-3 flex-grow-0  rounded-3xl  bg-[#F4F2EE] border border-[#F9F3F0]">
          <p className="text-base font-ManropeRegular ">
            It seems that you have not selected an advertiser to proceed
            further.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col mb-10">
          <TopContent
            title={step === 1 ? "Campaign Information" : "Campaign Wizard"}
            subTitle={
              step === 1
                ? "Details of your advertising campaign"
                : "Step-by-step guide to creating and launching ad campaigns"
            }
            showWizard
          />
          {step === 0 && (
            <>
              {isCampaignDetailsPending && !isCreatingCampaignError && (
                <ProgressTracking
                  isRequestStillProcessing={isCampaignDetailsPending}
                  steps={steps}
                />
              )}
              <Website
                handleWebsiteNext={handleWebsiteNext}
                openNewCampaignVerificationModal={
                  openNewCampaignVerificationModal
                }
                setOpenNewCampaignVerificationModal={
                  setOpenNewCampaignVerificationModal
                }
                canMoveToWizardStep={canMoveToWizardStep}
                selectionData={selectionData}
                selectedListing={selectedListing}
                setSelectedListing={setSelectedListing}
                handleCreateNewCampaign={handleCreateNewCampaign}
                selectedSiteData={selectedSiteData}
                isCreatingCampaignError={isCreatingCampaignError}
                isCreatingCampaignPending={isCreatingCampaignPending}
              />
            </>
          )}
          {step === 1 && <TabView creatives={bannerbearImagesData} />}
          {step === 2 && (
            <>
              <Strategy
                campaignId={campaignId}
                selectedListing={selectedListing}
                campaignBudgetData={campaignBudgetData}
                handlePlanNext={handlePlanNext}
              />
            </>
          )}
          {step === 3 && (
            <>
              <Targeting
                campaignFieldVals={campaignFieldVals}
                multiSelectFieldIds={multiSelectFieldIds}
                initialCampaignFieldVals={initialCampaignFieldVals}
                setInitialCampaignFieldVals={setInitialCampaignFieldVals}
                setCampaignFieldVals={setCampaignFieldVals}
                handleCampaignNext={handleCampaignNext}
                textArea={textArea}
                isAllConfigFieldsFilled={isAllConfigFieldsFilled()}
              />
            </>
          )}
          {step === 4 && (
            <>
              <Ads adsFromEach={bannerbearImagesData} />
            </>
          )}
          {step === 5 && (
            <div className="flex flex-col ">
              <Confirm creatives={bannerbearImagesData} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Stepper;
