export default function UserMessage({ text }: { text: string }) {
  return (
    <div className="w-full">
      <div
        style={{ borderRadius: "20px 20px 1px 20px" }}
        className=" text-left float-right py-4 my-2.5 ml-10  mr-1 px-3 bg-[#cccccc] text-black"
      >
        {text}
      </div>
    </div>
  );
}
