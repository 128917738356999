import Campaigns from "../../containers/campaigns";

import MainLayout from "../../layouts/main";

const Index = () => {
  return (
    <MainLayout showSidebar showSidebarLogo>
      <Campaigns />
    </MainLayout>
  );
};

export default Index;
