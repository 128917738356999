import { useState } from "react";

import { useAppStore } from "../../../../store";
import { useShallow } from "zustand/shallow";

import CampaignStrategyReadMode from "./campaignStrategyReadMode";
import CampaignStrategyEditMode from "./campaignStrategyEditMode";

import RoundedButton from "../../../../components/roundedButton";
import ContentContainer from "../../../../components/contentContainer";
import ActivitySpinnerNoBg from "../../../../components/activitySpinner/withNoBgEffect";

import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon-mui.svg";

import { ICreateBudgetResponse } from "../../../../services/wizard/budget";
import { useBudgetOrganizationChannels } from "../../../../services/advertiser/organization-budget-channel";
import {
  useCampaignStrategyInfo,
  useSingleCampaignDetails,
} from "../../../../services/campaigns";
import { useDomainStyle } from "../../../../services/multitenacy-config";

const Strategy = ({
  campaignId,
  selectedListing,
  campaignBudgetData,
  handlePlanNext,
}: {
  campaignId: number | undefined;
  selectedListing: any;
  campaignBudgetData: ICreateBudgetResponse | undefined;
  handlePlanNext(): Promise<void>;
}) => {
  const [isEditEnabled, setIsEditEnabled] = useState<boolean>(false);

  const { selectedAdvertiser, domainName } = useAppStore(
    useShallow((state) => ({
      selectedAdvertiser: state.selectedAdvertiser,
      domainName: state.domainName,
    }))
  );

  const { data: campaignStrategyData, isPending: isStrategyDataPending } =
    useCampaignStrategyInfo(campaignId, selectedAdvertiser);

  const { data: domainStyleData } = useDomainStyle(domainName);
  const { data: organizationBudgetChannels } = useBudgetOrganizationChannels(
    domainStyleData?.id ?? null,
    domainName
  );

  const { data: campaignDetailsData } = useSingleCampaignDetails(
    campaignId,
    selectedAdvertiser
  );

  return (
    <div>
      {isStrategyDataPending ? (
        <ActivitySpinnerNoBg />
      ) : (
        <ContentContainer className="p-7">
          <div>
            {!isEditEnabled &&
              campaignDetailsData?.status &&
              campaignDetailsData?.status !== "active" && (
                <RoundedButton
                  type="button"
                  onClick={() => {
                    if (
                      campaignDetailsData?.status &&
                      campaignDetailsData?.status !== "active"
                    ) {
                      setIsEditEnabled(!isEditEnabled);
                    }
                  }}
                  className="py-2.5 px-4 max-w-fit ml-auto"
                  borderRadius="rounded-md"
                  text={
                    <p className=" text-sm text-white inline-flex items-center font-interRegular">
                      <EditIcon className="text-white fill-current mr-2 text-2xl " />
                      EDIT STRATEGY
                    </p>
                  }
                />
              )}
            {!isEditEnabled && campaignStrategyData ? (
              <CampaignStrategyReadMode
                organizationBudgetChannels={organizationBudgetChannels}
                campaignStrategyData={campaignStrategyData}
              />
            ) : (
              <CampaignStrategyEditMode
                organizationBudgetChannels={organizationBudgetChannels}
                setIsEditEnabled={setIsEditEnabled}
                campaignStrategyData={campaignStrategyData}
              />
            )}
          </div>

          <div className="flex">
            {!isEditEnabled && (
              <div className="ml-auto">
                <RoundedButton
                  onClick={handlePlanNext}
                  type="button"
                  isEnabled={!!selectedListing || !!campaignBudgetData}
                  className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto "
                  borderRadius="rounded-md"
                  text={
                    <p className=" text-sm text-white inline-flex items-center font-interRegular">
                      Next
                    </p>
                  }
                />
              </div>
            )}
          </div>
        </ContentContainer>
      )}
    </div>
  );
};

export default Strategy;
