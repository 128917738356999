import { StateCreator } from "zustand";
import { StoreState } from "..";

export interface AdvertiserSlice {
  selectedAdvertiser: number | null;
  setSelectedAdvertiser: (selectedAdvertiser: number | null) => void;
}

export const createAdvertiserSlice: StateCreator<
  StoreState,
  [],
  [],
  AdvertiserSlice
> = (set, get) => ({
  selectedAdvertiser: null,
  setSelectedAdvertiser: (selectedAdvertiser: number | null) => {
    set({ selectedAdvertiser });

    if (selectedAdvertiser !== null && selectedAdvertiser !== undefined) {
      get().setStep(0);
    }
  },
});
