import { useNavigate } from "react-router-dom";

import { useAppStore } from "../../store";

import ContentContainer from "../../components/contentContainer";
import RoundedButton from "../../components/roundedButton";
import { useAdvertiserSites, useWebsite } from "../../services/web-details";

function NoCampaignView() {
  const selectedAdvertiser = useAppStore((state) => state.selectedAdvertiser);
  const navigate = useNavigate();

  const { data: usersAllSites } = useAdvertiserSites(selectedAdvertiser);
  const { data: selectedSiteData } = useWebsite(usersAllSites?.data[0].id);

  return (
    <ContentContainer>
      <div className=" flex flex-1 flex-col  min-h-[36.125rem] max-h-[67rem] pt-[5.6rem] xs:px-2 md:px-6  lg:pl-9 lg:pr-20">
        <div className="flex flex-1 flex-col w-full space-y-8">
          <p className="text-sm text-primary font-interRegular">
            Welcome to{" "}
            <span className="font-interSemiBold mx-0.5">Sparkbird!</span>
          </p>
          <p className="text-sm text-primary font-interRegular">
            Your advertising journey starts here! Whether it's{" "}
            <span className="font-interSemiBold mx-0.5">
              connecting new customers to your business or building loyalty with
              existing ones,
            </span>
            this is your space to make an impact.
          </p>
          <div className="flex flex-col space-y-1.5">
            <p className="text-sm text-primary font-interRegular">
              Before you start, take a moment to:
            </p>
            <p className="text-sm text-primary font-interRegular">
              ✅
              <span
                onClick={() => {
                  navigate(`/advertiser/${selectedAdvertiser}`);
                }}
                className="cursor-pointer mx-1 font-interSemiBold text-colorBlue underline"
              >
                Review Your Advertiser Details
              </span>
              – Ensure your business info is up to date.
            </p>
            <p className="text-sm text-primary font-interRegular">
              ✅
              <span
                onClick={() => {
                  if (!selectedSiteData?.id) return;
                  navigate(`/websites/metadata/${selectedSiteData.id}`);
                }}
                className="cursor-pointer mx-1 font-interSemiBold text-colorBlue underline"
              >
                Check Your Website Summary
              </span>
              &
              <span
                onClick={() => {
                  if (!selectedSiteData?.id) return;
                  navigate(`/websites/assets/${selectedSiteData.id}`);
                }}
                className="cursor-pointer mx-1 font-interSemiBold text-colorBlue underline"
              >
                Media Assets
              </span>
              – These will help shape your campaign for the best results.
            </p>
          </div>
          <p className="font-interRegular text-sm text-primary">
            <span className="cursor-pointer mr-1 font-interSemiBold">
              Ready to take flight?
            </span>
            Click
            <span className="cursor-pointer mx-1 font-interSemiBold">
              “Propose a Campaign”
            </span>
            to get started, and you'll see a personalized campaign generated
            just for your business—tailored to captivate, engage, and deliver
            real results!
          </p>
          <div className="mt-10">
            <RoundedButton
              text={
                <p className=" text-sm text-white inline-flex items-center font-interRegular">
                  Propose a Campaign
                </p>
              }
              onClick={() => {
                navigate("/campaigns/wizard");
              }}
              bgColor="bg-[#3077aa]"
              borderRadius="rounded-lg"
              textColor="text-secondary"
              className=" mx-auto px-6 py-3 text-sm font-interSemiBold"
            />
          </div>
        </div>
      </div>
    </ContentContainer>
  );
}

export default NoCampaignView;
