import { useEffect } from "react";

import firebase from "../firebase";

import { useAppStore } from "../store";

function useAuthSubscriber() {
  const onAuthStateChanged = useAppStore((store) => store.onAuthStateChanged);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onAuthStateChanged);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  return null;
}

export default useAuthSubscriber;
