export function mapFirebaseErrCodes(errCode: string, defaultErrMsg: string) {
  switch (errCode) {
    case "auth/email-already-in-use":
      return "The email address is already in use by another account.";
    case "auth/email-already-exists":
      return "The email address is already in use by another account.";
    case "auth/invalid-display-name":
      return "Invalid display name value";
    case "auth/user-not-found":
      return "There is no existing user record corresponding to the credential";
    case "auth/too-many-requests":
      return "Too many unsuccessful attempts. Make sure you log into an existing account!";
    case "auth/invalid-email":
      return "The email address is invalid";
    case "auth/invalid-credential":
      return "The information you entered doesn't match our records. Please try again or click Forgot Password";
    case "auth/invalid-email-verified":
      return "The provided value for the emailVerified user property is invalid";
    default:
      return defaultErrMsg;
  }
}
