import { queryClient } from "../../App";

import { useMutation, useQuery } from "@tanstack/react-query";

import { request } from "../axios";

export interface IAssignAdvertiserReq {
  role: "ADVERTISER_ADMIN" | "BASIC";
  advertiserId: number | null;
  email: string;
  organizationId: number | null;
}

export interface IUnAssignAdvertiserReq {
  id: number;
  userId: string | null;
  role: "ADVERTISER_ADMIN" | "BASIC";
  advertiserId: number | null;
  organizationId: number | null;
}

export interface IUpdateUsersRoleRequestData {
  id: number;
  data: {
    role: string;
    advertiserId: number | null;
    organizationId: number | null;
  };
}

function assignRole(data: IAssignAdvertiserReq) {
  return request({
    url: "user-organization-advertiser-role/assign-roles/",
    method: "POST",
    data,
  });
}

export const useAssignRole = (advertiserId: number | null) => {
  return useMutation({
    mutationFn: assignRole,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["assignedUsersForAdvertiser", advertiserId],
      });
    },
  });
};

function unassignRole(data: IUnAssignAdvertiserReq) {
  return request({
    url: `user-organization-advertiser-role/unassign-roles/${data.id}`,
    method: "POST",
    data,
  });
}

export const useUnAssignRole = (advertiserId: number | null) => {
  return useMutation({
    mutationFn: unassignRole,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["assignedUsersForAdvertiser", advertiserId],
      });
    },
  });
};

/* all organization users by advertiser id  */
function getAllAdvertisersUsers(
  advertiserId: number | null,
  organizationId: number | null
) {
  return request({
    url: `/user-organization-advertiser-role/user-advertiser/${advertiserId}?organizationId=${organizationId}`,
    method: "GET",
  });
}

export const useAdvertisersUsers = (
  advertiserId: number | null,
  organizationId: number | null
) => {
  return useQuery({
    queryKey: ["assignedUsersForAdvertiser", advertiserId],
    queryFn: () => getAllAdvertisersUsers(advertiserId, organizationId),
    retry: 1,
  });
};

/* UPDATE USERS ROLE */
export function updateUsersRole({ id, data }: IUpdateUsersRoleRequestData) {
  return request({
    url: `user-organization-advertiser-role/update-roles/${id}`,
    method: "PATCH",
    data,
  });
}

export const useUpdateAssignedUsersRole = (advertiserId: number | null) => {
  return useMutation({
    mutationFn: updateUsersRole,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["assignedUsersForAdvertiser", advertiserId],
      });
    },
  });
};

/* GET USERS ROLE FOR SELECTED ADVERTISER */

// user-organization-advertiser-role/user-roles/14?organizationId=1

async function getUsersRole(
  advertiserId: number | null,
  organizationId: number | null
) {
  return request({
    url: `user-organization-advertiser-role/user-roles/${advertiserId}?organizationId=${organizationId}`,
    method: "GET",
  });
}

export const useUsersRoleForAdvertiser = (
  advertiserId: number | null,
  organizationId: number | null
) => {
  return useQuery({
    queryKey: ["usersRole", advertiserId],
    queryFn: () => getUsersRole(advertiserId, organizationId),
    retry: 0,
  });
};
