import { StateCreator } from "zustand";

export interface StepSlice {
  step: number;
  setStep: (step: number) => void;
}

export const createStepSlice: StateCreator<StepSlice, [], [], StepSlice> = (
  set,
  get
) => ({
  step: 0,
  setStep: (step: number) => set({ step }),
});
