import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";

import { useAppStore } from "../../../../store";
import { useShallow } from "zustand/shallow";

import CampaignConfirmationModal from "../../campaignConfirmationModal";

import ContentContainer from "../../../../components/contentContainer";
import RoundedButton from "../../../../components/roundedButton";

import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon-mui.svg";

import {
  useCampaignNote,
  useRequestCampaignChange,
  useUpdateCampaignNote,
} from "../../../../services/campaign-change-request";
import { useCampaignStrategyInfo } from "../../../../services/campaigns";

function Notes() {
  const navigate = useNavigate();

  const {
    campaignId,
    selectedAdvertiser,
    isConfirmationModalShown,
    setIsConfirmationModalShown,
  } = useAppStore(
    useShallow((state) => ({
      campaignId: state.campaignId,
      selectedAdvertiser: state.selectedAdvertiser,
      isConfirmationModalShown: state.isConfirmationModalShown,
      setIsConfirmationModalShown: state.setIsConfirmationModalShown,
    }))
  );

  const {
    mutateAsync: requestCampaignChange,
    isError: isRequestChangeFailed,
    isSuccess: isRequestChangeSuccess,
    isPending: isRequestChangePending,
  } = useRequestCampaignChange(campaignId);
  const {
    mutateAsync: updateCampaignNote,
    isError: hasUpdateCampaignNoteError,
    isSuccess: hasUpdateCampaignNoteSuccess,
    isPending: isUpdateCampaignNotePending,
  } = useUpdateCampaignNote(campaignId);

  const { data: campaignStrategyInfo } = useCampaignStrategyInfo(
    campaignId,
    selectedAdvertiser
  );

  const { data: campaignNote } = useCampaignNote(campaignId);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      changeRequest: campaignNote?.changeRequest ?? "",
    },
  });

  async function onSubmit(data: any) {
    const { changeRequest } = data;

    if (campaignNote) {
      const reqData = {
        id: campaignNote.id,
        changeRequest,
      };
      await updateCampaignNote(reqData);
      return;
    }

    await requestCampaignChange({
      campaignId,
      changeRequest,
    });
  }

  async function handleCampaignConfirmationModal(
    campaignStatus: string | undefined
  ) {
    if (campaignStatus === "active") {
      navigate("/campaigns");
      return;
    }

    if (campaignStatus === "draft") {
      setIsConfirmationModalShown(true);
    }
  }

  // useEffect(() => {
  //   if (isCampaignUpdated) {
  //     navigate("/campaigns");
  //     setIsCampaignActiveInfoModalShown(true);
  //     resetForm();
  //   }
  //   //eslint-disable-next-line
  // }, [isCampaignUpdated]);

  useEffect(() => {
    if (campaignNote) {
      setValue("changeRequest", campaignNote.changeRequest);
    }
    // eslint-disable-next-line
  }, [campaignNote?.changeRequest]);

  return (
    <>
      {isConfirmationModalShown && <CampaignConfirmationModal />}

      {campaignStrategyInfo && (
        <ContentContainer className="px-7 py-5">
          <div className="flex flex-col space-y-4">
            <div className="flex">
              <RoundedButton
                onClick={async () =>
                  handleCampaignConfirmationModal(
                    campaignStrategyInfo.campaignStatus
                  )
                }
                type="button"
                isEnabled={true}
                className="py-3 px-9 max-w-fit max-h-fit ml-auto"
                borderRadius="rounded-md"
                bgColor={`${
                  campaignStrategyInfo.campaignStatus !== "active"
                    ? "bg-[#49AC1B]"
                    : "bg-[#3077aa]"
                }`}
                text={
                  <p className=" text-sm text-white inline-flex items-center font-interRegular">
                    {campaignStrategyInfo.campaignStatus !== "active"
                      ? "Continue"
                      : campaignStrategyInfo.campaignStatus === "active" &&
                        "Go Back to Campaigns"}
                  </p>
                }
              />
            </div>
          </div>

          <hr className=" mt-1.5 mb-3" />

          <div className="flex flex-col mb-8">
            <div className=" mb-5">
              <p className="font-interRegular text-primary text-sm leading-6">
                The Notes page is your space to communicate key details,
                updates, or special requests about your campaign before it
                begins. Whether you have last-minute adjustments, specific
                messaging preferences, budget clarifications, or creative
                insights, this section ensures your sales rep has all the
                information needed to execute your vision effectively.
              </p>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (
                  isRequestChangePending ||
                  campaignStrategyInfo?.campaignStatus === "active"
                ) {
                  return;
                }

                handleSubmit(onSubmit)(e);
              }}
            >
              <div className="flex">
                <div className="flex flex-col">
                  <div className="flex">
                    <textarea
                      {...register("changeRequest", {
                        required: "This field is required.",
                        minLength: {
                          value: 10,
                          message: "This field must be at least 10 characters.",
                        },
                      })}
                      placeholder=""
                      className={`xs:w-full sm:w-[40rem] text-sm text-primary  ${
                        errors.changeRequest?.message
                          ? "border-[#d34638]"
                          : "border-[#40444f]"
                      } h-[12rem]   hide-scrollbar resize-none  focus:outline-none border-[1px] rounded-[0.625rem] py-3 px-3`}
                    />
                  </div>
                  <RoundedButton
                    className="py-2.5 px-4 max-w-fit mt-2"
                    type="submit"
                    borderRadius="rounded-md"
                    isEnabled={
                      !isRequestChangePending &&
                      campaignStrategyInfo.campaignStatus !== "active"
                    }
                    text={
                      <p className=" text-sm text-white inline-flex items-center font-interRegular">
                        <EditIcon className="text-white fill-current mr-2 text-2xl " />
                        {isRequestChangePending || isUpdateCampaignNotePending
                          ? "Saving..."
                          : "SAVE"}
                      </p>
                    }
                  />
                </div>
                <div className="flex flex-col">
                  {errors.changeRequest?.message && (
                    <p className="font-interSemiBold mt-2  ml-2 text-colorDelete  text-sm ">
                      {errors.changeRequest.message}
                    </p>
                  )}
                </div>
              </div>
              {isRequestChangeFailed ||
                (hasUpdateCampaignNoteError && (
                  <p className="text-sm font-interSemiBold mt-5 text-colorDelete">
                    Failed to request the change of campaign!
                  </p>
                ))}
              {(isRequestChangeSuccess || hasUpdateCampaignNoteSuccess) && (
                <div className="mt-2">
                  <p className="text-sm font-interRegular text-colorGreen">
                    Note saved. Your request will be sent when you click the
                    <span className="mx-1 font-interSemiBold">Continue</span>
                    button to submit the campaign button
                  </p>
                </div>
              )}
            </form>
          </div>
        </ContentContainer>
      )}
    </>
  );
}

export default Notes;
