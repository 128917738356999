import { StateCreator } from "zustand";

import { parseDomainBeforeVeloxi } from "../../utils/parseDomainBeforeVeloxi";

export interface IDomainSliceProps {
  domainName: string | null;
  initializeDomainName: () => void;
}

export const createDomainSlice: StateCreator<IDomainSliceProps> = (
  set,
  get
) => ({
  domainName: null,
  initializeDomainName: () => {
    if (get().domainName) return;

    const hostname = window.location.hostname;
    const tenant = parseDomainBeforeVeloxi(hostname);
    set({ domainName: tenant });
  },
});
