import React, { useEffect, useState } from "react";

import { useAppStore } from "../../../../store";

import CreativeDisplayMode from "./creativeDisplayMode";
import CreativeEditMode from "./creativeEditMode";

import ContentContainer from "../../../../components/contentContainer";
import TableImageModal from "../../../../components/modal/tableImageModal";
import ActivityIndicator from "../../../../components/activitySpinner";

import { IBannerBearImagesResponse } from "../../../../services/wizard/bannerbear";
import { useBudgetOrganizationChannels } from "../../../../services/advertiser/organization-budget-channel";
import { useDomainStyle } from "../../../../services/multitenacy-config";

type TAds = {
  adsFromEach: IBannerBearImagesResponse | undefined;
};

const Ads: React.FC<TAds> = ({ adsFromEach }) => {
  const domainName = useAppStore((store) => store.domainName);
  const { data: domainStyleData } = useDomainStyle(domainName);

  const {
    data: organizationBudgetChannels,
    isPending: isBudgetChannelPending,
  } = useBudgetOrganizationChannels(domainStyleData?.id ?? null, domainName);

  const formattedSelectionData = organizationBudgetChannels
    ?.map((channel) => {
      if (channel.budgetChannel !== "paidEmail") {
        return {
          value: channel.budgetChannel,
          label: channel.channelName,
        };
      }
      return null;
    })
    .filter((channel) => channel !== null);

  const [adCategoryselection, setAdCategorySelection] = useState<{
    label: string;
    value: "social" | "search" | "display" | "print";
  } | null>(null);

  const [previewedImage, setPreviewedImage] = useState<{
    id: number;
    imageUrl: string | null;
    templateName: string | null;
  } | null>(null);

  const [modalImage, setModalImage] = useState<string | null>(null);
  const [isEditEnabled, setIsEditEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (formattedSelectionData) {
      const displayCreatives = formattedSelectionData.filter(
        (data) => data?.value === "display"
      )[0];
      const defaultSelectedChannel =
        displayCreatives ?? formattedSelectionData[0];
      setAdCategorySelection(defaultSelectedChannel);
    }
    // eslint-disable-next-line
  }, [formattedSelectionData?.length]);

  useEffect(() => {
    if (adCategoryselection?.value && adsFromEach && formattedSelectionData) {
      const adCategoryKey = adCategoryselection?.value;
      const adCategoryBannerBearMap =
        adCategoryKey === "display"
          ? "displayAds"
          : adCategoryKey === "print"
          ? "printAds"
          : adCategoryKey === "social"
          ? "socialAds"
          : ("searchAds" as keyof IBannerBearImagesResponse);
      const ad = adsFromEach[adCategoryBannerBearMap];
      const selected = ad.find((imgObj) => imgObj.imageUrl !== null);

      if (selected) {
        setPreviewedImage({
          id: selected?.id,
          imageUrl: selected?.imageUrl,
          templateName: selected.templateName,
        });
      } else {
        setPreviewedImage({
          id: 1,
          imageUrl: null,
          templateName: null,
        });
      }
    }
    // eslint-disable-next-line
  }, [adCategoryselection?.value, formattedSelectionData?.length]);

  return (
    <>
      {modalImage && (
        <TableImageModal
          isOpen={modalImage}
          src={modalImage}
          onClose={() => setModalImage(null)}
        />
      )}

      {isBudgetChannelPending && <ActivityIndicator />}
      <ContentContainer className="p-7">
        {isEditEnabled ? (
          <CreativeEditMode setIsEditEnabled={setIsEditEnabled} />
        ) : (
          <CreativeDisplayMode
            isEditEnabled={isEditEnabled}
            setIsEditEnabled={setIsEditEnabled}
            adCategoryselection={adCategoryselection}
            adsFromEach={adsFromEach}
            formattedSelectionData={formattedSelectionData}
            isBudgetChannelPending={isBudgetChannelPending}
            previewedImage={previewedImage}
            setAdCategorySelection={setAdCategorySelection}
            setModalImage={setModalImage}
            setPreviewedImage={setPreviewedImage}
          />
        )}
      </ContentContainer>
    </>
  );
};

export default Ads;
