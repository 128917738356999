import { ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAppStore } from "../../store";
import { useShallow } from "zustand/shallow";

import ActivitySpinner from "../../components/activitySpinner";

import { useGetSelf } from "../../services/user";
import { useDomainStyle } from "../../services/multitenacy-config";

function PrivateRoute({ children }: { children: ReactNode }) {
  const {
    user,
    sessionToken,
    step,
    setStep,
    campaignId,
    setCampaignId,
    domainName,
  } = useAppStore(
    useShallow((state) => ({
      user: state.user,
      sessionToken: state.sessionToken,
      isCampaignActiveInfoModalShown: state.isCampaignActiveInfoModalShown,
      step: state.step,
      setStep: state.setStep,
      campaignId: state.campaignId,
      setCampaignId: state.setCampaignId,
      domainName: state.domainName,
    }))
  );

  const location = useLocation();

  const { data: userBEData } = useGetSelf(sessionToken);

  const { isPending } = useDomainStyle(domainName);

  useEffect(() => {
    if (location.pathname !== "/campaigns/wizard" && step > 0 && campaignId) {
      setCampaignId(undefined);
      setStep(0);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  if (!user) {
    return <Navigate replace to="/signin" />;
  }

  if (!userBEData || isPending) {
    return <ActivitySpinner />;
  }

  return <div>{children}</div>;
}

export default PrivateRoute;
