import MainLayout from "../../layouts/main";

import EditRole from "../../containers/profile/users/editRole";

function EditRoleScreen() {
  return (
    <MainLayout showSidebarLogo showSidebar>
      <EditRole />
    </MainLayout>
  );
}

export default EditRoleScreen;
