import { queryClient } from "../../App";

import { StateCreator } from "zustand";

import firebase from "../../firebase";

import { AdvertiserSlice } from "../advertiserSlice";

import { clearAxiosAuthHeader, setAxiosAuthHeader } from "../../services/axios";

import { removeCookies } from "../../utils/cookies";
import { mapFirebaseErrCodes } from "../../utils/mapFirebaseErrCodes";

import { IEmailResetRequest, SignUpFormData } from "../../interfaces";

export interface IAuthSliceProps {
  user: any;
  sessionToken?: string;
  isLoadingAuth: boolean;
  isSuccessAuth: boolean;
  isErrorAuth: boolean;
  errorAuthMessage: string | null;
  tokenRefreshed: boolean;
  setTokenRefreshed: (state: boolean) => void;
  isEmailResetRequest: IEmailResetRequest;
  setIsEmailResetRequest: (state: IEmailResetRequest) => void;
  onAuthStateChanged(user: any): Promise<void>;
  setFirebaseTokenAsAxiosAuthToken(reloadUser: boolean): Promise<void>;
  clearCookies(): void;
  beforeRequest(): void;
  successfulRequest(): void;
  erroneousRequest(errorAuthMessage?: string): void;
  clearAuthStates(): void;
  signIn(
    email: string,
    password: string,
    successCallback?: () => any
  ): Promise<void>;
  signUp(
    userForm: SignUpFormData,
    createNewUser: any,
    successCallback?: () => any
  ): Promise<void>;
  resetPassword(email: string): Promise<void>;
  updateUserPassword(
    email: string,
    currentPassword: string,
    newPassword: string
  ): Promise<void>;
  signOut(successCallback?: () => any): Promise<void>;
  resendVerificationEmail(successCallback?: () => any): Promise<void>;
}

export const createAuthSlice: StateCreator<
  IAuthSliceProps & AdvertiserSlice,
  [],
  [],
  IAuthSliceProps
> = (set, get) => ({
  user: null,
  sessionToken: undefined,
  isLoadingAuth: false,
  isSuccessAuth: false,
  isErrorAuth: false,
  errorAuthMessage: null,
  tokenRefreshed: false,
  isEmailResetRequest: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  setTokenRefreshed: (state) => set({ tokenRefreshed: state }),
  setIsEmailResetRequest: (state) => set({ isEmailResetRequest: state }),

  clearCookies() {
    removeCookies("chatSessionId");
  },

  beforeRequest() {
    set({
      isLoadingAuth: true,
      isSuccessAuth: false,
      isErrorAuth: false,
      errorAuthMessage: null,
    });
  },

  successfulRequest() {
    set({
      isLoadingAuth: false,
      isSuccessAuth: true,
      isErrorAuth: false,
      errorAuthMessage: null,
    });
  },

  erroneousRequest(errorAuthMessage?: string) {
    set({
      isLoadingAuth: false,
      isSuccessAuth: false,
      isErrorAuth: true,
      errorAuthMessage: errorAuthMessage ?? "Something went wrong",
    });
  },

  clearAuthStates() {
    set({
      isLoadingAuth: false,
      isSuccessAuth: false,
      isErrorAuth: false,
      errorAuthMessage: null,
    });
  },

  async onAuthStateChanged(user: any) {
    if (user) {
      set({ user });
      // Refresh Firebase Token
      await get().setFirebaseTokenAsAxiosAuthToken(false);
    } else {
      get().clearCookies();
      set({ user: null });
      await queryClient.refetchQueries({ queryKey: ["domainStyle"] });

      queryClient
        .getQueryCache()
        .getAll()
        .forEach((query) => {
          if (query.queryKey[0] !== "domainStyle") {
            queryClient.removeQueries({ queryKey: query.queryKey });
          }
        });

      localStorage.clear();
      clearAxiosAuthHeader();
    }
  },

  async setFirebaseTokenAsAxiosAuthToken(reloadUser: boolean) {
    try {
      if (!firebase.auth().currentUser) return;

      if (reloadUser) {
        await firebase.auth()?.currentUser?.reload();
      }

      const token = await firebase.auth()?.currentUser?.getIdToken(reloadUser);
      if (!token) return;

      setAxiosAuthHeader(token);
      set({ sessionToken: token });
    } catch (error) {
      console.error("Auth token could not be refreshed:", error);
    }
  },

  async signIn(email: string, password: string, successCallback?: () => any) {
    try {
      set({
        isLoadingAuth: true,
        isSuccessAuth: false,
        isErrorAuth: false,
        errorAuthMessage: null,
      });

      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const token = await userCredential?.user?.getIdToken();

      set({
        user: userCredential.user,
        sessionToken: token,
        isLoadingAuth: false,
        isSuccessAuth: true,
        isErrorAuth: false,
        errorAuthMessage: null,
      });

      successCallback?.();
    } catch (error: any) {
      set({
        isLoadingAuth: false,
        isSuccessAuth: false,
        isErrorAuth: true,
        errorAuthMessage: mapFirebaseErrCodes(error?.code, error?.message),
      });
    }
  },

  async signUp(
    userForm: SignUpFormData,
    createNewUser: any,
    successCallback?: () => any
  ) {
    try {
      set({
        isLoadingAuth: true,
        isSuccessAuth: false,
        isErrorAuth: false,
        errorAuthMessage: null,
      });

      const { email, password, ...rest } = userForm;
      const { user } = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      await user?.sendEmailVerification();
      createNewUser({ email, ...rest });

      set({
        isLoadingAuth: false,
        isSuccessAuth: true,
        isErrorAuth: false,
        errorAuthMessage: null,
      });

      // successCallback?.();
    } catch (error: any) {
      set({
        isLoadingAuth: false,
        isSuccessAuth: false,
        isErrorAuth: true,
        errorAuthMessage: mapFirebaseErrCodes(error?.code, error?.message),
      });
    }
  },

  async resetPassword(email: string) {
    try {
      set({ isLoadingAuth: true });

      await firebase.auth().sendPasswordResetEmail(email);

      set({
        isLoadingAuth: false,
        isSuccessAuth: true,
        isErrorAuth: false,
        errorAuthMessage: null,
      });
    } catch (error: any) {
      set({
        isLoadingAuth: false,
        isSuccessAuth: false,
        isErrorAuth: true,
        errorAuthMessage: error?.message,
      });
    }
  },

  async updateUserPassword(
    email: string,
    currentPassword: string,
    newPassword: string
  ) {
    try {
      set({ isLoadingAuth: true });

      const credential = firebase.auth.EmailAuthProvider.credential(
        email,
        currentPassword
      );
      await firebase
        .auth()
        .currentUser?.reauthenticateWithCredential(credential);
      await firebase.auth().currentUser?.updatePassword(newPassword);

      set({
        isLoadingAuth: false,
        isSuccessAuth: true,
        isErrorAuth: false,
        errorAuthMessage: null,
      });
    } catch (error: any) {
      set({
        isLoadingAuth: false,
        isSuccessAuth: false,
        isErrorAuth: true,
        errorAuthMessage: error?.message,
      });
    }
  },

  async signOut(successCallback?: () => any) {
    set({ isLoadingAuth: true });

    try {
      await queryClient.refetchQueries({ queryKey: ["domainStyle"] });

      queryClient
        .getQueryCache()
        .getAll()
        .forEach((query) => {
          if (query.queryKey[0] !== "domainStyle") {
            queryClient.removeQueries({ queryKey: query.queryKey });
          }
        });

      await firebase.auth().signOut();

      set({
        sessionToken: undefined,
        selectedAdvertiser: null,
        isLoadingAuth: false,
        isSuccessAuth: true,
        isErrorAuth: false,
      });

      successCallback?.();
    } catch (error: any) {
      set({
        isLoadingAuth: false,
        isSuccessAuth: false,
        isErrorAuth: true,
        errorAuthMessage: error?.message,
      });
    }
  },

  async resendVerificationEmail(successCallback?: () => any) {
    try {
      set({ isLoadingAuth: true });

      await firebase.auth().currentUser?.sendEmailVerification();

      set({
        isLoadingAuth: false,
        isSuccessAuth: true,
        isErrorAuth: false,
        errorAuthMessage: null,
      });

      successCallback?.();
    } catch (error: any) {
      set({
        isLoadingAuth: false,
        isSuccessAuth: false,
        isErrorAuth: true,
        errorAuthMessage: mapFirebaseErrCodes(error?.code, error?.message),
      });
    }
  },
});
