import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { SubmitHandler, useForm } from "react-hook-form";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ControlledSelect from "../controlledSelect";

import Input from "../../components/input/inputWithToggle";
import ContentContainer from "../../components/contentContainer";
import ImageUpload from "../../components/profileEditImage";
import RoundedButton from "../../components/roundedButton";
import TopContent from "../../components/topContent";
import InputWithToggle from "../../components/input/inputWithToggle";

import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon-mui.svg";

import { useAdvertiser, useUpdateAdvertiser } from "../../services/advertiser";
import { useUserIndustry } from "../../services/industry";
import {
  useAdvertiserFields,
  useCustomFieldsValues,
  useUpdateCustomFields,
} from "../../services/advertiser/advertiserCustomFields";
import { useAdvertiserTones } from "../../services/advertiser/tone";

import {
  IAdvertiserUpdateCustomFieldsRequest,
  IUpdateAdvertiserDataRequest,
} from "../../interfaces";

import { tooltipTexts } from "../../constants/tooltipTexts";

const SingleAdvertiser = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const idToNumber = id ? parseInt(id, 10) : null;

  const [customFieldsSourceMap, setCustomFieldsSourceMap] = useState<
    Map<number, "INDUSTRY" | "ORGANIZATION">
  >(new Map());

  const {
    register,
    setValue,
    handleSubmit: handleSubmitBaseFields,
    formState: { errors: errorsBaseFields },
    watch,
    trigger: triggerBaseFields,
  } = useForm<IUpdateAdvertiserDataRequest>();
  const editFormData = watch();

  const {
    register: registerCustomFields,
    setValue: setValueCustomFields,
    handleSubmit: handleSubmitCustomFields,
    formState: { errors: errorsCustomFields },
    watch: watchCustomFieldsForm,
    trigger: triggerCustomFields,
  } = useForm();
  const customFieldsFormData = watchCustomFieldsForm();

  const { data: advertiserData } = useAdvertiser(idToNumber);
  const { data: industry } = useUserIndustry(
    advertiserData?.industryId,
    advertiserData?.id
  );
  const { data: industryFields } = useAdvertiserFields(advertiserData?.id);
  const {
    mutateAsync: updateAdvertiser,
    isPending: isUpdatingAdvertiser,
    isError: isUpdatingAdvertiserError,
    isSuccess: isBaseAdvertiserInfoUpdated,
  } = useUpdateAdvertiser(advertiserData?.id);

  const {
    mutateAsync: updateCustomFieldValues,
    isPending: isUpdatingCustomFields,
    isSuccess: isCustomFieldsUpdated,
  } = useUpdateCustomFields(advertiserData?.id);
  const { data: customFieldsValuesData } = useCustomFieldsValues(
    advertiserData?.id
  );

  const { data: TONES, isPending: isFetchingTones } = useAdvertiserTones();

  const formattedTones = TONES?.map((tone) => {
    return {
      value: tone.tone,
      label: tone.tone,
    };
  });

  const submitHandlerBaseFields = useRef<() => void>();
  const submitHandlerCustomFields = useRef<() => void>();

  const onSubmitBaseFields: SubmitHandler<
    IUpdateAdvertiserDataRequest
  > = async (data) => {
    const toneValue: string = editFormData.tone?.value;

    const args = {
      advertiserId: advertiserData?.id,
      data: { ...data, tone: toneValue },
    };

    await updateAdvertiser(args);
  };

  const onSubmitCustomFields: SubmitHandler<any> = async (data) => {
    const isEmpty = Object.keys(customFieldsFormData).length === 0;
    if (isEmpty) return;

    let reqData: IAdvertiserUpdateCustomFieldsRequest = {
      advertiserId: advertiserData?.id,
      advertiserInformation: [],
    };

    const customFieldsValuesMap = new Map<
      number,
      { id?: number; value: string }
    >(
      customFieldsValuesData.map((cf: any) => [
        cf.customFieldId,
        { id: cf.id, value: cf.value },
      ])
    );

    const dataFormatted: Array<{
      id?: number;
      customFieldId: number;
      value: string;
      source: "INDUSTRY" | "ORGANIZATION";
    }> = Object.entries(data).map(([key, value]) => {
      const customFieldId = parseInt(key);
      const existingEntry = customFieldsValuesMap.get(customFieldId);

      return {
        customFieldId,
        value: String(value),
        source: customFieldsSourceMap.get(customFieldId) || "INDUSTRY",
        id: existingEntry?.id,
      };
    });

    reqData.advertiserInformation = [...dataFormatted];

    // console.log("reqData:", reqData);

    await updateCustomFieldValues(reqData);
  };

  submitHandlerBaseFields.current = () =>
    handleSubmitBaseFields(onSubmitBaseFields)();
  submitHandlerCustomFields.current = () =>
    handleSubmitCustomFields(onSubmitCustomFields)();

  async function onSubmitBothForms() {
    const isBaseFieldsValid = await triggerBaseFields();
    const isCustomFieldsValid = await triggerCustomFields();

    if (!isBaseFieldsValid) {
      return;
    }

    if (!isCustomFieldsValid) {
      return;
    }

    submitHandlerBaseFields.current?.();
    submitHandlerCustomFields.current?.();
  }

  function isSubsetKey(key: any): key is keyof IUpdateAdvertiserDataRequest {
    return [
      "photoUrl",
      "displayName",
      "email",
      "phoneNumber",
      "tone",
      "industryId",
    ].includes(key);
  }

  useEffect(() => {
    if (advertiserData) {
      Object.entries(advertiserData).map(([key, value]) => {
        if (isSubsetKey(key)) {
          if (key === "tone") {
            const toneDefaultObj = { value: value, label: value };
            setValue("tone", toneDefaultObj);
          } else {
            setValue(key, value);
          }
        }
        return null;
      });
    }
    //eslint-disable-next-line
  }, [advertiserData]);

  useEffect(() => {
    if (!customFieldsValuesData || !industryFields) return;

    const customFieldsValuesMap = new Map<
      number,
      { value: string; source: "INDUSTRY" | "ORGANIZATION" }
    >();

    industryFields.forEach((field) => {
      const matchedField = customFieldsValuesData.find(
        (cf: any) => cf.customFieldId === field.customFieldId
      );

      const value = matchedField ? matchedField.value : "";
      const source = field.source; // Always take source from industryFields

      customFieldsValuesMap.set(field.customFieldId, { value, source });

      setValueCustomFields(String(field.customFieldId), value);
    });

    setCustomFieldsSourceMap(
      new Map(
        Array.from(customFieldsValuesMap.entries()).map(
          ([customFieldId, data]) => [customFieldId, data.source]
        )
      )
    );

    // eslint-disable-next-line
  }, [customFieldsValuesData, industryFields]);

  useEffect(() => {
    if (isBaseAdvertiserInfoUpdated || isCustomFieldsUpdated) {
      toast.success("Successfully saved!");
      setTimeout(() => {
        navigate("/advertiser");
      }, 800);
    }
    // eslint-disable-next-line
  }, [isCustomFieldsUpdated, isBaseAdvertiserInfoUpdated]);

  return (
    <div className="flex flex-col">
      <TopContent
        title="Advertiser Profile"
        subTitle="Enter the advertiser's details below to begin tailoring their unique marketing strategy. Information here will also be used in creative"
      />
      {advertiserData && (
        <>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            newestOnTop={false}
            closeOnClick
            hideProgressBar={false}
            theme="light"
            transition={Bounce}
          />
          <ContentContainer>
            <div className=" flex flex-1 flex-col p-12 pb-6 ">
              <div className=" flex flex-1 flex-col space-y-5 justify-between mb-12 items-center sm:items-stretch">
                <form autoComplete="off">
                  <div className="flex flex-1 flex-col w-[600px] space-y-6">
                    <ImageUpload
                      register={register}
                      inputName="photoUrl"
                      photoUrl={editFormData.photoUrl}
                      setValue={setValue}
                    />
                    <div className="grid flex-1 xs:gap-x-0 sm:gap-x-3 md:gap-x-6 xs:grid-cols-1 sm:grid-cols-1  gap-y-4">
                      <div className="flex flex-col">
                        <Input
                          type="text"
                          name="displayName"
                          label="Name (Appears on ad)"
                          register={register}
                          requiredMessage="Name is required"
                          validation={errorsBaseFields.displayName?.message}
                          reactHooksFormEnabled={true}
                          validationClassName="mt-0 text-sm ml-2"
                          className={`${
                            errorsBaseFields.displayName?.message &&
                            "border-[#D34638] "
                          }   `}
                        />
                      </div>

                      <Input
                        type="text"
                        name="email"
                        label="Email (Appears on ad)"
                        register={register}
                        requiredMessage="Email is required"
                        pattern={{
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Entered value does not match email format",
                        }}
                        validation={errorsBaseFields.email?.message}
                        reactHooksFormEnabled={true}
                        validationClassName="mt-0 text-sm ml-2"
                        className={`${
                          errorsBaseFields.email?.message && "border-[#D34638] "
                        }   `}
                      />
                      <Input
                        type="text"
                        name="phoneNumber"
                        label="Phone (Appears on ad)"
                        register={register}
                        requiredMessage="Phone Number required"
                        validation={errorsBaseFields.phoneNumber?.message}
                        reactHooksFormEnabled={true}
                        validationClassName="mt-0 text-sm ml-2"
                        className={`${
                          errorsBaseFields.phoneNumber?.message &&
                          "border-[#D34638] "
                        }   `}
                      />
                      <ControlledSelect
                        labelName="Brand Voice"
                        tooltipBody={tooltipTexts.BRAND_VOICE}
                        tooltipClassName="cursor-pointer ml-1 h-4 w-4 mt-0.5"
                        data={formattedTones}
                        isPending={isFetchingTones}
                        value={editFormData.tone}
                        register={register}
                        registerFieldName="tone"
                        requiredMessage="Brand Voice is required"
                        isMulti={false}
                        extractValueOnly={false}
                        setValue={setValue}
                        errorFieldMsg={errorsBaseFields.tone?.message}
                      />
                      {industry && (
                        <InputWithToggle
                          label="Industry"
                          name="industry"
                          placeholder="Type to filter the list..."
                          requiredMessage="Industry is required"
                          type="text"
                          value={industry.name}
                          disabled={true}
                        />
                      )}
                    </div>

                    {isUpdatingAdvertiserError && (
                      <p className="mt-5 pl-2 font-ManropeRegular text-sm text-red-500">
                        Failed to update fields.Please try again!
                      </p>
                    )}
                  </div>
                </form>
              </div>

              <div className=" flex flex-1 flex-col  ">
                {industryFields && industryFields.length !== 0 && (
                  <form autoComplete="off">
                    <div className="grid items-start xs:gap-x-0 sm:gap-x-3 w-[600px] md:gap-x-6 xs:grid-cols-1 sm:grid-cols-1 grid-flow-row  gap-y-6">
                      {industryFields.map((fields) => {
                        if (
                          fields.customFieldName
                            .toLowerCase()
                            .includes("describe") ||
                          fields.customFieldName
                            .toLowerCase()
                            .includes("overview")
                        ) {
                          return (
                            <div
                              key={fields.customFieldId}
                              className="flex flex-col order-1"
                            >
                              <label className="font-interRegular text-sm text-secondary mb-1">
                                {fields.customFieldName}
                              </label>
                              <div className="flex">
                                <div className="flex">
                                  <textarea
                                    className={`xs:w-[18.75rem] sm:w-[27.5rem] text-sm text-primary  ${
                                      errorsCustomFields[
                                        String(fields.customFieldId)
                                      ]?.message
                                        ? "border-[#d34638]"
                                        : "border-[#40444f]"
                                    } h-[12rem]   hide-scrollbar resize-none  focus:outline-none border-[1px] rounded-[0.625rem] py-3 px-3`}
                                    {...registerCustomFields(
                                      String(fields.customFieldId),
                                      { required: "required" }
                                    )}
                                  />
                                </div>
                                {errorsCustomFields[
                                  String(fields.customFieldId)
                                ]?.message && (
                                  <p className="font-interSemiBold  ml-2 text-colorDelete mt-0 text-sm ">
                                    {fields.customFieldName} is required
                                  </p>
                                )}
                              </div>
                            </div>
                          );
                        }

                        return (
                          <Input
                            key={fields.customFieldId}
                            type="text"
                            name={String(fields.customFieldId)}
                            requiredMessage={`${fields.customFieldName} is required`}
                            validation={
                              errorsCustomFields[String(fields.customFieldId)]
                                ?.message
                            }
                            label={fields.customFieldName}
                            register={registerCustomFields}
                            reactHooksFormEnabled={true}
                            validationClassName="mt-0 text-sm ml-2"
                            className={`${
                              errorsCustomFields[String(fields.customFieldId)]
                                ?.message && "border-[#D34638] "
                            }   `}
                          />
                        );
                      })}
                    </div>
                  </form>
                )}
                <div className="flex pt-10 items-center">
                  <RoundedButton
                    className="py-2.5 px-4"
                    borderRadius="rounded-md"
                    text={
                      <p className=" text-sm text-white inline-flex items-center font-interRegular">
                        <EditIcon className="text-white fill-current mr-2 text-2xl " />
                        {isUpdatingAdvertiser || isUpdatingCustomFields
                          ? "Saving..."
                          : "SAVE"}
                      </p>
                    }
                    onClick={onSubmitBothForms}
                  />
                  <p
                    onClick={() => navigate(-1)}
                    className="text-sm cursor-pointer font-interRegular ml-7 text-colorBlue"
                  >
                    Cancel
                  </p>
                </div>
              </div>
            </div>
          </ContentContainer>
        </>
      )}
    </div>
  );
};

export default SingleAdvertiser;

/*
  useEffect(() => {
    customFieldsValuesData?.map((customField: any) => {
      industryFields?.map((field) => {
        if (customField.customFieldId === field.customFieldId) {
          setValueCustomFields(String(field.customFieldId), customField.value);
        }
        return null;
      });
      return null;
    });
    //eslint-disable-next-line
  }, [customFieldsValuesData, industryFields]);
  */
