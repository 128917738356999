import React, { SetStateAction, useEffect, useState } from "react";

import { Bounce, toast, ToastContainer } from "react-toastify";

import { useAppStore } from "../../../../../store";

import CopyList from "./copyList";
import CopyEdit from "./copyEdit";

import RoundedButton from "../../../../../components/roundedButton";

import { useCreativeImageCopy } from "../../../../../services/image-copy";
import { useUpdateCopySuggestion } from "../../../../../services/image-copy/suggestion";

const CreativeEditMode = ({
  setIsEditEnabled,
}: {
  setIsEditEnabled: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const campaignId = useAppStore((store) => store.campaignId);

  const { data: creativeCopyData } = useCreativeImageCopy(campaignId);
  const {
    mutateAsync: updateCopyCreative,
    isError: isFailedToUpdateCopy,
    isPending: isUpdatingCopy,
    isSuccess: isCopyUpdated,
  } = useUpdateCopySuggestion(campaignId);

  const [requestedCopyData, setRequestedCopyData] = useState<{
    id: number;
    copy: string;
  } | null>(null);

  const [creativeEditMode, setCreativeEditMode] = useState<
    "copyList" | "copyEdit"
  >("copyList");

  useEffect(() => {
    if (isCopyUpdated) {
      setCreativeEditMode("copyList");
      toast.success("Successfully updated the copy!");
    }
  }, [isCopyUpdated]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        newestOnTop={true}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      {creativeCopyData && creativeCopyData.length === 0 && (
        <div className="h-[20rem]">
          <p className="text-sm text-primary font-interSemiBold">
            No data found
          </p>
        </div>
      )}

      {creativeCopyData &&
        creativeCopyData.length > 0 &&
        (creativeEditMode === "copyList" ? (
          <CopyList
            setCreativeEditMode={setCreativeEditMode}
            setRequestedCopyData={setRequestedCopyData}
            creativeCopyData={creativeCopyData}
          />
        ) : (
          <CopyEdit
            requestedCopyData={requestedCopyData}
            setCreativeEditMode={setCreativeEditMode}
            isUpdatingCopy={isUpdatingCopy}
            isFailedToUpdateCopy={isFailedToUpdateCopy}
            updateCopyCreative={updateCopyCreative}
          />
        ))}

      {creativeEditMode !== "copyEdit" && (
        <RoundedButton
          onClick={() => setIsEditEnabled(false)}
          type="button"
          className="py-3 px-9 max-w-fit max-h-fit mt-7 mr-5"
          borderRadius="rounded-md"
          bgColor="#FFFFFF"
          borderColor="#C1C9D2"
          text={
            <p className="uppercase text-sm text-primary inline-flex items-center font-interRegular">
              Back
            </p>
          }
        />
      )}
    </>
  );
};

export default CreativeEditMode;
