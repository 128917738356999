import MainLayout from "../../../layouts/main";

function AdvertiserBusinessProfileEdit() {
  return (
    <MainLayout showSidebar showSidebarLogo>
      <p>edit</p>
    </MainLayout>
  );
}

export default AdvertiserBusinessProfileEdit;
