import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { useAppStore } from "../store";
import { useShallow } from "zustand/shallow";

import NavigationListener from "../components/navigationListener";

import PrivateRoute from "../containers/privateRoute";

import Campaign from "../pages/campaigns";
import CampaignDetails from "../pages/campaigns/campaignDetails";
import Wizard from "../pages/wizard/index";
import Profile from "../pages/profile";
import Invite from "../pages/profile/invite";
import EditRoleScreen from "../pages/profile/editRole";
import ProfileUsers from "../pages/profile/users";
import Login from "../pages/login";
import SignUpPage from "../pages/signUp";
import Websites from "../pages/websites";
import WebsiteDetails from "../pages/websites/websiteDetails";
import WebsiteAssets from "../pages/websites/websiteAssets";
import AddAsset from "../pages/websites/websiteAssets/addAsset";
import AddWebSite from "../pages/websites/addWebsite";
import ForgetPassword from "../pages/forgotPassword";
import Advertiser from "../pages/advertiser";
import AddAdvertiser from "../pages/advertiser/addAdvertiser";
import SingleAdvertiser from "../pages/advertiser/editAdvertiser";
// import AdvertiserBusinessProfileScreen from "../pages/advertiser/businessProfile";
import AdvertiserBusinessProfileEditScreen from "../pages/advertiser/businessProfile/edit";
import Insights from "../pages/insights";
import Notifications from "../pages/notifications";
import Help from "../pages/help";
import TermsAndServices from "../pages/termsOfService";
import UnauthorizedScreen from "../pages/unauthorized";
import ForbiddenScreen from "../pages/forbidden";
import NotFoundScreen from "../pages/notFound";

import { axios } from "../services/axios";

import { ScrollToTop } from "../utils/scrollToTop";

export const RootNavigator = () => {
  const {
    user,
    tokenRefreshed,
    setFirebaseTokenAsAxiosAuthToken,
    clearAuthStates,
  } = useAppStore(
    useShallow((state) => ({
      user: state.user,
      tokenRefreshed: state.tokenRefreshed,
      setFirebaseTokenAsAxiosAuthToken: state.setFirebaseTokenAsAxiosAuthToken,
      clearAuthStates: state.clearAuthStates,
    }))
  );

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const tokenExpired =
          error?.response?.data?.error?.moreInfo?.code ===
          "auth/id-token-expired";
        tokenExpired && setFirebaseTokenAsAxiosAuthToken(true);
        return Promise.reject(error);
      }
    );
    return () => axios.interceptors.response.eject(interceptor);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const hasUnauthorizedErrorCode = error?.response?.status === 401;
        const hasForbiddenErrorCode = error?.response?.status === 403;

        if (window.location.pathname === "/401") {
          return;
        }
        if (window.location.pathname === "/403") {
          return;
        }
        if (hasForbiddenErrorCode) {
          window.location.href = "/403";
        }
        if (hasUnauthorizedErrorCode) {
          window.location.href = "/401";
        }
        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <NavigationListener clearAuthStates={clearAuthStates} />
      <Routes>
        <Route path="/401" element={<UnauthorizedScreen />} />
        <Route path="/403" element={<ForbiddenScreen />} />
        <Route
          path="/signin"
          element={user ? <Navigate to="/" /> : <Login />}
        />
        <Route path="/terms-of-service" element={<TermsAndServices />} />
        <Route
          path="/reset-password"
          element={user ? <Navigate to="/" /> : <ForgetPassword />}
        />
        <Route
          path="/signup"
          element={
            user && tokenRefreshed ? <Navigate to="/" /> : <SignUpPage />
          }
        />
        <Route path="/" element={<Navigate to="/campaigns" replace />} />
        <Route
          path="/campaigns"
          index
          element={
            <PrivateRoute>
              <Campaign />
            </PrivateRoute>
          }
        />
        <Route
          path="/campaigns/campaignDetails/:campaignId"
          element={
            <PrivateRoute>
              <CampaignDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="/campaigns/wizard"
          element={
            <PrivateRoute>
              <Wizard />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/users"
          element={
            <PrivateRoute>
              <ProfileUsers />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/users/invite"
          element={
            <PrivateRoute>
              <Invite />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/users/editRole/:id"
          element={
            <PrivateRoute>
              <EditRoleScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="/websites"
          element={
            <PrivateRoute>
              <Websites />
            </PrivateRoute>
          }
        />
        <Route
          path="/websites/metadata/:id"
          element={
            <PrivateRoute>
              <WebsiteDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/websites/assets/:id"
          element={
            <PrivateRoute>
              <WebsiteAssets />
            </PrivateRoute>
          }
        />
        <Route
          path="/websites/assets/:id/addAsset"
          element={
            <PrivateRoute>
              <AddAsset />
            </PrivateRoute>
          }
        />
        <Route
          path="/websites/addWebsite"
          element={
            <PrivateRoute>
              <AddWebSite />
            </PrivateRoute>
          }
        />

        <Route
          path="/advertiser"
          element={
            <PrivateRoute>
              <Advertiser />
            </PrivateRoute>
          }
        />
        <Route
          path="/advertiser/addAdvertiser"
          element={
            <PrivateRoute>
              <AddAdvertiser />
            </PrivateRoute>
          }
        />
        <Route
          path="/advertiser/:id"
          element={
            <PrivateRoute>
              <SingleAdvertiser />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/advertiser/businessProfile/:id"
          element={
            <PrivateRoute>
              <AdvertiserBusinessProfileScreen />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/advertiser/businessProfile/:id/edit/:businessInfoId"
          element={
            <PrivateRoute>
              <AdvertiserBusinessProfileEditScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="/insights"
          element={
            <PrivateRoute>
              <Insights />
            </PrivateRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <PrivateRoute>
              <Notifications />
            </PrivateRoute>
          }
        />
        <Route
          path="/help"
          element={
            <PrivateRoute>
              <Help />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RootNavigator;
