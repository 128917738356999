import { useAppStore } from "../../store";

interface ContentContainerProps {
  children: React.ReactNode;
  className?: string;
}

const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  className,
}) => {
  const isSidebarOpen = useAppStore((store) => store.isSidebarOpen);

  return (
    <div
      className={`bg-white border border-solid border-[#E5E9EB] max-w-[80rem] my-8 ${
        isSidebarOpen ? "ml-8" : "ml-[5.5rem] "
      }  mr-8  rounded-md flex flex-col   ${className}`}
    >
      <div className="flex flex-col border-[#F9F3F0] ">{children}</div>
    </div>
  );
};

export default ContentContainer;
