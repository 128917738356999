import React, { useEffect, useMemo } from "react";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAppStore } from "../../store";
import { useShallow } from "zustand/shallow";

import ContentContainer from "../../components/contentContainer";
import TopContent from "../../components/topContent";
import RoundedButton from "../../components/roundedButton";

import { ProfileContent } from "../../interfaces";

interface DisplayProfileProps {
  data: {
    displayName: string;
    email: string;
    phoneNumber: string;
    role: string;
  };
  reset: () => void;
  onClickEdit: (content: ProfileContent) => void;
  isEditUserSuccess: boolean;
}

const DisplayProfile: React.FC<DisplayProfileProps> = ({
  data,
  reset,
  onClickEdit,
  isEditUserSuccess,
}) => {
  const { user, isSuccess, isError, isLoading, resendVerificationEmail } =
    useAppStore(
      useShallow((state) => ({
        user: state.user,
        isSuccess: state.isSuccessAuth,
        isError: state.isErrorAuth,
        isLoading: state.isLoadingAuth,
        resendVerificationEmail: state.resendVerificationEmail,
      }))
    );

  const isEmailVerified = useMemo(() => user?.emailVerified, [user]);

  useEffect(() => {
    if (isEditUserSuccess) {
      toast.success("User fields updated successfully!");
      reset();
    }
    // eslint-disable-next-line
  }, [isEditUserSuccess]);

  return (
    <div className="flex flex-col">
      <TopContent title="User Profile" subTitle="" />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      <ContentContainer className="p-8 h-[70vh] max-h-[50rem]">
        <div className="flex ml-auto ">
          <RoundedButton
            onClick={() => onClickEdit("changePassword")}
            type="button"
            className="py-3 px-9 mr-8 max-w-fit max-h-fit ml-auto "
            borderRadius="rounded-md"
            text={
              <p className=" text-sm uppercase text-white inline-flex items-center font-interRegular">
                Change Password
              </p>
            }
          />
          <RoundedButton
            onClick={() => onClickEdit("editProfile")}
            type="button"
            className="py-3 px-9  max-w-fit max-h-fit ml-auto"
            borderRadius="rounded-md"
            text={
              <p className=" text-sm uppercase text-white inline-flex items-center font-interRegular">
                Edit
              </p>
            }
          />
        </div>
        <div className="flex flex-col space-y-5 mt-5">
          <p className="text-sm font-interSemiBold text-primary">
            Name:
            <span className="text-sm ml-1.5 font-interRegular text-primary">
              {data.displayName}
            </span>
          </p>
          <p className="text-sm font-interSemiBold text-primary">
            Email:
            <span className="text-sm ml-1.5 font-interRegular text-primary">
              {data.email}
            </span>
          </p>
        </div>
        <div className="flex flex-col mt-4">
          {!isEmailVerified && (
            <p className="text-sm  font-interSemiBold  text-colorDelete">
              We sent a verification email to your inbox. If you didn’t receive
              it, click below to resend.
            </p>
          )}
          {!isEmailVerified && (
            <div className="flex items-center">
              <p
                onClick={async () => {
                  if (isLoading || isSuccess) return;
                  resendVerificationEmail();
                }}
                className={`text-sm ${
                  isLoading || (isSuccess && "opacity-50")
                } underline cursor-pointer font-interSemiBold text-primary`}
              >
                Resend notification email
              </p>
              {isLoading && (
                <div className="circle-spinner-blue w-4 h-4 ml-3"></div>
              )}
              {isSuccess && (
                <p className="text-sm text-colorGreen font-interSemiBold ml-3">
                  Verification mail successfully sent!
                </p>
              )}
              {isError && (
                <p className="text-sm text-colorDelete font-interSemiBold ml-3">
                  Something went wrong!
                </p>
              )}
            </div>
          )}
        </div>
      </ContentContainer>
    </div>
  );
};

export default DisplayProfile;
