import { useState } from "react";

import MainLayout from "../../layouts/main";

import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";

import { useAppStore } from "../../store";
import { useShallow } from "zustand/shallow";

import EditProfile from "../../containers/profile/editProfile";
import DisplayProfile from "../../containers/profile";
import ChangePassword from "../../containers/profile/changePassword";

import { useGetSelf, useUpdateSelf } from "../../services/user";

import { ProfileContent } from "../../interfaces";

const Profile = () => {
  const {
    isUpdateUserPasswordSuccess,
    isUpdateUserPasswordError,
    updateUserPasswordErrorMessage,
    updateUserPassword,
    isUpdateUserPasswordLoading,
  } = useAppStore(
    useShallow((state) => ({
      isUpdateUserPasswordSuccess: state.isSuccessAuth,
      isUpdateUserPasswordError: state.isErrorAuth,
      updateUserPasswordErrorMessage: state.errorAuthMessage,
      updateUserPassword: state.updateUserPassword,
      isUpdateUserPasswordLoading: state.isLoadingAuth,
    }))
  );

  const [content, setContent] = useState<ProfileContent>("displayProfile");

  const { data: getSelfData, isError: getSelfError } = useGetSelf();

  const {
    mutateAsync: updateUserProfile,
    isPending: isUpdateUserPending,
    isSuccess: isEditUserSuccess,
    reset,
  } = useUpdateSelf();

  function switchContent(content: ProfileContent) {
    setContent(content);
  }

  return (
    <MainLayout showSidebarLogo showSidebar>
      {getSelfError && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          Something went wrong...
        </div>
      )}
      <div className=" space-y-5">
        {content === "editProfile" ? (
          <EditProfile
            isUpdateUserPending={isUpdateUserPending}
            updateUserProfile={updateUserProfile}
            data={getSelfData}
            onClickGoBack={switchContent}
          />
        ) : content === "changePassword" ? (
          <ChangePassword
            updateUserPasswordErrorMessage={updateUserPasswordErrorMessage}
            isUpdateUserPasswordError={isUpdateUserPasswordError}
            updateUserPassword={updateUserPassword}
            isUpdateUserPasswordLoading={isUpdateUserPasswordLoading}
            isUpdateUserPasswordSuccess={isUpdateUserPasswordSuccess}
            data={getSelfData}
            onClickGoBack={switchContent}
          />
        ) : (
          <DisplayProfile
            data={getSelfData}
            isEditUserSuccess={isEditUserSuccess}
            reset={reset}
            onClickEdit={switchContent}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default Profile;
