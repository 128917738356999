import { useState } from "react";

import { useAppStore } from "../../../../store";

import Confirm from "../confirm";
import Ads from "../creative";

import { IBannerBearImagesResponse } from "../../../../services/wizard/bannerbear";
import Notes from "./notes";

type TTabsProps = "campaign" | "creative" | "notes";

type TCreatives = {
  creatives: IBannerBearImagesResponse | undefined;
};

function TabView({ creatives }: TCreatives) {
  const isSidebarOpen = useAppStore((state) => state.isSidebarOpen);

  const [activeTab, setActiveTab] = useState<TTabsProps>("campaign");

  function handleActiveTab(tab: TTabsProps) {
    setActiveTab(tab);
  }

  return (
    <div className={`${isSidebarOpen ? "ml-8" : "ml-[5.5rem] "}`}>
      <div className="flex relative shadow-sm rounded-md  bg-white w-[600px] mt-8 border border-solid border-gray-200">
        <div
          className={`absolute bottom-0 bg-[#0e73f6] transition-all duration-300 ease-in-out h-[2px] w-1/3 rounded-md ${
            activeTab === "campaign"
              ? "left-0"
              : activeTab === "creative"
              ? "left-1/3"
              : "left-2/3"
          }`}
        ></div>
        <div
          onClick={() => handleActiveTab("campaign")}
          className={`w-1/3 cursor-pointer bg-white text-primary transition-all  delay-75 ease-in  px-3 py-1.5 ${
            activeTab === "campaign"
              ? "   font-interSemiBold "
              : "  font-interRegular"
          }`}
        >
          Campaign Details
        </div>
        <div
          onClick={() => handleActiveTab("creative")}
          className={`w-1/3 bg-white text-primary  cursor-pointer transition-all delay-75  ease-in  px-3 py-1 ${
            activeTab === "creative"
              ? "  font-interSemiBold "
              : "  font-interRegular"
          }`}
        >
          Creative
        </div>
        <div
          onClick={() => handleActiveTab("notes")}
          className={`w-1/3 bg-white text-primary  cursor-pointer transition-all delay-75  ease-in  px-3 py-1 ${
            activeTab === "notes"
              ? "  font-interSemiBold "
              : "  font-interRegular"
          }`}
        >
          Notes
        </div>
      </div>
      <div
        className={`${isSidebarOpen ? "-ml-8" : "-ml-[5.4rem]"} -mt-[2.09rem]`}
      >
        {activeTab === "campaign" && <Confirm creatives={creatives} />}
        {activeTab === "creative" && <Ads adsFromEach={creatives} />}
        {activeTab === "notes" && <Notes />}
      </div>
    </div>
  );
}

export default TabView;
