import { Cookies } from "react-cookie";

const cookies = new Cookies();

export function getCookie(name: string): string | null {
  return cookies.get(name) || null;
}

export function setCookie(name: string, value: string) {
  cookies.set(name, value, {
    path: "/",
    maxAge: 24 * 60 * 60,
    secure: true,
    sameSite: "strict", // Prevent CSRF attacks
  });
}

export function removeCookies(name: string) {
  cookies.remove(name);
}
