import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../axios";
import { queryClient } from "../../App";

export interface IOpenAIResponse {
  id: number;
  advertiserId: number;
  websiteId: number;
  websiteFieldId: number;
  value: string;
  createdAt: Date;
  updatedAt: Date;
  websiteFieldName: string;
  websiteFieldValue: string;
}

/* GET extracted fields/values associated with website from openai */
const getWebsiteFields = (
  websiteId: string | undefined
): Promise<Array<IOpenAIResponse>> => {
  return request({
    url: `openai-response/website/${websiteId}`,
    method: "get",
  });
};

export const useOpenAIResponse = (websiteId: string | undefined) => {
  return useQuery({
    queryKey: ["openAIResponse", websiteId],
    queryFn: () => getWebsiteFields(websiteId),
    enabled: !!websiteId,
  });
};

/* UPDATE fields of  Website  */
const updateWebsiteFields = (
  data: Array<{
    id: number;
    value: string;
  }>
) => {
  return request({
    url: "openai-response",
    method: "patch",
    data: data,
  });
};

export const useUpdateWebsiteFields = (
  websiteId: string | undefined,
  advertiserId: number | null
) => {
  return useMutation({
    mutationFn: updateWebsiteFields,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ["openAIResponse", websiteId],
      });
      queryClient.invalidateQueries({
        queryKey: ["advertiserSites", advertiserId],
      });
    },
  });
};
