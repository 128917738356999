export default function BotMessage({ text }: { text: string }) {
  return (
    <div className="w-full">
      <div
        style={{ borderRadius: "20px 20px 20px 1px" }}
        className="text-left float-left py-4  my-2.5 mr-10  ml-1 px-3 bg-[#29209e] text-white min-w-10"
      >
        {text === "pending" ? (
          <span className="dots-bot">
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </span>
        ) : (
          text
        )}
      </div>
    </div>
  );
}
