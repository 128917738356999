import { StateCreator } from "zustand";

export interface ICampaignInfoModal {
  isCampaignActiveInfoModalShown: boolean;
  setIsCampaignActiveInfoModalShown: (val: boolean) => void;
}

export const createCampaignModalSlice: StateCreator<
  ICampaignInfoModal,
  [],
  [],
  ICampaignInfoModal
> = (set, get) => ({
  isCampaignActiveInfoModalShown: false,
  setIsCampaignActiveInfoModalShown: (value: boolean) => {
    set({ isCampaignActiveInfoModalShown: value });
  },
});
