import {
  Image,
  Text,
  View,
  Page,
  Document,
  StyleSheet,
  pdf,
  Font,
  Link,
} from "@react-pdf/renderer";

import { saveAs } from "file-saver";

import { useAppStore } from "../../../../store";

import EstimationPDFTable from "../../../../components/estimationTable/pdfVersion";

import SparkLogo from "../../../../assets/icons/spark-logo-white-text.png";
import InterLight from "../../../../assets/fonts/Inter/static/Inter-Light.ttf";
import InterRegular from "../../../../assets/fonts/Inter/static/Inter-Regular.ttf";
import InterSemiBold from "../../../../assets/fonts/Inter/static/Inter-SemiBold.ttf";
import InterBold from "../../../../assets/fonts/Inter/static/Inter-Bold.ttf";

import { parseDefaultVal } from "../../../../utils/parseDefaultValues";
import { formatDate } from "../../../../utils/formatDate";

interface CampaignProps {
  setIsPDFPending?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  generalCampaignInfoArr: any[];
  advanceTargetingCampaignInfoArr: any[];
  geoTargetingCampaignInfoArr: any[];
  campaignName: string | undefined;
  campaignOwnerData: any;
  campaignWebsite: string | undefined;
  campaignKeywordsFormatted: any;
  totalBudget: number | undefined;
  pieChartImageSrc?: string;
  mapImageSrc?: string;
  legalText: string | null | undefined;
  description: string | null | undefined;
  campaignVisitEstimation: any;
  campaignViewEstimation: any;
  isSearchChannelGreaterThanZero: boolean;
}

Font.register({
  family: "InterLight",
  format: "truetype",
  src: InterLight,
});
Font.register({
  family: "InterRegular",
  format: "truetype",
  src: InterRegular,
});
Font.register({
  family: "InterSemiBold",
  format: "truetype",
  src: InterSemiBold,
});
Font.register({
  family: "InterBold",
  format: "truetype",
  src: InterBold,
});

const renderDescriptionWithLinks = (description: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const parts = description.split(urlRegex);

  return parts.map((part, index) => {
    if (urlRegex.test(part)) {
      return (
        <Link
          key={index}
          src={part}
          style={{ color: "blue", textDecoration: "underline" }}
        >
          {part}
        </Link>
      );
    } else {
      return <Text key={index}>{part}</Text>;
    }
  });
};

export const SavePDF = async ({
  setIsPDFPending,
  generalCampaignInfoArr,
  advanceTargetingCampaignInfoArr,
  geoTargetingCampaignInfoArr,
  campaignName,
  campaignOwnerData,
  campaignWebsite,
  totalBudget,
  campaignKeywordsFormatted,
  pieChartImageSrc,
  legalText,
  description,
  campaignVisitEstimation,
  campaignViewEstimation,
  isSearchChannelGreaterThanZero,
}: CampaignProps) => {
  setIsPDFPending && setIsPDFPending(true);

  const Navbar = (
    <View
      fixed={true}
      style={{
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        backgroundColor: "#0e086a",
        height: 56,
        marginBottom: 15,
        paddingHorizontal: 16,
      }}
    >
      <View
        style={{
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Image
            src={SparkLogo}
            style={{ width: 80, height: 80, objectFit: "contain" }}
          />
        </View>
      </View>
    </View>
  );

  const renderField = (fieldTitle: string, fieldVal: any) => (
    <View
      key={`${fieldTitle}-${fieldVal}`}
      style={{
        display: "flex",
        marginBottom: 3,
        marginRight: 10,
      }}
    >
      <Text
        style={{
          fontSize: 8,
          //  fontWeight: InterSemiBold,
          fontFamily: "InterSemiBold",
          marginRight: 2,
        }}
      >
        {fieldTitle === "Address" ? "Where your ads will appear:" : fieldTitle}
      </Text>
      <Text
        style={{
          fontSize: 8,
          // fontWeight: InterLight
          fontFamily: "InterLight",
        }}
      >
        {parseDefaultVal(fieldVal)}
      </Text>
    </View>
  );

  const sortedFields = geoTargetingCampaignInfoArr.sort((a, b) => {
    if (a.campaignFieldName === "Address") return -1;
    if (b.campaignFieldName === "Address") return 1;
    return 0;
  });

  const campaignCreatives = useAppStore.getState().creativesForPDFArr;

  const PDFDoc = (
    <Document>
      <Page size="A5" style={[styles.page]}>
        {Navbar}

        {description && (
          <View style={[styles.section]}>
            <Text style={styles.title}>About</Text>
            <Text style={[styles.text, { lineHeight: 1.4 }]}>
              {renderDescriptionWithLinks(description)}
            </Text>
          </View>
        )}

        <View
          style={[
            styles.section,
            { display: "flex", flexDirection: "row", marginVertical: 20 },
          ]}
        >
          <View>
            <Text style={styles.title}>Campaign Info</Text>
            <View style={styles.textWrapper}>
              <Text style={styles.subTitle}>• Prepared for: </Text>
              <Text style={styles.text}>{campaignOwnerData?.displayName}</Text>
            </View>
            {generalCampaignInfoArr?.map((campaignFieldsObj, index) => {
              const fieldTitle = campaignFieldsObj.campaignFieldName;
              const fieldVal = campaignFieldsObj.campaignFieldName
                .toLocaleLowerCase()
                .includes("date")
                ? formatDate(campaignFieldsObj.value)
                : campaignFieldsObj.value;

              return (
                <View key={`${fieldVal}-s-${index}`} style={styles.textWrapper}>
                  <Text style={styles.subTitle}>• {fieldTitle}: </Text>
                  <Text style={styles.text}>{fieldVal}</Text>
                </View>
              );
            })}

            <View style={styles.textWrapper}>
              <Text style={styles.subTitle}>• Total Budget: </Text>
              <Text style={styles.text}>${totalBudget}</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.subTitle}>• Website: </Text>
              <Text style={styles.text}>{campaignWebsite}</Text>
            </View>
          </View>
        </View>

        <View style={[styles.section]}>
          <Text style={styles.title}>Budget Allocation</Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View
              style={[
                styles.textWrapper,
                { width: "45%", alignSelf: "flex-start" },
              ]}
            >
              <Text style={[styles.text, styles.customLineHeight]}>
                To get the most out of your ad budget, we’ll allocate it across
                the best channels to ensure maximum impact and ROI.
              </Text>
            </View>
            <View style={[{ width: "54%", marginTop: -20 }]}>
              <Image
                style={{ width: 200, height: 100, objectFit: "contain" }}
                src={pieChartImageSrc}
              />
            </View>
          </View>
        </View>

        <View break style={styles.section}>
          <Text style={styles.title}>Reaching Your Customers</Text>
          <View
            style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}
          >
            <View
              style={{
                width: "45%",
              }}
            >
              <Text style={[styles.text, styles.customLineHeight]}>
                We make sure your ads reach the people most likely to be
                interested in your business. Using smart data and insights, we
                connect you with the right customers so every ad dollar counts.
              </Text>
            </View>

            <View
              style={{
                width: "48%",
                marginLeft: 15,
              }}
            >
              <EstimationPDFTable
                campaignVisitEstimation={campaignVisitEstimation}
                campaignViewEstimation={campaignViewEstimation}
              />
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {sortedFields?.map((campaignFieldsObj) => {
              const fieldTitle = campaignFieldsObj.campaignFieldName;
              const fieldVal = campaignFieldsObj.campaignFieldName
                .toLocaleLowerCase()
                .includes("date")
                ? formatDate(campaignFieldsObj.value)
                : campaignFieldsObj.value;

              return renderField(fieldTitle, fieldVal);
            })}
          </View>
          {advanceTargetingCampaignInfoArr.map((campaignFieldsObj, index) => {
            const fieldTitle = campaignFieldsObj.campaignFieldName;
            const fieldVal = campaignFieldsObj.campaignFieldName
              .toLocaleLowerCase()
              .includes("date")
              ? formatDate(campaignFieldsObj.value)
              : campaignFieldsObj.value;

            return (
              <View
                key={`${fieldVal}-${index}`}
                style={[styles.textWrapper, { marginTop: 1.5 }]}
              >
                <Text style={styles.subTitle}>{fieldTitle}: </Text>
                <Text style={styles.text}>{parseDefaultVal(fieldVal)}</Text>
              </View>
            );
          })}
          {campaignKeywordsFormatted &&
            campaignKeywordsFormatted.length > 0 &&
            isSearchChannelGreaterThanZero && (
              <View style={styles.textWrapperCol}>
                <Text
                  style={[styles.subTitle, { marginTop: 10, marginBottom: 5 }]}
                >
                  Search keywords:
                </Text>
                <Text style={[styles.text, styles.customLineHeight]}>
                  {campaignKeywordsFormatted}
                </Text>
              </View>
            )}
        </View>

        <View break style={styles.section}>
          <Text style={[styles.title, { marginTop: 5 }]}>Sample Creative</Text>
          <Text
            style={[styles.text, styles.customLineHeight, { marginBottom: 5 }]}
          >
            The following creative concepts are designed to showcase some
            samples of how your brand would appear to a ad audience across
            different media channels. These are tailored to suit your unique
            brand identity and objectives and are optimized based on our
            industry experience to ensure it resonates with audiences.
          </Text>
          <View style={styles.imageContainer}>
            {campaignCreatives.map((creative) => (
              <View style={styles.gridItem} key={creative.id}>
                <Image src={creative.imageUrl ?? ""} style={styles.image} />
              </View>
            ))}
          </View>
        </View>

        {legalText && (
          <View break style={styles.section}>
            <View style={styles.textWrapperCol}>
              <Text style={[styles.title, { marginVertical: 5 }]}>
                Legal Disclosure
              </Text>
              <Text style={[styles.text, styles.customLineHeight]}>
                {legalText}
              </Text>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );

  try {
    const pdfBlob = await pdf(PDFDoc).toBlob();
    saveAs(pdfBlob, `${campaignName}-${campaignOwnerData?.displayName}.pdf`);
    setIsPDFPending && setIsPDFPending(undefined);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

const styles = StyleSheet.create({
  page: {
    paddingBottom: 10,
  },
  textWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 5,
    flexWrap: "wrap",
  },
  textWrapperCol: { display: "flex", marginVertical: 5 },
  section: {
    paddingHorizontal: 8,
    marginBottom: 0,
  },
  title: {
    textTransform: "uppercase",
    fontFamily: "InterBold",
    color: "#40444f",
    paddingBottom: 0,
    fontSize: 11,
    marginBottom: 7,
  },
  subTitle: {
    fontFamily: "InterSemiBold",
    fontSize: 8,
    marginRight: 2,
  },
  text: {
    fontFamily: "InterRegular",
    fontSize: 8,
    flexShrink: 1,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
  },
  gridItem: {
    width: "50%",
    padding: 5,
    alignItems: "center",
  },
  image: {
    width: "100%",
    maxHeight: 190,
    objectFit: "contain",
  },
  customLineHeight: {
    lineHeight: 1.5,
  },
});
