import { useShallow } from "zustand/shallow";
import { useAppStore } from "../../store";

import SidebarStep from "../sidebarStep";

const TopContent = ({
  title,
  subTitle,
  subTitleClassName,
  showWizard,
}: {
  title: string;
  subTitle: string | undefined;
  subTitleClassName?: string;
  showWizard?: boolean;
}) => {
  const { step, isSidebarOpen } = useAppStore(
    useShallow((state) => ({
      step: state.step,
      isSidebarOpen: state.isSidebarOpen,
    }))
  );

  const Wizard = () => {
    const stepTitles = ["Strategy", "Targeting", "Creative", "Confirmation"];
    return (
      <div className={`flex w-full -mb-3 ${!isSidebarOpen && "ml-[4rem]"} `}>
        {stepTitles.map((title, index) => (
          <SidebarStep
            key={title}
            index={index}
            title={title}
            currentStep={step ?? 0}
            totalSteps={4}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={`bg-white  px-8 pt-0 pb-3  w-full flex `}>
      <div className="w-full flex flex-col  max-w-[80rem] ">
        <div
          className={`
        flex flex-col mt-2  max-w-fit ${!isSidebarOpen && "ml-[4rem]"}
        `}
        >
          <h3 className="text-[2.5rem] font-interBold text-primary">{title}</h3>
          <h5
            className={`text-sm text-secondary font-interSemiBold -mt-1.5 ${subTitleClassName}`}
          >
            {subTitle}
          </h5>
        </div>
        {showWizard && step !== 0 && step !== 1 && <Wizard />}
      </div>
    </div>
  );
};

export default TopContent;
