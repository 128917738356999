import { useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

import { SubmitHandler, useForm } from "react-hook-form";

import { useAppStore } from "../../../../store";
import { useShallow } from "zustand/shallow";

import ContentContainer from "../../../../components/contentContainer";
import { Selection } from "../../../../components/select";
import TopContent from "../../../../components/topContent";
import RoundedButton from "../../../../components/roundedButton";

import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon-mui.svg";

import {
  IUpdateUsersRoleRequestData,
  useUpdateAssignedUsersRole,
  useUsersRoleForAdvertiser,
} from "../../../../services/user-organization-advertiser-role";
import { useDomainStyle } from "../../../../services/multitenacy-config";

import { roleHierarchy } from "../../../../constants/usersRoles";

function EditRole() {
  const navigation = useNavigate();

  const { selectedAdvertiser, domainName } = useAppStore(
    useShallow((state) => ({
      selectedAdvertiser: state.selectedAdvertiser,
      domainName: state.domainName,
    }))
  );

  const { data: domainStyleData } = useDomainStyle(domainName);

  const organizationId = domainStyleData?.id ?? null;

  const { data: usersRoleForAdvertiserData } = useUsersRoleForAdvertiser(
    selectedAdvertiser,
    organizationId
  );

  const [selectRole, setSelectRole] = useState<{
    label: any;
    value: any;
  } | null>(null);

  const [roleErrMsg, setRoleErrMsg] = useState<string | undefined>(undefined);

  const selfUserRole = usersRoleForAdvertiserData?.role;

  const {
    // register,
    // watch,
    handleSubmit,
    setValue,
    // formState: { errors },
  } = useForm<any>({
    defaultValues: {
      role: selectRole?.value,
    },
  });

  const {
    mutateAsync: updateAssignedUsersRole,
    isError: hasRoleAssignmentError,
    isPending: isUpdatingRole,
    isSuccess: isNewRoleAssigned,
  } = useUpdateAssignedUsersRole(selectedAdvertiser);

  const roleSelectionData = useMemo(() => {
    const selfRoleIndex = roleHierarchy.findIndex(
      (role) => role.value === selfUserRole
    );
    return roleHierarchy.slice(0, selfRoleIndex + 1);
  }, [selfUserRole]);

  function handleFormSubmit(e: React.FormEvent) {
    if (!selectRole?.value) {
      setRoleErrMsg("Role is required");
    }

    handleSubmit(onSubmit)(e);
  }

  async function handleSave(formData: any) {
    if (isUpdatingRole) return;
    await updateAssignedUsersRole(formData);
  }

  const onSubmit: SubmitHandler<any> = async (formData) => {
    if (selfUserRole === "BASIC") return;

    const reqData: IUpdateUsersRoleRequestData = {
      id: usersRoleForAdvertiserData.id,
      data: {
        advertiserId: selectedAdvertiser,
        role: formData.role,
        organizationId,
      },
    };
    await handleSave(reqData);
  };

  useEffect(() => {
    if (usersRoleForAdvertiserData) {
      setSelectRole({
        value: usersRoleForAdvertiserData.role,
        label: usersRoleForAdvertiserData.role.toLocaleLowerCase(),
      });
      setValue("role", usersRoleForAdvertiserData.role);
    }
    //eslint-disable-next-line
  }, [usersRoleForAdvertiserData]);

  useEffect(() => {
    if (isNewRoleAssigned) {
      navigation("/profile/users");
    }
    //eslint-disable-next-line
  }, [isNewRoleAssigned]);

  return (
    <div>
      <TopContent
        title="Edit User Role"
        subTitle="Update Assigned Users Role For Selected Advertiser"
      />
      <ContentContainer>
        <div className=" flex flex-1 flex-col p-12 pb-0 ">
          <div className=" flex flex-1 flex-col space-y-5 justify-between mb-12 items-center sm:items-stretch">
            <form onSubmit={handleFormSubmit}>
              <div className="flex items-center">
                <div className="flex  flex-col">
                  <label
                    className="font-interRegular text-sm text-secondary mb-1"
                    htmlFor="industryId"
                  >
                    Role
                  </label>
                  <div className="flex items-center">
                    <div className="relative xs:w-[18.75rem] sm:w-[27.5rem]">
                      <Selection
                        data={roleSelectionData}
                        placeholder="Select a role for the user"
                        value={selectRole}
                        onChange={(selectedOption: any, actionMeta: any) => {
                          setSelectRole(selectedOption);
                          setValue("role", selectedOption.value);
                          setRoleErrMsg(undefined);
                        }}
                        closeMenuOnSelect={true}
                        isMulti={undefined}
                        customStyle={{
                          selectContainer: {
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: `${
                              roleErrMsg ? "#D34638" : "#40444f"
                            }`,
                            borderRadius: "0.625rem",
                            width: "100%",
                            fontSize: 14,
                            marginTop: "1px",
                          },
                          valueContainer: {
                            paddingInline: "0.75rem",
                            paddingBlock: "0.43rem",
                          },
                          color: "#d3d3d3",
                          optionisSelectedBgColor: "transparent",
                          optionisFocusedBgColor: "#335498",
                          optionisFocusedTextColor: "white",
                          singleValStyles: {
                            backgroundColor: "transparent",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                {roleErrMsg && (
                  <p className=" text-sm mt-4 ml-2 font-interSemiBold text-colorDelete">
                    {roleErrMsg}
                  </p>
                )}
              </div>
              {hasRoleAssignmentError && (
                <p className=" text-sm mt-4 ml-2 font-interSemiBold text-colorDelete">
                  Something went wrong adding new user!
                </p>
              )}
              <div className="flex  pt-10 items-center ">
                <RoundedButton
                  className="py-2.5 px-4"
                  type="submit"
                  borderRadius="rounded-md"
                  isEnabled={
                    !hasRoleAssignmentError && selfUserRole !== "BASIC"
                  }
                  text={
                    <p className=" text-sm text-white inline-flex items-center font-interRegular">
                      <EditIcon className="text-white fill-current mr-2 text-2xl " />
                      {isUpdatingRole ? "Saving..." : "SAVE"}
                    </p>
                  }
                />
                <p
                  onClick={() => navigation(-1)}
                  className="text-sm cursor-pointer font-interRegular ml-7 text-colorBlue"
                >
                  Cancel
                </p>
              </div>
            </form>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
}

export default EditRole;
